import { Machine, StackItem } from "../../lib/types/ClientServices/Machines";
import MachineMayTag from "./machine-maytag";
import MachineSticker from "./machine-sticker";
import React from "react";
import { NFCRoute } from "../../routes/routes";
import { useNavigate } from "react-router-dom";

const MachineDisplay = ({ machine }: { machine: Machine }) => {
  const hasStack =
    machine && machine?.stackItems ? machine?.stackItems.length > 0 : false;

  const navigate = useNavigate();

  const changeMachine = (machine: Machine) => {
    navigate(`/${NFCRoute}/${machine.nfcId}`);
  };

  if (!machine.available && !machine.freePlay) {
    return <></>;
  }

  return (
    <>
      {hasStack && machine?.stackItems ? (
        <MachineMayTag
          top={machine?.stackItems?.find(
            (stack: StackItem) => stack.stack === "upper"
          )}
          bottom={machine?.stackItems?.find(
            (stack: StackItem) => stack.stack === "lower"
          )}
          changeMachine={changeMachine}
        />
      ) : (
        <MachineSticker machine={machine} />
      )}
    </>
  );
};

export default MachineDisplay;
