import React from "react";
import Image from "react-bootstrap/Image";
import AppLessLayout from "../components/layout/Layout";
import LaundryRoomImage from "/assets/LaundryRoom.svg";
import { useTranslation } from "react-i18next";
// pages/404.js
interface FourOFourProps {}

const FourOFour: React.FC<FourOFourProps> = () => {
  const { t } = useTranslation();
  return (
    <AppLessLayout noContext={true}>
      <div className="headerBackground" />
      <main role="main" className="container">
        <div className="laundry-room-row">
          <div className="laundry-room__wrap">
            <div className="laundry-room m-auto">
              <h3 className="pt-3">
                {t("FourOFour.weAreSorry")}
                <br />
                {t("FourOFour.something")}
                <br />
                {t("FourOFour.wentWrong")}
              </h3>
              <Image src={LaundryRoomImage} style={{ width: "100%" }} />
            </div>
          </div>
        </div>
      </main>
    </AppLessLayout>
  );
};

export default FourOFour;
