import logger from "../../lib/logger";
import { SetInitPropsType, UIState } from "./UIContext";
import MyLaundryManager from "../../lib/localStorage/MyLaundryManager";
import SegmentEventTypes from "../tracking/SegmentEventTypes";
import {
  RoomSummaryProps,
  SetMachineAction,
  SetSourceMachineProps,
  SetSSEStatus,
  UIAction,
} from "./UIAction";
import { Machine } from "../../lib/types/ClientServices/Machines";
import { MachineNavigationMethod } from "../../lib/types/FrontEnd/Machines";
import { FlashMessageProps } from "../alerts/StickyMessage";

type LogEventAction = SegmentEventTypes;
declare let analytics: any;

export const Reducer = (state: UIState, action: UIAction): UIState => {
  const localStorageManager = new MyLaundryManager(false);

  const NotYetSetString = "N/A";

  switch (action.type) {
    case "log-page-view": {
      analytics.page();
      return state;
    }

    // Sends segment event
    case "log-event": {
      logger.trace("Log event: %o", action.payload);
      const payload = action.payload as LogEventAction;
      const licensePlate =
        payload.properties?.licensePlateOverride ??
        state?.getSourceMachine()?.licensePlate ??
        NotYetSetString;
      const props = {
        ...payload.properties,
        locationId: state.location?.locationId ?? NotYetSetString,
        locationName: state.location?.label ?? NotYetSetString,
        as400Id: state.location?.as400Number ?? NotYetSetString,
        roomId: state.room?.roomId ?? NotYetSetString,
        roomName: state.room?.label ?? NotYetSetString,
        SSE_enabled: state.isSSESupported ?? NotYetSetString,
        Time_Stamp: new Date().toISOString(),
        Machine_Identification_Method:
          MyLaundryManager.getMachineIdentificationMethod(licensePlate) ??
          MachineNavigationMethod.manual,
        sourceLicensePlate: licensePlate ?? NotYetSetString,
        paymentProcessor: payload.properties?.paymentProcessor ?? "A",
        Machine_Type: state?.getSourceMachine()?.type ?? "",
      };
      logger.debug(props, "UI Context Log Event: %s", payload.name);
      analytics.track(payload.name, props);
      return state;
    }

    // Adds new machine to users local stage
    case "set-machine": {
      const payload = action.payload as SetMachineAction;

      if (payload == null || !state?.machines?.length) {
        // protect against destructive changes.  Should warning ...
        return { ...state };
      }

      localStorageManager.upsertMachine(payload.machine);

      let indexOfMachine: number | undefined = undefined;
      indexOfMachine = state?.machines?.findIndex(
        (el) => el.licensePlate === payload.machine.licensePlate
      );

      if (indexOfMachine !== undefined) {
        const newMachines = state.machines;
        newMachines[indexOfMachine] = payload.machine;

        return {
          ...state,
          machines: newMachines,
          isSSESupported: payload?.isSSESupported ?? state.isSSESupported,
        };
      } else {
        return { ...state };
      }
    }

    // Sets the "source machine" guid
    case "set-source-machine": {
      const payload = action.payload as SetSourceMachineProps;
      if (!payload.guid) {
        return { ...state };
      }
      const selectedMachine = state.machines?.find(
        (m) => m.opaqueId === payload.guid
      );
      if (selectedMachine) {
        return { ...state, selectedMachine: selectedMachine };
      }

      return { ...state };
    }

    // Bootstraps the application
    case "set-init-props": {
      const payload = action.payload as SetInitPropsType;
      if (!payload.data || !payload.data?.machines) {
        return { ...state };
      } else {
        const selectedMachines = payload.data.machines;
        const selectedMachinesArray = Object.values(selectedMachines);

        const data = {
          roomSummary: state.roomSummary,
          initialized: true,
          error: false,
          location: payload.data.location,
          room: payload.data.room,
          machines: selectedMachinesArray,
          stickerNumber: payload.data.stickerNumber,
          availabilitySummary: payload.data.availabilitySummary,
          isSSESupported: state.isSSESupported,
          selectedMachine: selectedMachinesArray.find(
            (machine) => machine.opaqueId === payload.guid
          ),
          getMachineByGuid: function getMachineByGuid(
            guid: string
          ): Machine | undefined {
            return this.machines.find((m) => m.opaqueId === guid);
          },
          getMachine: function getMachine(lp: string) {
            for (const index in this.machines) {
              if (this.machines[parseInt(index)].licensePlate === lp) {
                return this.machines[parseInt(index)];
              }
            }
            return null;
          },
          getSourceMachine: function getSourceMachine(
            guid?: string
          ): Machine | undefined {
            if (guid) {
              const newSelectedMachine = this.getMachineByGuid(guid);
              this.selectedMachine = newSelectedMachine;
              return newSelectedMachine;
            }
            if (!this.selectedMachine) {
              logger.trace("no source machine set!");
            }
            return selectedMachinesArray.find(
              (machine) => machine.opaqueId === this.selectedMachine?.opaqueId
            );
          },
          findMachineByStickerNumber: function findMachineByStickerNumber(
            stickerNumber: string
          ) {
            for (const index in this.machines) {
              if (
                this.machines[parseInt(index)].stickerNumber == stickerNumber
              ) {
                return this.machines[parseInt(index)];
              }
            }
            return null;
          },
        };

        // Update local storage whenever we fetch new data
        MyLaundryManager.updateRoomMachines(data.machines);
        MyLaundryManager.updateRoom(data.room);
        MyLaundryManager.updateLocation(data.location);

        return data;
      }
    }

    case "set-sse-status": {
      const payload = action.payload as SetSSEStatus;
      return { ...state, isSSESupported: payload.isSSESupported };
    }

    case "set-alert-message": {
      const payload = action.payload as FlashMessageProps;
      return { ...state, flashMessage: payload };
    }

    case "set-room-summary": {
      const payload = action.payload as RoomSummaryProps;
      return { ...state, roomSummary: payload.data };
    }
  }
  return state;
};
