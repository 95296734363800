import { Machine } from "../../lib/types/ClientServices/Machines";
import { MachineTypesClientServices } from "../../lib/types/FrontEnd/Machines";
import React from "react";
import { useTranslation } from "react-i18next";

function MachineName(props: { machine: Machine }) {
  const { t } = useTranslation();
  return (
    <p className="pt-4 name">
      {props.machine
        ? props.machine.type === MachineTypesClientServices.Dryer
          ? t("MachineType.dryer")
          : t("MachineType.washer")
        : null}{" "}
      {props.machine ? props.machine.stickerNumber : null}
    </p>
  );
}

export default MachineName;
