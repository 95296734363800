import React from "react";
import { useTranslation } from "react-i18next";
export const FreePlayContinueButton = (props: {
  onClick: () => void;
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <button
      onClick={props.onClick}
      className={"btn btn--primary btn--full-width laundry-machine__button"}
    >
      {t("FreePlayButton.continue")}
    </button>
  );
};
