import { gql } from "@apollo/client";
import { DataEndpointResponse } from "../../types/ClientServices/RoomDataResponse";

const GET_DATA = gql`
  query GetRoomData {
    data(guid: $guid) @rest(type: "Post", path: "machine/info/{args.guid}") {
      licensePlate
      location
      machines
      room
      availabilitySummary
    }
  }
`;

export default GET_DATA;

export interface DataEndpointResponseGraphql {
  data: DataEndpointResponse;
}
