import React from "react";
import { UIContext } from "../../../context/UIContext";
import { getOnViewRoomSummaryEvent } from "../../../tracking/SegmentEvents";
import Expire from "../../../alerts/Expire";
import { useTranslation } from "react-i18next";

interface CopyToClipBoardButtonProps {
  url?: string;
  roomId: string;
  locationId: string;
}

export const CopyToClipboardButton = ({
  url,
  roomId,
  locationId,
}: CopyToClipBoardButtonProps) => {
  const { dispatch } = React.useContext(UIContext);
  const [clicked, setClicked] = React.useState(false);
  const { t } = useTranslation();

  const onClick = () => {
    setClicked(true);
    navigator.clipboard.writeText(url as string).then(() => {
      dispatch({
        type: "log-event",
        payload: getOnViewRoomSummaryEvent(true, roomId, locationId),
      });
    });
  };

  return (
    <>
      {navigator?.clipboard &&
        typeof navigator?.clipboard?.writeText !== "undefined" &&
        url && (
          <>
            <div
              onClick={onClick}
              className={`clipboard-button ${
                clicked ? "clipboard-button--active" : ""
              }`}
            >
              <input
                readOnly
                className={"clipboard-button__input"}
                onClick={onClick}
                value={url}
              />
            </div>
            {clicked && (
              <Expire delay={3500}>
                <span className={"clipboard-button__copied-text"}>
                  {t("CopyToClipBoardButton.urlCopied")}
                </span>
              </Expire>
            )}
          </>
        )}
    </>
  );
};
