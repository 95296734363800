import React, { useEffect, useState } from "react";
import { UIContext } from "../../context/UIContext";
import { MachinePricing } from "../../../lib/graphql/queries/GetMachinePrice";
import { useLocation, useNavigate } from "react-router-dom";
import APRIVA_PAYMENT, {
  AprivaErrorResponse,
  AprivaPayload,
  AprivaPaymentData,
  startMachineWithAnonStudentCardPayloadProps,
} from "../../../lib/graphql/mutations/AprivaPayment";
import { useApolloClient } from "@apollo/client";
import logger from "../../../lib/logger";
import { PressStartRoute, StartedRoute } from "../../../routes/routes";
import { setFlashMessage, StickyMessageType } from "../../alerts/StickyMessage";
import "./StudentPaymentForm.scss";
import { ToolTip } from "../ToolTip/ToolTip";
import LoadingSVG from "../../../../assets/img/loading-spinner.svg";
import StudentErrorModal, {
  StudentPaymentError,
} from "../Modals/StudentErrorModal";
import { ErrorResponse } from "@apollo/client/link/error";
import { ServerError } from "@apollo/client/link/utils/throwServerError";
import { onAdyenPaymentProps } from "../../../lib/types/FrontEnd/Adyen";
import {
  getOnPaymentAttemptEventPayload,
  getOnPaymentStartEventPayload,
  getPaymentErrorEventPayload,
  getPaymentSuccessEventPayload,
} from "../../tracking/SegmentEvents";
import { VendMethod } from "../../../lib/types/FrontEnd/vend-price";
import { paymentMethods } from "../../../lib/types/FrontEnd/Payment";
import { TRANSACTION_FEE_PRICE_TYPE } from "../../machine/machine-price";
import { useTranslation } from "react-i18next";

interface StudentPaymentFormProps {
  price: MachinePricing;
}

export const APRIVA_SERVICE_ERRORS = {
  INSUFFICIENT_FUNDS: {
    CODE: 14,
  },
};

const formatPrice = (selectedPrice: number | undefined): string | undefined => {
  if (!selectedPrice) {
    return undefined;
  }
  return "$" + (selectedPrice / 100).toFixed(2);
};

export const StudentPaymentForm = (props: StudentPaymentFormProps) => {
  const { state, dispatch } = React.useContext(UIContext);
  const navigate = useNavigate();
  const location = useLocation();
  const client = useApolloClient();

  const [errorModalType, setErrorModalType] = useState<
    StudentPaymentError | undefined
  >();

  const [name, setName] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [loading, setLoading] = useState(false);

  const sourceMachine = state.getSourceMachine();
  // This is passed in on the route here
  const additionalBlocks = location?.state?.additionalBlocks;
  const durationPerBlock = props.price.topOffDuration?.durationPerBlock;
  const vendMethod = additionalBlocks > 0 ? VendMethod.topOff : VendMethod.full;
  const addedTime = additionalBlocks * (durationPerBlock ?? 0);

  const option =
    props?.price?.topOffDuration?.options?.find(
      (option) => option.blocks === additionalBlocks
    ) ?? undefined;

  const elementsToSumForCost = props.price.details
    .filter((priceDetail) => priceDetail.type !== TRANSACTION_FEE_PRICE_TYPE)
    .map((fee) => fee.price);

  const baseCost = option
    ? option.zeroDecimalAmount
    : elementsToSumForCost.reduce((sum, x) => (sum ?? 0) + (x ?? 0), 0) ?? 0;

  const cardName = state.roomSummary?.school?.cardName ?? "";
  const schoolName = state.roomSummary?.school?.name;

  const last4DigitsOfStudentCard = schoolId.substring(schoolId.length - 4);
  const { t } = useTranslation();

  useEffect(() => {
    if (!state.initialized) return;

    dispatch({
      type: "log-event",
      payload: getOnPaymentStartEventPayload({
        type: vendMethod,
        amount: baseCost,
        Added_Time: addedTime,
        processor: "Apr",
      }),
    });
  }, [state.initialized]);

  const onSubmitEvent = (props: onAdyenPaymentProps) => {
    dispatch({
      type: "log-event",
      payload: getOnPaymentAttemptEventPayload({
        ...props,
        processor: "Apr",
      }),
    });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // don't reload page
    onSubmitEvent({
      Added_Time: addedTime,
      method: vendMethod,
      type: paymentMethods.studentcard,
      amount: baseCost,
      studentCardID: last4DigitsOfStudentCard,
    });

    if (
      !state.room?.roomId ||
      !state.location?.locationId ||
      !state.location?.as400Number ||
      !sourceMachine?.licensePlate
    ) {
      throw new Error("Missing data on apriva submit!");
    }

    setLoading(true);

    const payload: startMachineWithAnonStudentCardPayloadProps = {
      roomId: state.room.roomId,
      locationId: state.location.locationId,
      as400Number: state.location.as400Number,
      licensePlate: sourceMachine.licensePlate,
      amount: baseCost as number,
      currency: "usd",
      additionalBlocks: additionalBlocks ?? undefined,
      studentCardId: schoolId,
      fullName: name,
    };

    client
      .mutate<AprivaPaymentData, AprivaPayload>({
        mutation: APRIVA_PAYMENT,
        variables: {
          body: payload,
        },
      })
      .then((response) => {
        setLoading(false);
        logger.debug("apriva payment response: %o", response);
        const data = response?.data?.data;

        if (!data?.success) {
          const responseText = data?.data.responseText;
          const responseCode = data?.data.responseCode;
          logger.error("responseText: %s", responseText);
          logger.error("responseCode: %s", responseCode);
          logger.debug("Response: %o", data);

          setFlashMessage(
            `${t("StudentPaymentForm.paymentFailed")}`,
            StickyMessageType.error
          );
        } else {
          dispatch({
            type: "log-event",
            payload: getPaymentSuccessEventPayload(
              {
                method: vendMethod,
                duration: addedTime,
                zeroDecimalAmount: baseCost,
              },
              paymentMethods.studentcard,
              last4DigitsOfStudentCard
            ),
          });

          if (additionalBlocks > 0 && durationPerBlock) {
            setFlashMessage(
              `${durationPerBlock * additionalBlocks} ${t(
                "StudentPaymentForm.minAdded"
              )}`,
              StickyMessageType.topOffCompleteNotice
            );
            navigate(`/${StartedRoute}/${sourceMachine.opaqueId}`);
          } else {
            setFlashMessage(
              `${t("StudentPaymentForm.paymentSuccess")}`,
              StickyMessageType.paymentCompleteNotice
            );

            if (!state.isSSESupported) {
              navigate(`/${PressStartRoute}/${sourceMachine.opaqueId}`);
            }
          }
        }
      })
      .catch((errorResponse: ErrorResponse) => {
        const networkError = errorResponse.networkError as ServerError;

        const error: AprivaErrorResponse =
          networkError?.result as AprivaErrorResponse;

        // Check to see if the error code meets a specific error message requirement
        if (
          error?.errors &&
          error.errors.length &&
          error.errors.some((err) => {
            return err.code === APRIVA_SERVICE_ERRORS.INSUFFICIENT_FUNDS.CODE;
          })
        ) {
          setErrorModalType(StudentPaymentError.insufficientBalance);
        } else {
          // Otherwise set generic error state
          setErrorModalType(StudentPaymentError.somethingWentWrong);
        }

        const [firstError] = error.errors;

        dispatch({
          type: "log-event",
          payload: getPaymentErrorEventPayload(
            {
              duration: addedTime,
              method: vendMethod,
              zeroDecimalAmount: baseCost,
            },
            {
              success: false,
              statusCode: firstError.code.toString(),
              message: firstError.message,
            },
            last4DigitsOfStudentCard
          ),
        });

        setLoading(false);
        logger.error("Error making student payment!: %o", error);
        throw error;
      });
  };

  const namePlaceholder = t("StudentPaymentForm.namePlaceholder");
  const schoolIdPlaceholder = t("StudentPaymentForm.schoolIdPlaceholder");

  return (
    <>
      <form className={"student-payment-form"} onSubmit={onSubmit}>
        <label className={"student-payment-form__label"}>
          <span className={"student-payment-form__label-text"}>
            {t("StudentPaymentForm.name")}
          </span>
          <input
            className={
              "student-payment-form__input student-payment-form__input--name"
            }
            value={name}
            placeholder={namePlaceholder}
            onChange={(e) => {
              setName(e.currentTarget.value);
            }}
            name={"first_and_last_name"}
          />
        </label>
        <label className={"student-payment-form__label"}>
          <span className={"student-payment-form__label-text"}>
            {schoolName} ID {schoolName && <ToolTip schoolId={schoolName} />}
          </span>
          <input
            className={
              "student-payment-form__input student-payment-form__input--card"
            }
            placeholder={`${schoolIdPlaceholder} ${cardName}` || ""}
            value={schoolId}
            onChange={(e) => {
              setSchoolId(e.currentTarget.value);
            }}
            name={"school_id"}
          />
        </label>

        <div className="student-payment-form__button-wrap">
          <button
            className={"student-payment-form__button"}
            disabled={!name || !schoolId || loading}
            type={"submit"}
          >
            {t("StudentPaymentForm.pay")} {formatPrice(baseCost)}
            {loading && (
              <img
                className={"student-payment-form__button__loading"}
                src={LoadingSVG}
                alt="Loading..."
              />
            )}
          </button>
        </div>
      </form>

      <StudentErrorModal
        showModal={errorModalType !== undefined}
        closeModal={() => setErrorModalType(undefined)}
        modalType={errorModalType}
      />
    </>
  );
};
