import React, { useEffect, useState } from "react";

export interface ExpireProps {
  delay: number; // Delay in ms
  children: JSX.Element;
  callback?: () => void;
}

/**
 * Higher order component which will only display children for a specified unit of time, and provide a class on unmount to allow css transitions.
 * @param props
 * @constructor
 */
const Expire = (props: ExpireProps) => {
  const [visible, setVisible] = useState(true);
  const shouldRenderChild = useDelayUnmount(visible, 500);

  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
    }, props.delay);
    return () => {
      if (typeof props?.callback === "function") {
        props?.callback();
      }
    };
  }, [props.delay]);

  return shouldRenderChild ? (
    <div className={`expire expire--${visible ? "mounted" : "unmounting"}`}>
      {props.children}
    </div>
  ) : (
    <></>
  );
};

/**
 * This function allows the calling component to animate "unmounting" a component by returning an additional boolean which will lag ${delayTime} milliseconds after the change in the ${isMounted} parameter.
 * @param isMounted
 * @param delayTime
 */
const useDelayUnmount = (isMounted: boolean, delayTime: number): boolean => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isMounted && !shouldRender) {
      setShouldRender(true);
    } else if (!isMounted && shouldRender) {
      timeoutId = setTimeout(() => setShouldRender(false), delayTime);
    }
    return () => clearTimeout(timeoutId);
  }, [isMounted, delayTime, shouldRender]);

  return shouldRender;
};

export default Expire;
