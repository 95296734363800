import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ConfirmationModal, {
  ConfirmationModalType,
} from "../components/request-service/confirmation-modal";
import ErrorModal from "../components/request-service/error-modal";
import AppLessLayout from "../components/layout/Layout";
import { useNavigate } from "react-router-dom";
import { Room } from "../lib/types/FrontEnd/Room";
import { SetInitPropsType, UIContext } from "../components/context/UIContext";
import logger from "../lib/logger";
import { LaundryRoute } from "../routes/routes";
import { LaundromatLocation } from "../lib/types/FrontEnd/LaundromatLocation";
import { parseCookies, setCookie } from "nookies";
import { Machine } from "../lib/types/ClientServices/Machines";
import { useApolloClient } from "@apollo/client";

import HowToPay from "../components/request-refund/HowToPay";
import RefundAmountAndSummary from "../components/request-refund/RefundAmountAndSummary";
import CardInfo from "../components/request-refund/CardInfo";
import ContactInfo from "../components/request-refund/ContactInfo";
import EnterLicenseForRefund from "../components/request-refund/EnterLicenseForRefund";
import CASH_REFUND_REQUEST, {
  CashRefundRequestData,
  CashRefundRequestVars,
  GenericRefundRequestBody,
} from "../lib/graphql/mutations/CashRefundRequest";
import DIGITAL_REFUND_REQUEST, {
  ANONYMOUS_USER_REFUND_REQUEST_REASON,
  DigitalRefundRequestBody,
  DigitalRefundRequestData,
  DigitalRefundRequestVars,
} from "../lib/graphql/mutations/DigitalRefundRequest";
import {
  getOnSuccessfulRefundRequestEventPayload,
  OnRequestRefundSubmitEventPayload,
} from "../components/tracking/SegmentEvents";
import GET_DATA, {
  DataEndpointResponseGraphql,
} from "../lib/graphql/queries/GetData";
import { useTranslation } from "react-i18next";

enum HowUserPaid {
  digital = "DIGITAL",
  cash = "CASH",
}

enum ViewName {
  HowToPay = "step1",
  EnterLicense = "step2",
  RefundAmountAndSummary = "step3",
  CardInfo = "step4-1",
  ContactInfo = "step4-2",
}

enum RequestType {
  digital = "refund-digital-request",
  cash = "refund-cash-request",
}

const RequestRefund = () => {
  const { state, dispatch } = React.useContext(UIContext);
  const navigate = useNavigate();
  const client = useApolloClient();

  const [location, setLocation] = useState<LaundromatLocation | undefined>(
    state.location
  );
  const [room, setRoom] = useState<Room | undefined>(state.room);
  const [selectedMachine, setSelectedMachine] = useState<Machine | undefined>(
    undefined
  );

  // How the original transaction was conducted
  const [howUserPaid, setHowUserPaid] = useState<HowUserPaid>();

  // Using this enum to keep track of where the user is in the process
  const [currentStep, setCurrentStep] = useState<ViewName>(ViewName.HowToPay);

  /** Form fields **/
  // Refund amount and summary inputs
  const [RefundAmount, setRefundAmount] = useState<string>("");
  const [Comments, setComments] = useState<string>("");

  // Digital
  const [LastFourDigits, SetLastFourDigits] = useState<string>("");
  const [Type, SetType] = useState<string>("");

  // Shared
  const [FirstName, SetFirstName] = useState<string>("");
  const [LastName, SetLastName] = useState<string>("");
  const [Email, SetEmail] = useState<string>("");
  const [PhoneNumber, SetPhoneNumber] = useState<string>("");
  const [StreetAddress, SetStreetAddress] = useState<string>("");
  const [StreetAddress2, SetStreetAddress2] = useState<string>("");
  const [City, SetCity] = useState<string>("");
  const [State, SetState] = useState<string>("");
  const [Zip, SetZip] = useState<string>("");

  // Show final success modal
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  // show error message view
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  // Step 0-1: Change step once user selects payment type
  useEffect(() => {
    if (howUserPaid && currentStep === ViewName.HowToPay) {
      setCurrentStep(ViewName.EnterLicense);
    }
  }, [howUserPaid]);

  // Step 1-2 Change step once user selects a machine
  useEffect(() => {
    if (currentStep === ViewName.EnterLicense && selectedMachine) {
      setCurrentStep(ViewName.RefundAmountAndSummary);
    }

    if (!selectedMachine) {
      return;
    }

    client
      .query<DataEndpointResponseGraphql>({
        query: GET_DATA,
        variables: { guid: selectedMachine.opaqueId },
        fetchPolicy: "network-only",
      })
      .then((roomInfo) => {
        dispatch({
          type: "set-init-props",
          payload: {
            ...roomInfo.data,
            guid: selectedMachine.opaqueId,
          } as SetInitPropsType,
        });
      });
  }, [selectedMachine]);

  // Step 2-3 Switch to big form based
  const submitRefundAmountAndSummary = () => {
    if (howUserPaid === HowUserPaid.cash) {
      setCurrentStep(ViewName.ContactInfo);
    } else if (howUserPaid === HowUserPaid.digital) {
      setCurrentStep(ViewName.CardInfo);
    }
  };

  /**
   * Successful callback for refund request (generic for digital and cash)
   */
  function onSuccess() {
    if (!selectedMachine) {
      throw new Error("Machine not defined!");
    }
    setCookie(
      null,
      selectedMachine.licensePlate,
      selectedMachine.licensePlate,
      {
        maxAge: 60 * 60 * 24 * 2, //48 hours
        path: "/",
      }
    );

    setShowConfirmationModal(true);

    dispatch({
      type: "log-event",
      payload: getOnSuccessfulRefundRequestEventPayload(
        FirstName,
        LastName,
        LastFourDigits,
        Type,
        Email
      ),
    });
  }

  /**
   * Query to submit digital refund request
   * @param request
   * @constructor
   */
  function SubmitDigitalRefundRequest(request: DigitalRefundRequestBody) {
    if (!selectedMachine) {
      throw new Error("Machine not defined!");
    }
    return client
      .mutate<DigitalRefundRequestData, DigitalRefundRequestVars>({
        mutation: DIGITAL_REFUND_REQUEST,
        variables: { body: request },
      })
      .then(onSuccess)
      .catch((err) => {
        logger.error(err, `Digital refund request failed!`);
        setShowErrorMessage(true);
      });
  }

  function SubmitCashRefundRequest(request: GenericRefundRequestBody) {
    if (!selectedMachine) {
      throw new Error("Machine not defined!");
    }
    return client
      .mutate<CashRefundRequestData, CashRefundRequestVars>({
        mutation: CASH_REFUND_REQUEST,
        variables: { body: request },
      })
      .then(onSuccess)
      .catch((err) => {
        logger.error(err, `Cash refund request failed!`);
        setShowErrorMessage(true);
      });
  }

  /**
   * submit refund request function
   * @param slug
   */
  const submitRefundRequest = (slug: RequestType): Promise<null | void> => {
    const cookies = parseCookies();
    if (!selectedMachine) {
      throw new Error("Machine not defined!");
    }

    dispatch({
      type: "log-event",
      payload: OnRequestRefundSubmitEventPayload,
    });

    if (
      cookies[selectedMachine.licensePlate] === selectedMachine.licensePlate
    ) {
      logger.warn("Cookie match found!");
      setShowErrorMessage(true);
      return Promise.resolve(null);
    }

    const request: DigitalRefundRequestBody = {
      firstName: FirstName,
      lastName: LastName,
      licensePlate: selectedMachine.licensePlate,
      email: Email,
      phone: PhoneNumber,
      source: "web", // RefundRequestSources: Enum: [ native, web, kiosk ]
      amount: parseFloat(RefundAmount) * 100,
      comments: Comments,
      street1: StreetAddress,
      street2: StreetAddress2,
      city: City,
      state: State,
      zipCode: Zip,
      creditCardType: Type,
      creditCardLast4: LastFourDigits,
      reasonId: ANONYMOUS_USER_REFUND_REQUEST_REASON, // the value is hardcoded in the backend
    };

    if (slug === RequestType.cash) {
      return SubmitCashRefundRequest(request);
    } else {
      return SubmitDigitalRefundRequest(request);
    }
  };

  /**
   * Hide the success modal
   */
  const hideSuccessModal = () => {
    // navigate to main page!
    setShowConfirmationModal(false);
    navigate(`/${LaundryRoute}`);
  };

  /**
   * Hide the error modal
   */
  const hideErrorModal = () => {
    setShowErrorMessage(false);
  };

  const ContactInfoProps = {
    SetStreetAddress: SetStreetAddress,
    SetStreetAddress2: SetStreetAddress2,
    SetCity: SetCity,
    SetZip: SetZip,
    SetState: SetState,
    StreetAddress: StreetAddress,
    StreetAddress2: StreetAddress2,
    Zip: Zip,
    State: State,
    City: City,
  };

  const { t } = useTranslation();

  return (
    <AppLessLayout headerType={"short"}>
      <React.Fragment>
        <Row className="text-center screen-title-row">
          <Col className="col-md-6 offset-md-3">
            <p className="text-center tag-line">
              {t("SideMenu.requestRefund")}
            </p>
          </Col>
        </Row>

        {currentStep === ViewName.HowToPay && (
          <HowToPay setHowUserPaid={setHowUserPaid} />
        )}

        {currentStep === ViewName.EnterLicense && (
          <EnterLicenseForRefund
            machines={state.machines}
            room={room}
            setRoom={setRoom}
            location={location}
            setLocation={setLocation}
            selectedMachine={selectedMachine}
            setSelectedMachine={setSelectedMachine}
          />
        )}

        {currentStep === ViewName.RefundAmountAndSummary && (
          <RefundAmountAndSummary
            room={room}
            location={location}
            machine={selectedMachine}
            comments={Comments}
            onSubmit={submitRefundAmountAndSummary}
            setComments={setComments}
            refundAmount={RefundAmount}
            setRefundAmount={setRefundAmount}
          />
        )}

        {currentStep === ViewName.CardInfo && (
          <CardInfo
            onSubmit={() => {
              submitRefundRequest(RequestType.digital);
            }}
            room={room}
            location={location}
            machine={selectedMachine}
            SetEmail={SetEmail}
            Email={Email}
            ContactInfo={ContactInfoProps}
            SetPhoneNumber={SetPhoneNumber}
            PhoneNumber={PhoneNumber}
            CardVerificationInfo={{
              SetType: SetType,
              Type: Type,
              SetLastFourDigits: SetLastFourDigits,
              LastFourDigits: LastFourDigits,
              SetFirstName: SetFirstName,
              FirstName: FirstName,
              SetLastName: SetLastName,
              LastName: LastName,
            }}
          />
        )}

        {currentStep === ViewName.ContactInfo && (
          <ContactInfo
            onSubmit={() => {
              submitRefundRequest(RequestType.cash);
            }}
            room={room}
            location={location}
            machine={selectedMachine}
            FirstName={FirstName}
            SetFirstName={SetFirstName}
            LastName={LastName}
            SetLastName={SetLastName}
            Email={Email}
            SetEmail={SetEmail}
            PhoneNumber={PhoneNumber}
            SetPhoneNumber={SetPhoneNumber}
            ContactInfo={ContactInfoProps}
          />
        )}

        <ConfirmationModal
          closeModal={hideSuccessModal}
          showModal={showConfirmationModal}
          modalType={ConfirmationModalType.refund}
        />
        <ErrorModal closeModal={hideErrorModal} showModal={showErrorMessage} />
      </React.Fragment>
    </AppLessLayout>
  );
};

export default RequestRefund;
