import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UIContext } from "../context/UIContext";
import MyLaundryManager from "../../lib/localStorage/MyLaundryManager";
import { Button, Col, FormControl, Modal, Row } from "react-bootstrap";
import logger from "../../lib/logger";
import { Machine } from "../../lib/types/ClientServices/Machines";
import { MachineRoute } from "../../routes/routes";
import { getOnStartNewMachineEventPayload } from "../tracking/SegmentEvents";
import { NewLoadSourceName } from "../tracking/SegmentEventTypes";
import { useTranslation } from "react-i18next";

interface MachineNewLoadProps {
  showText: boolean;
  sourceName: NewLoadSourceName;
}

/**
 * Modal that allows users to pick machines by sticker number
 * @param showText
 * @param sourceName
 * @constructor
 */
const MachineNewLoad: React.FC<MachineNewLoadProps> = ({
  showText,
  sourceName,
}) => {
  const navigate = useNavigate();

  // Control state for the UI
  const [isOpen, setShowNew] = useState(false);
  const [newMachine, setNewMachine] = useState("");
  const [isInvalid, setIsInvalid] = useState(true);
  const [machines, setMachines] = React.useState<Machine[]>();
  const inputRef = React.createRef<HTMLInputElement>();
  const { state, dispatch } = React.useContext(UIContext);

  useEffect(() => {
    if (!state.initialized) {
      return;
    }

    // If there aren't machines already added to the state, let's pull them from the active room
    if (!state.machines?.length) {
      const localMachines = MyLaundryManager.getRoomMachines();
      if (localMachines.length) {
        setMachines(localMachines);
      }
    } else {
      setMachines(state.machines);
    }
  }, [state.machines, state.initialized]);

  const showModal = () => {
    setShowNew(true);
  };

  const hideModal = () => {
    setShowNew(false);
  };

  const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setNewMachine(newValue);
    const validRoomSticker = isValidMachineSticker(newValue);
    setIsInvalid(
      !(newValue != null && parseFloat(newValue) > 0) || !validRoomSticker
    );
  };

  const submitValue = () => {
    setShowNew(false);
    const value = inputRef.current?.value;
    if (value != null) {
      newMachineHandler(value);
    }
  };

  const { t } = useTranslation();

  function isValidMachineSticker(sticker: string) {
    return !!machines?.find((machine) => machine.stickerNumber == sticker);
  }

  function newMachineHandler(value: string) {
    dispatch({
      type: "log-event",
      payload: getOnStartNewMachineEventPayload(sourceName),
    });

    logger.debug("new machine value: %o", value);
    const machine = machines?.find((machine) => machine.stickerNumber == value);
    if (machine != null) {
      navigate(`/${MachineRoute}/${machine.opaqueId}`);
    }
  }

  return (
    <Row className="start-new-row">
      <Col>
        <div className="start-new m-auto text-center">
          {(showText == undefined || showText) &&
            t("MachineNewLoad.readyForAnotherLoad")}
          <div className="text-center">
            <button className="start-new-row__button" onClick={showModal}>
              {t("MachineNewLoad.start")}
            </button>

            {/* Don't put this in a function */}
            <Modal
              show={isOpen}
              onHide={hideModal}
              centered={true}
              className="new-machine-dialog"
            >
              <Modal.Header>
                <Modal.Title>{t("MachineNewLoad.add")}</Modal.Title>
                <Button variant="primary" className="close" onClick={hideModal}>
                  <span aria-hidden="true">&times;</span>
                </Button>
              </Modal.Header>
              <Modal.Body className="m-auto text-center">
                <label>{t("MachineNewLoad.enterNumber")}</label>
                <FormControl
                  type="number"
                  id="newMachineInput"
                  value={newMachine}
                  placeholder="1"
                  ref={inputRef}
                  isInvalid={isInvalid}
                  onChange={inputChangeHandler}
                />
                <div className="invalid-feedback" hidden={!isInvalid}>
                  {t("MachineNewLoad.enterAValidNumber")}
                </div>
              </Modal.Body>
              <Modal.Footer className="m-auto">
                <Button
                  variant="primary"
                  className="px-4"
                  onClick={submitValue}
                  disabled={isInvalid}
                >
                  {t("MachineNewLoad.go")}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default MachineNewLoad;
