import { gql } from "@apollo/client";
import { Machine } from "../../types/ClientServices/Machines";

const GET_MACHINES = gql`
  query GetMachines {
    machines(licensePlates: $licensePlates)
      @rest(type: "GET", path: "machine?{args.licensePlates}") {
      opaqueId
      type
      locationId
      roomId
      stickerNumber
      licensePlate
      nfcId
      qrCodeId
      doorClosed
      available
      notAvailableReason
      inService
      freePlay
      mode
      display
      timeRemaining
      settings
      capability
      groupId
      stack
      stackItems
    }
  }
`;

export default GET_MACHINES;

export interface MachinesEndpointResponseGraphql {
  machines: Machine[];
}
