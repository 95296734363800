import { gql } from "@apollo/client";

interface Capability {
  showSettings?: boolean;
  addTime?: boolean;
  showAddTimeNotice?: boolean;
}

export interface MachinePricingDetail {
  type?: string;
  price?: number;
  label?: null;
  selection?: null;
  selectionGroup?: null;
}

interface MainCycle {
  default?: number;
  appless?: number | null;
  creditCard?: number | null;
}

export interface TopOffDuration {
  durationPerBlock?: number;
  options?: TopOffDurationOption[];
}

export interface TopOffDurationOption {
  zeroDecimalAmount?: number;
  displayAmount?: string;
  title?: string;
  duration?: number;
  blocks?: number;
}

export interface MachinePricing {
  selectedPrice: number;
  mainCycle?: MainCycle;
  mainCycleDuration?: number;
  capability?: Capability;
  topOff?: MainCycle;
  topOffDuration?: TopOffDuration;
  details: MachinePricingDetail[];
}

const GET_MACHINE_PRICE = gql`
  query GetMachinePrice {
    price(licensePlate: $licensePlate)
      @rest(
        type: "GET"
        path: "machine/{args.licensePlate}/price?paymentProvider=adyen"
      ) {
      selectedPrice
      mainCycle
      mainCycleDuration
      capability
      topOff
      topOffDuration
      details
    }
  }
`;

export default GET_MACHINE_PRICE;

export interface MachinePriceEndpointResponseGraphql {
  price: MachinePricing;
}
