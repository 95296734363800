import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Machine } from "../../lib/types/ClientServices/Machines";
import { SetInitPropsType, UIContext } from "../context/UIContext";
import { MachineTypes } from "../../lib/types/FrontEnd/Machines";
import useFeatureFlags from "../../lib/flags/useFeatureFlags";
import { Room } from "../../lib/types/FrontEnd/Room";
import logger from "../../lib/logger";
import { MockMachine } from "../../lib/mocks/MockMachine";
import EnterLicenseGeneric, {
  EnterLicenseGenericType,
} from "../enter-license/enter-license";
import GET_MACHINE, {
  MachineEndpointResponseGraphql,
} from "../../lib/graphql/queries/GetMachine";
import { useApolloClient } from "@apollo/client";
import GET_DATA, {
  DataEndpointResponseGraphql,
} from "../../lib/graphql/queries/GetData";
import { LaundromatLocation } from "../../lib/types/FrontEnd/LaundromatLocation";
import {
  RequestServiceMachineNotFoundNameEventPayload,
  RequestServicePageViewPayload,
} from "../tracking/SegmentEvents";

export interface EnterLicenseProps {
  selectedMachine?: Machine | undefined;
  setSelectedMachine: Dispatch<SetStateAction<Machine | undefined>>;
  room?: Room;
  setRoom: (value: Room) => void;
  location?: LaundromatLocation;
  setLocation: Dispatch<SetStateAction<LaundromatLocation | undefined>>;
}

const SAMPLE_LICENSE_PLATES = [
  "953-HRL",
  "954-HRL",
  "955-HRL",
  "956-HRL",
  "957-HRL",
  "958-HRL",
  "959-HRL",
  "960-HRL",
];

/**
 * Prompts the user to enter the license plate of the troubled machine
 * Note: this requires the parent component to pass in function to set machine state
 * @param props
 * @constructor
 */
const EnterLicenseForSupportRequest = (props: EnterLicenseProps) => {
  const { state, dispatch } = React.useContext(UIContext);
  const [showMachineNotFound, setShowMachineNotFound] = useState(false);
  const flags = useFeatureFlags();
  const client = useApolloClient();

  useEffect(() => {
    if (!state.initialized) {
      return;
    }
    dispatch({
      type: "log-event",
      payload: RequestServicePageViewPayload,
    });
  });

  /**
   * Helper function to toggle the machine not found UI
   * @param show
   */
  const ToggleMachineNotFound = (show: boolean) => {
    setShowMachineNotFound(show);

    if (show) {
      dispatch({
        type: "log-event",
        payload: RequestServiceMachineNotFoundNameEventPayload,
      });
    }
  };
  /**
   * Submit the license plate and store the machine to local state
   */
  const submit = (licensePlate: string) => {
    let machine: Machine | null;

    machine = state.getMachine(licensePlate);

    if (!machine) {
      logger.warn("License plate not found: %o", licensePlate);
    }

    /**
     * To allow QA/dev to test sample license plates, that aren't associated with any running machines
     */
    if (flags.allowFakeServiceMachines) {
      if (SAMPLE_LICENSE_PLATES.find((el) => el === licensePlate)) {
        machine = MockMachine(MachineTypes.Washer, {
          licensePlate: licensePlate,
        });
      }
    }

    /**
     * This will allow someone to report an issue with a machine if it's not in their local state?
     * Is this something we want to allow people to do?
     */
    if (machine) {
      props.setSelectedMachine(machine);
    } else {
      // This is messy! Try to clean up!
      client
        .query<MachineEndpointResponseGraphql>({
          query: GET_MACHINE,
          variables: { licensePlates: licensePlate },
          fetchPolicy: "network-only",
        })
        .then((res) => {
          if (res.error || !res.data.machine) {
            ToggleMachineNotFound(true);
          } else {
            const { data: newMachines } = res;
            if (newMachines.machine?.opaqueId) {
              client
                .query<DataEndpointResponseGraphql>({
                  query: GET_DATA,
                  variables: { guid: newMachines.machine.opaqueId },
                  fetchPolicy: "network-only",
                })
                .then((roomInfo) => {
                  if (roomInfo.error || !roomInfo.data) {
                    ToggleMachineNotFound(true);
                  } else {
                    dispatch({
                      type: "set-init-props",
                      payload: {
                        ...roomInfo.data,
                        guid: newMachines.machine.opaqueId,
                      } as SetInitPropsType,
                    });
                    props.setSelectedMachine(newMachines.machine);
                    props.setRoom(roomInfo.data.data.room);
                    props.setLocation(roomInfo.data.data.location);
                  }
                })
                .catch(() => {
                  ToggleMachineNotFound(true);
                });
            }
          }
        });
    }
  };

  return (
    <EnterLicenseGeneric
      ToggleMachineNotFound={ToggleMachineNotFound}
      showMachineNotFound={showMachineNotFound}
      submit={submit}
      type={EnterLicenseGenericType.service}
    />
  );
};

export default EnterLicenseForSupportRequest;
