import React, { useEffect, useState } from "react";
import GenericModal, { buttonType, modalType } from "./generic-modal";
import { UIContext } from "../context/UIContext";
import {
  OnConfirmationModalCloseRefundSuccessEventPayload,
  OnConfirmationModalCloseServiceRequestEventPayload,
} from "../tracking/SegmentEvents";
import { useTranslation } from "react-i18next";

export enum ConfirmationModalType {
  refund,
  service,
}

interface ConfirmationModalProps {
  showModal: boolean;
  closeModal: () => void;
  title?: string;
  copy?: string;
  modalType?: ConfirmationModalType;
}

/**
 * Simple higher order component for our GenericModal that populates defaults for the confirmation modal design.
 * @param props
 * @constructor
 */
const ConfirmationModal = (props: ConfirmationModalProps): JSX.Element => {
  const { dispatch } = React.useContext(UIContext);
  const { t } = useTranslation();
  const [close, setClose] = useState<any>("");
  const [title, setTitle] = useState<any>("");
  const [copy, setCopy] = useState<any>("");
  useEffect(() => {
    setClose(t("ConfirmationModal.close"));
    setTitle(t("ConfirmationModal.title"));
    setCopy(t("ConfirmationModal.copy"));
  });
  return (
    <GenericModal
      buttons={[
        {
          text: close,
          type: buttonType.primary,
          onClick: () => {
            if (props?.modalType === ConfirmationModalType.refund) {
              dispatch({
                type: "log-event",
                payload: OnConfirmationModalCloseRefundSuccessEventPayload,
              });
            } else if (props?.modalType === ConfirmationModalType.service) {
              dispatch({
                type: "log-event",
                payload: OnConfirmationModalCloseServiceRequestEventPayload,
              });
            }
          },
        },
      ]}
      showModal={props.showModal}
      closeModal={props.closeModal}
      title={title || props.title}
      copy={[copy || props.copy]}
      type={modalType.success}
    />
  );
};

ConfirmationModal.defaultProps = {
  title: "Request Submitted",
  copy: "Thank you for your request. We’ll send you an email to let you know if the request is approved.",
};

export default ConfirmationModal;
