import React, { useEffect, useState } from "react";
import {
  LaundryRoute,
  PressStartRoute,
  StartedRoute,
  StudentPaymentRoute,
} from "../../../routes/routes";
import { useLocation, useNavigate } from "react-router-dom";
import "./StudentPaymentButton.scss";
import { UIContext } from "../../context/UIContext";
import { ServerError, useApolloClient } from "@apollo/client";
import logger from "../../../lib/logger";
import { setFlashMessage, StickyMessageType } from "../../alerts/StickyMessage";
import { ErrorResponse } from "@apollo/client/link/error";
import SSO_PAYMENT, {
  AprivaErrorResponse,
  startMachineWithAnonStudentSsoTokenProps,
} from "../../../lib/graphql/mutations/SSOPayment";
import StudentErrorModal, {
  StudentPaymentError,
} from "../Modals/StudentErrorModal";
import LoadingSVG from "../../../../assets/img/loading-spinner.svg";
import { VendMethod, VendPrice } from "../../../lib/types/FrontEnd/vend-price";
import useFeatureFlags from "../../../lib/flags/useFeatureFlags";
import { onAdyenPaymentProps } from "../../../lib/types/FrontEnd/Adyen";
import {
  getOnPaymentAttemptEventPayload,
  getPaymentErrorEventPayload,
  getPaymentSuccessEventPayload,
  getssoViewedEventPayload,
} from "../../tracking/SegmentEvents";
import { paymentMethods } from "../../../lib/types/FrontEnd/Payment";
import { TRANSACTION_FEE_PRICE_TYPE } from "../../machine/machine-price";
import { TopOffDurationOption } from "../../../lib/types/FrontEnd/MachinePricing";
import { useTranslation } from "react-i18next";

interface StudentPaymentButtonProps {
  cardName?: string;
  fees: boolean;
  machineGuid: string;
  additionalBlocks?: number;
  price: VendPrice | undefined;
  priceOptions?: TopOffDurationOption[];
  isSSOLoading?: boolean;
  setIsSSOLoading: (state: boolean) => void;
}

export const APRIVA_SERVICE_ERRORS = {
  INSUFFICIENT_FUNDS: {
    CODE: 14,
  },
};

export const ssoNames = {
  atrium: "atrium",
  adyen: "adyen",
  apriva: "apriva",
  cboard: "cbord",
};

export const StudentPaymentButton = (props: StudentPaymentButtonProps) => {
  const navigate = useNavigate();
  const origin = window.location.origin;
  const location = useLocation();
  const { state, dispatch } = React.useContext(UIContext);
  const sourceMachine = state.getSourceMachine();
  const { isSSOLoading, setIsSSOLoading } = props;
  const client = useApolloClient();
  const [errorModalType, setErrorModalType] = useState<
    StudentPaymentError | undefined
  >();
  let additionalBlocks = props?.additionalBlocks ?? 0;
  const durationPerBlock = props?.price?.topOffDuration?.duration;
  const addedTime = () => {
    return additionalBlocks * (durationPerBlock ?? 0);
  };
  const vendMethod = additionalBlocks > 0 ? VendMethod.topOff : VendMethod.full;

  const option =
    props?.priceOptions?.find((option) => option.blocks === additionalBlocks) ??
    undefined;

  const elementsToSumForCost = props?.price?.details
    ? props?.price?.details
        .filter(
          (priceDetail) => priceDetail.type !== TRANSACTION_FEE_PRICE_TYPE
        )
        .map((fee) => fee.price)
    : undefined;

  const baseCost = option
    ? option.zeroDecimalAmount
    : elementsToSumForCost?.reduce((sum, x) => (sum ?? 0) + (x ?? 0), 0) ??
      props?.price?.selectedPrice ??
      0;
  const legacyBasecost = () =>
    (additionalBlocks ?? 1) * (props?.price?.zeroDecimalAmount ?? 0);

  const flags = useFeatureFlags();
  const ssoEnabled = flags?.allowSsoStudentPayment;

  const { t } = useTranslation();

  const atriumSSoUrl = () => {
    const url = state.location?.schoolServiceProvider?.ssoUrl.split("?") ?? [];
    const atriumProvitioningUrlProd = url[0];
    const withQuerys = atriumProvitioningUrlProd + "?partner=cscgo&url=";
    savingAdditionalBlocks(additionalBlocks);
    const redirectUrl =
      additionalBlocks && additionalBlocks !== 0
        ? `${origin}/${StartedRoute}/${sourceMachine?.opaqueId}`
        : `${origin}/${LaundryRoute}/machine/${sourceMachine?.opaqueId}`;
    const ssoUrl = withQuerys.concat(redirectUrl);
    return ssoUrl;
  };

  useEffect(() => {
    if (localStorage.getItem("SSOToken")) {
      const token = localStorage.getItem("SSOToken") as string;
      additionalBlocks = Number(localStorage.getItem("additionalBlocks"));
      const title = "Closed SSO Screen";
      dispatch({
        type: "log-event",
        payload: getssoViewedEventPayload(title),
      });
      onSubmit(token);
    } else if (localStorage.getItem("SSOAtriumToken")) {
      const token = localStorage.getItem("SSOAtriumToken") as string;
      additionalBlocks = Number(localStorage.getItem("additionalBlocks"));
      const title = "Closed SSO Screen";
      dispatch({
        type: "log-event",
        payload: getssoViewedEventPayload(title),
      });
      onSubmit(token);
    }
  }, [location]);

  const savingAdditionalBlocks = (additionalBlocks: number) => {
    if (additionalBlocks && additionalBlocks !== 0) {
      localStorage.setItem("additionalBlocks", additionalBlocks.toString());
    }
  };

  const onClick = () => {
    if (ssoEnabled && state.location?.schoolServiceProvider?.ssoUrl) {
      const title = "Viewed SSO Screen";
      dispatch({
        type: "log-event",
        payload: getssoViewedEventPayload(title),
      });
      if (origin.includes("localhost")) {
        // use dummy URL
        if (
          state.location?.schoolServiceProvider?.ssoUrl &&
          state.location?.schoolServiceProvider?.name === ssoNames.atrium
        ) {
          const ssoUrl = atriumSSoUrl();
          window.location.replace(ssoUrl);
        } else {
          savingAdditionalBlocks(additionalBlocks);
          navigate(
            additionalBlocks && additionalBlocks !== 0
              ? `/${StartedRoute}/${sourceMachine?.opaqueId}?&token=7640b184-5b3c-4692-bda6-979011fb033e`
              : `/${LaundryRoute}/machine/${sourceMachine?.opaqueId}?&token=7640b184-5b3c-4692-bda6-979011fb033e`
          );
        }
      } else {
        // use SSO URL
        // The location object returns the app URL (mobileapp=2) but we need to use the web url (mobileapp=3)
        if (
          state.location?.schoolServiceProvider?.ssoUrl &&
          state.location?.schoolServiceProvider?.name === ssoNames.atrium
        ) {
          // this use the atrium SSO URL with the iframe in PROD, DEV, and Staging
          const ssoUrl = atriumSSoUrl();
          window.location.replace(ssoUrl);
        } else {
          const providerUrl =
            state.location?.schoolServiceProvider?.ssoUrl.split(
              "?mobileapp=2"
            )[0];
          savingAdditionalBlocks(additionalBlocks);
          const redirectUrl =
            additionalBlocks && additionalBlocks !== 0
              ? `${origin}/${StartedRoute}/${sourceMachine?.opaqueId}`
              : `${origin}/${LaundryRoute}/machine/${sourceMachine?.opaqueId}`;
          const ssoParams = `?mobileapp=3&redirecturl=${redirectUrl}`;
          const ssoUrl = providerUrl?.concat(ssoParams);
          window.location.replace(ssoUrl);
        }
      }
    } else {
      navigate(`/${StudentPaymentRoute}/${sourceMachine?.opaqueId}`, {
        state: {
          additionalBlocks: props.additionalBlocks,
        },
      });
    }
  };

  const onSubmitEvent = (props: onAdyenPaymentProps) => {
    dispatch({
      type: "log-event",
      payload: getOnPaymentAttemptEventPayload({
        ...props,
        processor: "Apr",
      }),
    });
  };

  const onSubmit = (token: string) => {
    onSubmitEvent({
      Added_Time: addedTime(),
      method: vendMethod,
      type: paymentMethods.studentcard,
      amount: additionalBlocks > 1 ? legacyBasecost() || 0 : baseCost,
      ssoPayment:
        state.location?.schoolServiceProvider?.name === ssoNames.atrium
          ? "sso_atrium"
          : "sso_cbord",
    });

    if (
      !state.room?.roomId ||
      !state.location?.locationId ||
      !state.location?.as400Number ||
      !sourceMachine?.licensePlate
    ) {
      throw new Error("Missing data on apriva submit!");
    }

    setIsSSOLoading(true);

    const payload: startMachineWithAnonStudentSsoTokenProps = {
      roomId: state.room.roomId,
      locationId: state.location.locationId,
      as400Number: state.location.as400Number,
      licensePlate: sourceMachine.licensePlate,
      amount: additionalBlocks > 1 ? legacyBasecost() : baseCost ?? 0,
      currency: "usd",
      additionalBlocks: additionalBlocks,
      ssoToken: token,
    };

    client
      .mutate<any, any>({
        mutation: SSO_PAYMENT,
        variables: {
          body: payload,
        },
      })
      .then((response) => {
        setIsSSOLoading(false);
        logger.debug("apriva payment response: %o", response);
        const data = response?.data?.data;

        if (!data?.success) {
          const responseText = data?.data.responseText;
          const responseCode = data?.data.responseCode;
          logger.error("responseText: %s", responseText);
          logger.error("responseCode: %s", responseCode);
          logger.debug("Response: %o", data);
          navigate(`/${LaundryRoute}/machine/${sourceMachine?.opaqueId}`);
          setFlashMessage(
            t("StudentPaymentButton.paymentFailed"),
            StickyMessageType.error
          );
        } else {
          dispatch({
            type: "log-event",
            payload: getPaymentSuccessEventPayload(
              {
                method: vendMethod,
                duration: addedTime(),
                zeroDecimalAmount:
                  additionalBlocks > 1 ? legacyBasecost() : baseCost ?? 0,
              },
              paymentMethods.studentcard,
              "we can't track student card since it is only on cbord", // we can not track a user's student card since it is only entered in the external sso page Note: just give it a value so it doesn't change the pament processor into adyen
              state.location?.schoolServiceProvider?.name === ssoNames.atrium
                ? "sso_atrium"
                : "sso_cbord"
            ),
          });

          if (additionalBlocks && additionalBlocks > 0 && durationPerBlock) {
            setFlashMessage(
              `${durationPerBlock * additionalBlocks} ${t(
                "StudentPaymentButton.minAdded"
              )}`,
              StickyMessageType.topOffCompleteNotice
            );
            localStorage.removeItem("additionalBlocks");
            localStorage.removeItem("SSOToken");
            localStorage.removeItem("SSOAtriumToken");
            window.location.reload();
          } else {
            setFlashMessage(
              t("StudentPaymentButton.paymentSuccessful"),
              StickyMessageType.paymentCompleteNotice
            );
            if (!state.isSSESupported) {
              localStorage.removeItem("SSOToken");
              localStorage.removeItem("SSOAtriumToken");
              navigate(`/${PressStartRoute}/${sourceMachine.opaqueId}`);
            }
          }
        }
      })
      .catch((errorResponse: ErrorResponse) => {
        const networkError = errorResponse.networkError as ServerError;

        const error: AprivaErrorResponse =
          networkError?.result as AprivaErrorResponse;

        // Check to see if the error code meets a specific error message requirement
        if (
          error?.errors &&
          error.errors.length &&
          error.errors.some((err) => {
            return err.code === APRIVA_SERVICE_ERRORS.INSUFFICIENT_FUNDS.CODE;
          })
        ) {
          setErrorModalType(StudentPaymentError.insufficientBalance);
        } else {
          // Otherwise set generic error state
          setErrorModalType(StudentPaymentError.somethingWentWrong);
        }

        const [firstError] = error.errors;

        dispatch({
          type: "log-event",
          payload: getPaymentErrorEventPayload(
            {
              duration: addedTime(),
              method: vendMethod,
              zeroDecimalAmount:
                additionalBlocks > 1 ? Number(legacyBasecost) : baseCost ?? 0,
            },
            {
              success: false,
              statusCode: firstError.code.toString(),
              message: firstError.message,
            },
            "we can't track student card since it is only on cbord", // we can not track a user's student card since it is only entered in the external sso page
            state.location?.schoolServiceProvider?.ssoUrl.includes("atrium")
              ? "sso_atrium"
              : "sso_cbord"
          ),
        });
        localStorage.removeItem("additionalBlocks");
        localStorage.removeItem("SSOToken");
        localStorage.removeItem("SSOAtriumToken");
        setIsSSOLoading(false);
        navigate(
          additionalBlocks && additionalBlocks !== 0
            ? `/${StartedRoute}/${sourceMachine?.opaqueId}`
            : `/${LaundryRoute}/machine/${sourceMachine?.opaqueId}`
        );
        logger.error("Error making student payment!: %o", error);
        throw error;
      });
  };

  return (
    <>
      <button
        onClick={onClick}
        className={"student-payment-button"}
        disabled={isSSOLoading}
      >
        {!isSSOLoading
          ? `${t("StudentPaymentButton.payWith")} ${props.cardName}`
          : ""}
        {isSSOLoading && (
          <img
            className={"student-payment-form__button__ssoLoading"}
            src={LoadingSVG}
            alt="Loading..."
          />
        )}
      </button>
      {props.fees && (
        <span className={"student-payment-button__fees"}>
          {t("StudentPaymentButton.convenienceFee")}
        </span>
      )}

      <StudentErrorModal
        showModal={errorModalType !== undefined}
        closeModal={() => setErrorModalType(undefined)}
        modalType={errorModalType}
      />
    </>
  );
};
