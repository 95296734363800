import { gql } from "@apollo/client";
import { ANONYMOUS_USER_REFUND_REQUEST_REASON } from "./DigitalRefundRequest";

export interface GenericRefundRequestBody {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  source: string;
  amount: number;
  reasonId: typeof ANONYMOUS_USER_REFUND_REQUEST_REASON;
  comments: string;
  licensePlate: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface CashRefundRequestVars {
  body: GenericRefundRequestBody;
}

interface CashRefundRequest {
  correlation: string;
  ts: string;
  fullName: string;
  email: string;
  phone: string;
  source: string;
  paymentMethod: string;
  amount: string;
  reasonId: string;
  reason: string;
  comments: string;
  as400Number: string;
  locationId: string;
  roomId: string;
  licensePlate: string;
  userId: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zipCode: string;
  creditCardType: string;
  creditCardLast4: string;
}

export interface CashRefundRequestData {
  data: CashRefundRequest;
}

const CASH_REFUND_REQUEST = gql`
  mutation CashRefundRequest {
    data(body: $body)
      @rest(
        type: "Post"
        path: "commands/submitCashRefundRequest"
        method: "POST"
        bodyKey: "body"
      ) {
      success
      correlation
    }
  }
`;

export default CASH_REFUND_REQUEST;
