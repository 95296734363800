import { gql } from "@apollo/client";

const START_MACHINE_FREE_PLAY = gql`
  mutation StartMachineFreePlay {
    data(body: $body)
      @rest(
        type: "Post"
        path: "commands/startMachineFreePlay"
        method: "POST"
        bodyKey: "body"
      ) {
      id
      name
      success
      ts
    }
  }
`;

export default START_MACHINE_FREE_PLAY;

export interface StartMachineFreePlayVars {
  body: {
    licensePlate: string;
    additionalBlocks?: number | null;
  };
}

export interface StartMachineFreePlayResponse {
  data: any;
}
