import { Machine, MachineMode } from "../lib/types/ClientServices/Machines";
import { VendMethod, VendPrice } from "../lib/types/FrontEnd/vend-price";
import { adyenPaymentResult } from "../components/machine/machine-payment-link";
import { SetInitPropsType, UIContext } from "../components/context/UIContext";
import logger from "../lib/logger";
import AppLessLayout from "../components/layout/Layout";
import LocationTitle from "../components/location-title";
import Loading from "../components/Loading";
import React, {
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import MyLaundryManager from "../lib/localStorage/MyLaundryManager";
import { useNavigate, useParams } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import GET_MACHINE_PRICE, {
  MachinePriceEndpointResponseGraphql,
} from "../lib/graphql/queries/GetMachinePrice";
import { PressStartRoute } from "../routes/routes";
import MachinePrice from "../components/machine/machine-price";
import getLaundryRedirectUrlFromMachine from "../lib/helpers/GetLaundryRedirect";
import START_MACHINE_FREE_PLAY, {
  StartMachineFreePlayResponse,
  StartMachineFreePlayVars,
} from "../lib/graphql/mutations/StartMachineFreePlay";
import {
  FlashMessageLocalStorageName,
  FlashMessageProps,
  StickyMessageType,
} from "../components/alerts/StickyMessage";
import GET_DATA from "../lib/graphql/queries/GetData";
import SSEFallbackUpdater from "../components/fallbacks/SSEFallbackUpdater";
import { GetMachineUnavailableEventPayload } from "../components/tracking/SegmentEvents";
import { AdyenThreeDSCallback } from "../components/payment/AdyenThreeDSCallback";
import { getDisplayAmount } from "../lib/helpers/getDisplayAmount";
import MachineDisplay from "../components/machine/MachineDisplay";
import PaymentOptions from "./PaymentOptions";
import { FetchRoomSummary } from "../components/student-payment/FetchRoomSummary/FetchRoomSummary";
import { useTranslation } from "react-i18next";

const isFree = (machine: Machine): boolean => {
  return machine.freePlay;
};

const isFreeByVendPrice = (vendPrice: VendPrice): boolean => {
  return vendPrice.zeroDecimalAmount === 0;
};

/**
 * Entry point for adding new machines to users session
 * @constructor
 */
const MachineView: FunctionComponent<{ is3DSreturn: boolean }> = (
  props: PropsWithChildren<{ is3DSreturn: boolean }>
) => {
  const { dispatch, state } = useContext(UIContext);
  const { guid } = useParams();
  const navigate = useNavigate();
  const [sourceMachine, setSourceMachine] = useState(
    state.getSourceMachine(guid)
  );
  const client = useApolloClient();
  const [isMachineOnline, setIsMachineOnline] = useState<boolean>(true);
  // Price info and current price
  const [price, setPrice] = useState<VendPrice>();
  const localStorageManager = new MyLaundryManager();
  const is3DSreturn = props.is3DSreturn ?? false;
  const { t } = useTranslation();

  // On global machine state change
  useEffect(() => {
    setSourceMachine(state.getSourceMachine(guid));
  }, [JSON.stringify(state.machines)]);

  // On init
  useEffect(() => {
    if (!state.initialized) {
      return;
    }

    const machine = state.getSourceMachine(guid);

    if (machine) {
      // If we have a machine and a state to add it to, let's add it
      if (guid && machine.opaqueId !== guid && state.machines?.length) {
        const newMachine = state.machines.find((m) => m.opaqueId === guid);
        if (newMachine) {
          setSourceMachine(newMachine);
          MyLaundryManager.addLicensePlateToTrack(newMachine.licensePlate);
          dispatch({
            type: "set-source-machine",
            payload: { guid: newMachine.opaqueId },
          });
        } else {
          MyLaundryManager.addLicensePlateToTrack(machine.licensePlate);
        }
      } else {
        MyLaundryManager.addLicensePlateToTrack(machine.licensePlate);
      }
    } else {
      logger.error("Unable to get source machine on initialization!");
    }
  }, [state.initialized]);

  // On source machine change
  useEffect(() => {
    if (!sourceMachine || !state.room || !state.location) {
      return;
    }

    if (sourceMachine && sourceMachine.mode !== MachineMode.idle) {
      localStorageManager.upsertMachine(sourceMachine);
      navigate(getLaundryRedirectUrlFromMachine(sourceMachine), {
        replace: true,
      });
      return;
    }

    setIsMachineOnline(sourceMachine.available ?? false);

    if (!sourceMachine.available) {
      dispatch({
        type: "log-event",
        payload: GetMachineUnavailableEventPayload(sourceMachine),
      });
      return;
    }

    client
      .query<MachinePriceEndpointResponseGraphql>({
        query: GET_MACHINE_PRICE,
        variables: { licensePlate: sourceMachine.licensePlate },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        if (!state.room) {
          throw new Error("room is not defined, cannot set price info!");
        }

        if (!state.location) {
          throw new Error("location is not defined, cannot set price info!");
        }

        const machinePricing = response.data.price;
        logger.debug(machinePricing);
        setPrice({
          capabilities: sourceMachine.capability,
          currency: "usd",
          zeroDecimalAmount: machinePricing.selectedPrice,
          displayAmount: getDisplayAmount(
            isFree(sourceMachine),
            machinePricing.selectedPrice
          ),
          locationId: state?.location.locationId,
          roomId: state.room.roomId,
          as400Number: state.location.as400Number,
          method: VendMethod.full,
          details: machinePricing.details,
          selectedPrice: machinePricing.selectedPrice,
          additionalBlocks: null,
          topOffDuration: null,
        });
      })
      .catch((err) => {
        logger.error(err);
        logger.warn("Failed to fetch machine price!");
        setIsMachineOnline(false);
      });

    if (state.location) {
      MyLaundryManager.updateLocation(state.location);
    }

    if (state.room) {
      MyLaundryManager.updateRoom(state.room);
    }
  }, [sourceMachine]);

  // On mount
  useEffect(() => {
    dispatch({ type: "log-page-view" });
  }, []);

  const paymentCompleteHandler = async ({
    success,
    result,
  }: {
    success: boolean;
    result: adyenPaymentResult | null;
  }) => {
    logger.debug("CLIENT PAYMENT COMPLETE:", result);
    logger.debug(
      success
        ? "Payment was successful, starting the machine"
        : "Payment was NOT successful, recording the failure"
    );

    if (!sourceMachine) {
      throw new Error("Source machine is not present on payment complete!");
    }

    if (!state.location) {
      throw new Error("Source machine is not present on payment complete!");
    }

    if (success) {
      if (price && !isFreeByVendPrice(price)) {
        const messageToFlashOnNextPage: FlashMessageProps = {
          type: StickyMessageType.paymentCompleteNotice,
          text: `${t("StudentPaymentForm.paymentSuccess")}`,
        };

        localStorage.setItem(
          FlashMessageLocalStorageName,
          JSON.stringify(messageToFlashOnNextPage)
        );
      }

      // If the payment went through, add the machine to the user's local state
      localStorageManager
        .addMachine(sourceMachine?.licensePlate, state.location)
        .then((result) => {
          const machine = result.find(
            (m) =>
              m.machine.licensePlate ===
              state.getSourceMachine(guid)?.licensePlate
          );
          if (!machine) {
            throw new Error("Unable to find machine!");
          }
          logger.debug(
            "successfully started machine, tracking new machine: %o",
            result
          );

          // If SSE is not supported by the client, forward them to the next step, and update the global state
          if (!state.isSSESupported) {
            client
              .query({
                query: GET_DATA,
                variables: { guid: machine.machine.opaqueId },
                fetchPolicy: "network-only",
              })
              .then((result) => {
                dispatch({
                  type: "set-init-props",
                  payload: { ...result.data, guid: guid } as SetInitPropsType,
                });
              })
              .catch((error) => {
                logger.error(
                  "error calling /machine/info in SSE fallback mode: %o",
                  error
                );
              });
            navigate(`/${PressStartRoute}/${machine.machine.opaqueId}`, {
              replace: true,
            });
          }
        });
    }
  };

  const continueOnClick = () => {
    paymentCompleteFreeHandler();
  };

  /**
   * Starts a free play machine, navigation to the "pressStart" screen is handled by SSE, don't send the user directly
   */
  const paymentCompleteFreeHandler = (): Promise<void> => {
    if (!sourceMachine?.licensePlate) {
      throw new Error("Source machine is not defined, cannot start machine!");
    }
    return client
      .mutate<StartMachineFreePlayResponse, StartMachineFreePlayVars>({
        mutation: START_MACHINE_FREE_PLAY,
        variables: { body: { licensePlate: sourceMachine?.licensePlate } },
      })
      .then(() => {
        return paymentCompleteHandler({ success: true, result: null }).then(
          () => {
            if (!state.isSSESupported) {
              navigate(`/${PressStartRoute}/${state.guid}`, { replace: true });
            }
          }
        );
      });
  };

  if (!sourceMachine) {
    return (
      <AppLessLayout noContext={true}>
        <Loading />
      </AppLessLayout>
    );
  }

  return (
    <AppLessLayout>
      <FetchRoomSummary />
      <SSEFallbackUpdater
        isSSEEnabled={state.isSSESupported}
        machine={sourceMachine}
      />
      <>
        {is3DSreturn && <AdyenThreeDSCallback machine={sourceMachine} />}
        <LocationTitle location={state.location} />
        <MachineDisplay machine={sourceMachine} />

        {price && (
          <MachinePrice
            machine={sourceMachine}
            displayAmount={price.displayAmount}
            details={price.details}
          />
        )}
        <PaymentOptions
          machine={sourceMachine}
          price={price}
          isOnline={isMachineOnline}
          freePlayOnClick={continueOnClick}
          paymentCompleteCallback={paymentCompleteHandler}
        />
      </>
    </AppLessLayout>
  );
};

export default MachineView;
