import { gql } from "@apollo/client";

export interface AprivaPaymentResponse {
  name: string;
  success: boolean;
  data: {
    timestamp: string;
    amount: number;
    uniqueRequestId: string;
    authCode: string;
    responseCode: string;
    responseText: string;
    captured: boolean;
    description: string;
  };
}

export interface startMachineWithAnonStudentSsoTokenProps {
  roomId: string;
  locationId: string;
  as400Number: string;
  licensePlate: string;
  amount: number;
  currency: "usd";
  additionalBlocks: number | undefined;
  comment?: string;
  ssoToken: string;
}

export interface SSOPayload {
  body: startMachineWithAnonStudentSsoTokenProps;
}

export interface SSOPaymentData {
  data: AprivaPaymentResponse;
}

export interface AprivaErrorResponse {
  id: string;
  ts: string;
  name: string;
  success: boolean;
  errors: Array<{
    code: number;
    message: string;
    displayMessage: string;
  }>;
}

const SSO_PAYMENT = gql`
  mutation DigitalRefundRequest {
    data(body: $body)
      @rest(
        type: "Post"
        path: "commands/startMachineWithAnonStudentSsoToken"
        method: "POST"
        bodyKey: "body"
      ) {
      name
      success
      data
    }
  }
`;

export default SSO_PAYMENT;
