import { GetMachineByNFC } from "../graphql/queries/GetMachineByNFC";
import MyLaundryManager from "../localStorage/MyLaundryManager";
import { LaundromatLocation } from "../types/FrontEnd/LaundromatLocation";
import { Room } from "../types/FrontEnd/Room";

// Set local storage overrides if the user's location or room has changed, after the redirect we'll call the GET_DATA query which will populate local storage will the full objects, so they're fine to just mock for now, but the SSE url needs these local storage variables set sp we can bootstrap the UIContext
export function setLocalStorageFromAPIResponse(data: GetMachineByNFC) {
  const location = MyLaundryManager.getLocation();
  const room = MyLaundryManager.getRoom();
  if (location?.locationId !== data.machine.locationId) {
    // So, instead of querying a fresh copy of the location which we'll pull in as soon as the init-props dispatch is kicked off on the next page load,
    // Let's just save a partial location, which is at least enough to bootstrap the SSE URL
    MyLaundryManager.updateLocation({
      locationId: data.machine.locationId,
    } as LaundromatLocation);
  }
  if (room?.roomId !== data.machine.roomId) {
    MyLaundryManager.updateRoom({ roomId: data.machine.roomId } as Room);
  }
}
