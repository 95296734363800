export const RequestRefundRoute = "request-refund";
export const RequestServiceRoute = "request-service";
export const LaundryRoute = "laundry";
export const MyLaundryRoute = `${LaundryRoute}/my-laundry`;
export const PressStartRoute = `${LaundryRoute}/press-start`;
export const StartedRoute = `${LaundryRoute}/started`;
export const DoneRoute = `${LaundryRoute}/done`;
export const MachineRoute = `${LaundryRoute}/machine`;
export const StudentPaymentRoute = `${LaundryRoute}/studentPayment`;

// Machine slug
export const MachineRouteParamName = ":guid";

// NFC / QR entry points
export const NFCRoute = "nfc";
export const NFCRouteParamName = ":nfc";
export const QRRoute = "qr";
export const QRRouteParamName = ":qr";

// 3DS entry points
export const ThreeDSRRouteSlug = "3ds";
export const ThreeDSAdditionalBlocksUrlParameter = "ab";
export const ThreeDSDurationUrlParameter = "dur";

// Room summary entry points
export const BaseRoomSummaryRoute = `${LaundryRoute}/summary`;
export const RoomSummaryRoomParameter = ":roomId";
export const RoomSummaryLocationParameter = ":locationId";
export const RoomSummaryRoute = `${LaundryRoute}/summary/${RoomSummaryLocationParameter}/${RoomSummaryRoomParameter}`;

// Account deletion
export const AccountDeletionRoute = "account-deletion";
export const AccountDeletionSuccessRoute = `${AccountDeletionRoute}/success`;
