import React from "react";
import { UIContext } from "../context/UIContext";
import { getOnPaymentRefundRequestEventPayload } from "../tracking/SegmentEvents";
import { useTranslation } from "react-i18next";

interface HowToPayProps {
  setHowUserPaid: React.Dispatch<React.SetStateAction<HowUserPaid | undefined>>;
}

enum HowUserPaid {
  digital = "DIGITAL",
  cash = "CASH",
}

/**
 *
 * @param props
 * @constructor
 */
const HowToPay = (props: HowToPayProps) => {
  const { dispatch } = React.useContext(UIContext);

  /**
   * Generic handler for the segment events in this component
   * @param title
   */
  const clickEvent = (title: "Digital Payment" | "Cash or Coins") => {
    dispatch({
      type: "log-event",
      payload: getOnPaymentRefundRequestEventPayload(title),
    });
  };

  const onDigitalPaymentClick = () => {
    props.setHowUserPaid(HowUserPaid.digital);
    clickEvent("Digital Payment");
  };

  const onPhysicalPaymentClick = () => {
    props.setHowUserPaid(HowUserPaid.cash);
    clickEvent("Cash or Coins");
  };

  const { t } = useTranslation();

  return (
    <div className={"how-to-pay"}>
      <div className={"how-to-pay__title"}>{t("HowToPay.title")}</div>
      <button
        onClick={onDigitalPaymentClick}
        className={"how-to-pay__button btn btn-primary"}
      >
        {t("HowToPay.firstButton")}{" "}
      </button>
      <button
        onClick={onPhysicalPaymentClick}
        className={"how-to-pay__button btn btn-primary"}
      >
        {t("HowToPay.secondButton")}
      </button>
    </div>
  );
};

export default HowToPay;
