import React from "react";
import { Col, Row } from "react-bootstrap";
import MachineName from "./MachineName";
import { Machine } from "../../lib/types/ClientServices/Machines";
import offlineDryerIcon from "../../../assets/machine/offline/OfflineDryerIcon.svg";
import offlineWasherIcon from "../../../assets/machine/offline/OfflineWasherIcon.svg";
import RoomSummarySplash from "./RoomSummarySplash";
import { MachineTypesClientServices } from "../../lib/types/FrontEnd/Machines";
import { useTranslation } from "react-i18next";

const OfflineMachine = (props: { machine: Machine }) => {
  const { t } = useTranslation();
  return (
    <>
      <Row className="machine-state-row">
        <Col className="col-md-6 offset-md-3">
          <div className="machine-state m-auto text-center">
            <MachineName machine={props.machine} />
            <div className={"offline-machine"}>
              <h3 className="offline-machine__title">
                {t("MachineOffline.offline")}
              </h3>
              <div className="offline-machine__image-wrap">
                <img
                  className={"offline-machine__image-wrap"}
                  src={
                    props?.machine?.type === MachineTypesClientServices.Washer
                      ? offlineWasherIcon
                      : offlineDryerIcon
                  }
                />
              </div>
              <div className="offline-machine__copy">
                {t("MachineOffline.offlineMessage")}
              </div>
            </div>
          </div>

          <RoomSummarySplash />
        </Col>
      </Row>
    </>
  );
};

export default OfflineMachine;
