import { MachinePricingDetail } from "../types/FrontEnd/MachinePricing";
import { TRANSACTION_FEE_PRICE_TYPE } from "../../components/machine/machine-price";

export const GetTransactionFeeFromPriceDetail = (
  details: MachinePricingDetail[] | undefined
): number => {
  return (
    details?.find(
      (priceDetail) => priceDetail.type === TRANSACTION_FEE_PRICE_TYPE
    )?.price ?? 0
  );
};
