export enum paymentMethods {
  bill = "bill",
  coin = "coin",
  creditcard = "credit-card",
  applePay = "apple-pay",
  googlePay = "google-pay",
  digitalWallet = "digital-wallet",
  storedValue = "stored-value",
  account = "account",
  check = "check",
  bonus = "bonus",
  none = "none",
  studentcard = "student-card",
}
