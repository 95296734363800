import { gql } from "@apollo/client";
import { Machine } from "../../types/ClientServices/Machines";

const GET_MACHINE_BY_QR = gql`
  query GetMachineByQR {
    machine(qr: $qr) @rest(type: "Post", path: "machine/qr/{args.qr}") {
      opaqueId
      type
      locationId
      roomId
      stickerNumber
      licensePlate
      nfcId
      qrCodeId
      doorClosed
      available
      notAvailableReason
      inService
      freePlay
      mode
      display
      timeRemaining
      settings
      capability
      groupId
      stack
      stackItems
    }
  }
`;

export default GET_MACHINE_BY_QR;

export interface GetMachineByQR {
  machine: Machine;
}
