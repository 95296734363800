import { gql } from "@apollo/client";

export interface ProblemCodeResponse extends Request {
  success: boolean;
  id: string;
  ts: string;
  name: string;
  data: string[]; // List of error codes
}

const GET_PROBLEM_CODES = gql`
  query GetProblemCodes {
    problemCodes(licensePlate: $licensePlate)
      @rest(
        type: "Post"
        path: "queries/getAvailableProblemCodesByLicensePlate?licensePlate={args.licensePlate}"
      ) {
      success
      id
      ts
      name
      data
    }
  }
`;

export default GET_PROBLEM_CODES;

export interface ProblemCodesResponseGraphql {
  problemCodes: ProblemCodeResponse;
}
