import React, { createContext, Dispatch, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { LaundromatLocation } from "../../lib/types/FrontEnd/LaundromatLocation";
import { Room } from "../../lib/types/FrontEnd/Room";
import MyLaundryManager, {
  TrackedUserMachine,
} from "../../lib/localStorage/MyLaundryManager";
import GET_DATA, {
  DataEndpointResponseGraphql,
} from "../../lib/graphql/queries/GetData";
import { useParams } from "react-router-dom";
import { Machine } from "../../lib/types/ClientServices/Machines";
import { Reducer } from "./Reducer";
import { RoomSummaryMachinesStatus } from "../../lib/types/FrontEnd/RoomSummary";
import { Observable, Subscription } from "rxjs";
import GET_MACHINE, {
  MachineEndpointResponseGraphql,
} from "../../lib/graphql/queries/GetMachine";
import logger from "../../lib/logger";
import { ApolloQueryResult } from "@apollo/client/core/types";
import Bugsnag from "@bugsnag/js";
import { SSEEvent, SSEEventType } from "./SSEEvent";
import { SetMachineAction, UIAction } from "./UIAction";
import { FlashMessageProps } from "../alerts/StickyMessage";
import { RoomSummaryResponse } from "../../lib/graphql/queries/GetRoomSummary";

// Top level (global) react state definition
export interface UIState {
  flashMessage?: FlashMessageProps;
  initialized: boolean;
  error: boolean;
  errorDetails?: { [id: string]: any };
  location?: LaundromatLocation;
  room?: Room;
  machines?: Machine[];
  sourceLicensePlate?: string;
  stickerNumber: string;
  selectedMachine?: Machine;
  availabilitySummary?: RoomSummaryMachinesStatus;
  guid?: string;
  getMachine: (licensePlate: string) => Machine | null;
  getSourceMachine: (guid?: string) => Machine | undefined;
  getMachineByGuid: (guid: string) => Machine | undefined;
  findMachineByStickerNumber?: (stickerNumber: string) => Machine | null;
  isSSESupported: boolean;
  roomSummary: RoomSummaryResponse | undefined;
}

// Initial state implementation
export const initialState: UIState = {
  selectedMachine: undefined,
  initialized: false,
  error: false,
  stickerNumber: "0",
  getSourceMachine: () => undefined,
  getMachineByGuid: () => undefined,
  getMachine: () => null,
  isSSESupported: true,
  roomSummary: undefined,
};

export interface SetInitPropsType extends DataEndpointResponseGraphql {
  guid: string;
}

interface UIContextType {
  state: UIState;
  dispatch: Dispatch<UIAction>;
}

const UIContext = createContext<UIContextType>({
  state: initialState,
  dispatch: () => null,
});

const UIContextProvider = (props: {
  eventUrl?: string;
  id?: string;
  children: React.ReactNode;
}): JSX.Element => {
  const [state, dispatch] = React.useReducer(Reducer, initialState); // because reducer is always synchronous we can't do the initial state from there
  const client = useApolloClient();
  const [triggerSSERefresh, setTriggerSSERefresh] = React.useState(false);
  const [numberOfTimesWeReconnected, setNumberOfTimesWeReconnected] =
    React.useState(0);

  // logger.debug(`Event URL from SSR page props: ${props.eventUrl}`);
  const eventStreamBaseUrl = props.eventUrl;

  // const updater = React.useRef<UpdaterType | null>(null); // allows us to call UpdateMachine with access to state
  let { guid } = useParams();
  if (!guid && props.id) {
    guid = props.id;
  }

  /**
   * So this isn't the most clear pattern, but essentially we store the user's machine state in local storage.
   * For machines the user actually started (and will show up in my-laundry page), we pull them from the `getUsersActiveMachines` localStorage helper function.
   * Any SSE that comes in related to those machines we'll add to the array of license plates we request from the server.
   *
   * For machines that haven't started, we check to see
   *    IF there's a guid in the url route
   *    AND a licensePlate stored in localStorage... (`getMachineInPurchaseFlow` localStorage helper)
   *    THEN add the license plate to the "currentMachines" array (remember this is just a reference to something we just pulled from local storage, so mutating it here doesn't effect anything else)
   *    If the SSE event pertains to.
   *
   * Notes:
   *    THIS DOESN'T HAVE ACCESS TO REACT STATE
   *    The only way to mutate the state is via dispatch
   *    The only way to read data is from localStorage
   *    Only "stage-changed" events with license plates are monitored
   *
   * @param data
   */
  const processServerSideEvent = (data: SSEEvent) => {
    logger.trace("SSE", data);
    if (data.event === SSEEventType.stateChanged && data.licensePlate) {
      logger.debug("SSE - stateChanged %o", data);
      const trackedUserMachines: TrackedUserMachine[] =
        MyLaundryManager.getUsersActiveMachines();

      // No non-idle machines saved in local storage
      if (guid) {
        const checkoutMachineLP = MyLaundryManager.getMachineInPurchaseFlow();
        if (checkoutMachineLP) {
          trackedUserMachines.push({
            licensePlate: checkoutMachineLP,
            serverDateReceived: new Date(0), // We're not saving this to local storage, just to the array of license plates we want to watch SSE for
          });
        }
      }

      logger.trace("currentMachines we will update: %o", trackedUserMachines);

      // If the event we just got is something we are tracking:
      if (
        trackedUserMachines.find((m) => m.licensePlate === data.licensePlate)
      ) {
        client
          .query<MachineEndpointResponseGraphql>({
            query: GET_MACHINE,
            variables: { licensePlates: data.licensePlate },
            fetchPolicy: "network-only",
          })
          .then((response) => {
            dispatch({
              type: "set-machine",
              payload: {
                machine: response.data.machine,
                isSSESupported: true,
              } as SetMachineAction,
            });
          })
          .catch((err) => {
            logger.warn("ClientServices GET machine/ error:");
            logger.error(err);
            throw new Error("Unable to update machine!");
          });
      }
    }
  };

  /**
   *
   */
  useEffect(() => {
    let SSE: EventSource;
    let observable: Observable<any>;
    let subscription: Subscription;
    logger.trace("eventStreamBaseUrl: %s", eventStreamBaseUrl);
    // EventSource will be undefined if the browser doesn't support SSE
    if (eventStreamBaseUrl && typeof EventSource !== undefined) {
      observable = new Observable((observer) => {
        SSE = new EventSource(eventStreamBaseUrl);

        SSE.onmessage = (e) => {
          processServerSideEvent(JSON.parse(e.data));
        };

        SSE.onerror = (err) => {
          logger.warn("SSE error:");
          logger.error(err);
          SSE.close();
          observer.error(err);
          dispatch({
            type: "set-sse-status",
            payload: { isSSESupported: false },
          });
          setTimeout(() => {
            setTriggerSSERefresh(!triggerSSERefresh);
            // The aim of this is to backoff how long it takes us to try to reconnect to the SSE server when we get an error: https://en.wikipedia.org/wiki/Additive_increase/multiplicative_decrease
            setNumberOfTimesWeReconnected(numberOfTimesWeReconnected + 1);
            logger.trace(
              "number of times we reconnected: %s",
              numberOfTimesWeReconnected
            );
          }, (numberOfTimesWeReconnected + 1) * 1000 + 500);
        };

        return () => {
          SSE.close();
        };
      });

      subscription = observable.subscribe();
    } else {
      logger.warn("SSE is not supported, or no location is set!");
      dispatch({
        type: "set-sse-status",
        payload: { isSSESupported: false },
      });
    }

    return () => {
      if (SSE) {
        SSE.close();
      }
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [triggerSSERefresh]);

  /**
   * This effect initializes the top-level state
   */
  useEffect(() => {
    if (state.initialized) {
      // Add the top-level state to bugsnag
      Bugsnag.clearMetadata("state");
      Bugsnag.addMetadata("state", { state });
      return;
    }

    // If we're in a route that contains the machine guid, it's been passed in as a prop to the context, pull fresh data from server
    if (guid) {
      client
        .query<DataEndpointResponseGraphql>({
          query: GET_DATA,
          variables: { guid: guid },
          fetchPolicy: "network-only",
        })
        .then(onGetDataResponse)
        .catch(onGetDataError);
    } else {
      // Otherwise no one gave us a guid/opaqueId to use to pull the global state, luckily we store some info in localStorage
      const selectedMachines = MyLaundryManager.getRoomMachines();
      // If the user has saved machines, let's use those to init the state

      if (selectedMachines?.length) {
        const guid = selectedMachines[0].opaqueId;
        client
          .query<DataEndpointResponseGraphql>({
            query: GET_DATA,
            variables: { guid: guid },
            fetchPolicy: "network-only",
          })
          .then(onGetDataResponse)
          .catch(onGetDataError);
      }
    }
  }, [state.initialized]);

  const onGetDataResponse = (
    d: ApolloQueryResult<DataEndpointResponseGraphql>
  ) => {
    dispatch({
      type: "set-init-props",
      payload: { ...d.data, guid: guid } as SetInitPropsType,
    });
  };

  const onGetDataError = (err: Error) => {
    logger.warn("ClientServices GET /machine/info/ error");
    logger.error(err);
    throw new Error(err.message);
  };

  return (
    <UIContext.Provider value={{ state, dispatch }}>
      {props.children}
    </UIContext.Provider>
  );
};

export { UIContext, UIContextProvider, initialState as UIContextInitialState };
