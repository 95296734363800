import React, { SetStateAction } from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

/**
 * Very simple higher order component for react-bootstrap "Alert" component, providing simple tracking and copy options.
 * Note this component is actively controlled by the parent, and requires a setState function to be passed in from the parent to handle closing the modal.
 * @param props
 * @constructor
 */
const APIRequestFailAlert = (props: {
  setShowAlert: React.Dispatch<SetStateAction<boolean>>;
  cssModifier?: string;
  title?: string;
  copy?: string;
}): JSX.Element => {
  const { t } = useTranslation(); // use translation hook to access translator function

  return (
    <Alert
      className={`alert--${props.cssModifier}`}
      variant={"danger"}
      dismissible
      onClose={() => {
        props.setShowAlert(false);
      }}
    >
      <Alert.Heading data-testid="alertTitle">
        {t("ApiRequestFailedAlert.alertTitleDefaultCopy") || props.title}
      </Alert.Heading>
      <p data-testid="alertText">
        {t("ApiRequestFailedAlert.alertErrorDefaultCopy") || props.copy}
      </p>
    </Alert>
  );
};

export const alertErrorDefaultCopy =
  "Please check your network connection and refresh the page";
export const alertTitleDefaultCopy = "Cannot connect to server";
export const alertCssModifier = "sticky";

APIRequestFailAlert.defaultProps = {
  cssModifier: alertCssModifier,
  title: alertTitleDefaultCopy,
  copy: alertErrorDefaultCopy,
};

export default APIRequestFailAlert;
