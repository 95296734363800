import React, { PropsWithChildren } from "react";

interface CustomSelectProps {}

/**
 * Simple wrapper for form select
 * @param props
 * @constructor
 */
export const Select = (
  props: PropsWithChildren<CustomSelectProps> &
    React.SelectHTMLAttributes<CustomSelectProps>
) => {
  return <select {...props}>{props.children}</select>;
};

export default Select;
