import React from "react";
import useFeatureFlags, {
  FeatureFlagValues,
} from "../../../lib/flags/useFeatureFlags";
import Accordion from "react-bootstrap/Accordion";
import { Card, ListGroup } from "react-bootstrap";
import {
  LaundryRoute,
  RequestRefundRoute,
  RequestServiceRoute,
} from "../../../routes/routes";
import EmailUsLink from "../../../constants/EmailUsLink";
import HelpExpandedMenuItem from "./HelpExpandedMenuItem";
import NavRight from "../../../../assets/nav-right.svg";
import MyLaundryIcon from "../../../../assets/MyLaundryIcon.svg";
import RequestServiceIcon from "../../../../assets/RequestServiceIcon.svg";
import RequestRefund from "../../../../assets/RequestRefund.svg";
import RoomSummaryIcon from "../../../../assets/RoomSummaryIcon.svg";

import accordionCollapse from "../../../../assets/help/accordion-collapse.svg";
import accordionExpand from "../../../../assets/help/accordion-expand.svg";
import FAQIcon from "../../../../assets/help/help-FAQ.svg";
import helpIcon from "../../../../assets/help/help-MSG.svg";

import CloseIcon from "../../../../assets/nav/close.svg";
import MainHeaderIcon from "../../../../assets/CSCLogo_mainheader.svg";
import { useNavigate } from "react-router-dom";
import { OnRequestRefundPageViewEventPayload } from "../../tracking/SegmentEvents";
import TrackWrap from "../../tracking/TrackWrap";
import { useTranslation } from "react-i18next";

interface SideMenuProps {
  expanded: boolean;
  setExpanded: (state: boolean) => void;
  guid?: string;
  flags: any;
}

interface SideMenuItemProps {
  img: string;
  href: string;
  label: string;
  onClick?: () => void;
  afterNavigate?: () => void;
}

const SideMenuItem: React.FC<SideMenuItemProps> = (
  props: SideMenuItemProps
) => {
  const { img, href, label, onClick } = props;
  const navigate = useNavigate();
  return (
    <ListGroup.Item
      action
      onClick={() => {
        onClick ? onClick() : "";
        if (href) {
          if (href.indexOf("https://") === 0 || href.indexOf("http://") === 0) {
            window.location.replace(href);
          } else {
            navigate(href);
            if (props?.afterNavigate) {
              props.afterNavigate();
            }
          }
        }
      }}
      className="bg-light pt-4 pb-3"
    >
      <img src={img} className="pr-2" />
      <span className={"sideMenuItem"}>{label}</span>
      <img src={NavRight} className="float-right mt-1" />
    </ListGroup.Item>
  );
};

const SideMenu: React.FC<SideMenuProps> = (props: SideMenuProps) => {
  const { expanded, setExpanded } = props;
  const flags = useFeatureFlags();
  const [needHelpExpaned, setNeedHelpExpaned] = React.useState(false);
  let screenHeight = 812;

  React.useEffect(() => {
    if (expanded) {
      if (window.innerHeight) {
        screenHeight = window.innerHeight;
      } else if (document.body && document.body.clientHeight) {
        screenHeight = document.body.clientHeight;
      }

      if (document.documentElement && document.documentElement.clientHeight) {
        screenHeight = document.documentElement.clientHeight;
      }

      const sideMenuNode = document.getElementById("side_menu");
      if (sideMenuNode) {
        // let scroll-bar of side menu can show and be scrolled by user
        sideMenuNode.style.height = screenHeight + "px";
      }
    }
  }, [expanded]);

  const getRefundRequestURL = (flags: FeatureFlagValues): string => {
    if (flags.allowRefundRequests) {
      // The menu isn't always loaded in the react context, which means we can't use dispatch or access the react state, let's send the analytics event directly here
      return `/${RequestRefundRoute}/`;
    }

    return `https://www.cscsw.com/refund-request/`;
  };

  const getRequestServiceURL = (flags: FeatureFlagValues): string => {
    if (flags.allowRequestService) {
      return `/${RequestServiceRoute}/`;
    }

    return "https://www.cscsw.com/request-service/";
  };

  //@ts-ignore
  const needHelpAccordionSelected = (e) => {
    setNeedHelpExpaned(!!e);
  };

  const { t } = useTranslation();

  return (
    <div className={`bg-light sideMenu`} id="side_menu">
      <div className={"sideMenuHeader"}>
        <img
          src={CloseIcon}
          width="18px"
          height="18px"
          className={"sideMenuHeaderClose"}
          onClick={() => {
            setExpanded(false);
          }}
        />
        <img
          src={MainHeaderIcon}
          width="168px"
          height="45px"
          className={"sideMenuHeaderLogo"}
        />
      </div>

      <ListGroup variant="flush">
        <SideMenuItem
          img={RoomSummaryIcon}
          href={`/${LaundryRoute}/summary/`}
          label={t("SideMenu.roomSummary")}
          afterNavigate={() => {
            setExpanded(false);
          }}
        />
        <SideMenuItem
          img={MyLaundryIcon}
          href={`/${LaundryRoute}/my-laundry`}
          label={t("SideMenu.myLaundry")}
          afterNavigate={() => {
            setExpanded(false);
          }}
        />
        <SideMenuItem
          img={RequestServiceIcon}
          href={getRequestServiceURL(flags)}
          label={t("SideMenu.requestService")}
          afterNavigate={() => {
            setExpanded(false);
          }}
        />
        <SideMenuItem
          img={RequestRefund}
          href={getRefundRequestURL(flags)}
          label={t("SideMenu.requestRefund")}
          afterNavigate={() => {
            TrackWrap(OnRequestRefundPageViewEventPayload);
            setExpanded(false);
          }}
        />
      </ListGroup>

      <Accordion
        className={"sideMenu__help"}
        onSelect={needHelpAccordionSelected}
      >
        <div className="bg-light">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="1"
            className={`bg-light pt-4 pb-4 sideMenuHelpToggle`}
          >
            <div>
              <span className={"sideMenuHelpTitle"}>
                {t("SideMenu.needHelp.title")}
              </span>
              {needHelpExpaned && (
                <img src={accordionCollapse} className="float-right mt-1" />
              )}
              {!needHelpExpaned && (
                <img src={accordionExpand} className="float-right mt-1" />
              )}
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <HelpExpandedMenuItem
                img={FAQIcon}
                href="https://help.mycscgo.com/"
                label={t("SideMenu.needHelp.faq")}
                rootClassName={"sideMenuHelpItemFAQ"}
              />
              <HelpExpandedMenuItem
                img={helpIcon}
                href={EmailUsLink}
                label={t("SideMenu.needHelp.sendUsAMessage")}
                rootClassName={"sideMenuHelpItemSendMsg"}
              />
            </Card.Body>
          </Accordion.Collapse>
        </div>
      </Accordion>

      <div className={"sideMenuFooter"}>
        {t("SideMenu.copyright")} {new Date().getFullYear()}{" "}
        {t("SideMenu.CSCServiceWorks")}
      </div>
    </div>
  );
};

export default SideMenu;
