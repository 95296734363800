import React, { useState } from "react";
import EnterLicenseGeneric, {
  EnterLicenseGenericType,
} from "../enter-license/enter-license";
import { UIContext } from "../context/UIContext";
import { Room } from "../../lib/types/FrontEnd/Room";
import { Machine } from "../../lib/types/ClientServices/Machines";
import { LaundromatLocation } from "../../lib/types/FrontEnd/LaundromatLocation";
import GET_MACHINE, {
  MachineEndpointResponseGraphql,
} from "../../lib/graphql/queries/GetMachine";
import { useApolloClient } from "@apollo/client";
import GET_DATA, {
  DataEndpointResponseGraphql,
} from "../../lib/graphql/queries/GetData";
import logger from "../../lib/logger";
import {
  getOnEnterRefundAddMachineEventPayload,
  OnMachineNotFoundEventPayload,
} from "../tracking/SegmentEvents";

export interface EnterLicenseProps {
  machines?: Machine[];
  selectedMachine?: Machine;
  setSelectedMachine: (value: Machine) => void;
  room?: Room;
  setRoom: (value: Room | undefined) => void;
  location?: LaundromatLocation;
  setLocation: (value: LaundromatLocation | undefined) => void;
}

/**
 * Prompts the user to enter the license plate of the troubled machine
 * Note: this requires the parent component to pass in function to set machine state
 * @param props
 * @constructor
 */
const EnterLicenseForRefund = (props: EnterLicenseProps) => {
  const { state, dispatch } = React.useContext(UIContext);
  const [showMachineNotFound, setShowMachineNotFound] = useState(false);
  const client = useApolloClient();

  /**
   * Helper function to toggle the machine not found UI, associated segment event
   * @param show
   */
  const ToggleMachineNotFound = (show: boolean) => {
    setShowMachineNotFound(show);

    if (show) {
      dispatch({
        type: "log-event",
        payload: OnMachineNotFoundEventPayload(EnterLicenseGenericType.refund),
      });
    }
  };

  /**
   * Submit the license plate and store the machine to local state
   */
  const submit = (licensePlate: string) => {
    const machine = state.getMachine(licensePlate);

    if (machine) {
      // The machine was already in their local state, set room/location from current state as well
      props.setSelectedMachine(machine);
      props.setLocation(state.location);
      props.setRoom(state.room);
    } else {
      client
        .query<MachineEndpointResponseGraphql>({
          query: GET_MACHINE,
          variables: { licensePlates: licensePlate },
        })
        .then((machineResponse) => {
          const machine = machineResponse.data.machine;
          if (!machine?.opaqueId) {
            ToggleMachineNotFound(true);
          } else {
            client
              .query<DataEndpointResponseGraphql>({
                query: GET_DATA,
                variables: { guid: machine.opaqueId },
              })
              .then((res) => {
                props.setSelectedMachine(machine);
                props.setLocation(res.data.data.location);
                props.setRoom(res.data.data.room);
              });
          }
        })
        .catch((err) => {
          logger.error(err);
          ToggleMachineNotFound(true);
        });
    }
  };

  return (
    <EnterLicenseGeneric
      ToggleMachineNotFound={ToggleMachineNotFound}
      showMachineNotFound={showMachineNotFound}
      submit={submit}
      type={EnterLicenseGenericType.refund}
    />
  );
};

export default EnterLicenseForRefund;
