import React, { PropsWithChildren } from "react";

interface CustomLabelProps {}

/**
 * Simple wrapper for form label
 * @param props
 * @constructor
 */
export const Label = (
  props: PropsWithChildren<CustomLabelProps> &
    React.LabelHTMLAttributes<CustomLabelProps>
) => {
  return <label {...props}>{props.children}</label>;
};

export default Label;
