import React, { useContext } from "react";
import { Machine } from "../../lib/types/ClientServices/Machines";
import { MachinePricingDetail } from "../../lib/types/FrontEnd/MachinePricing";
import { MachineTypesClientServices } from "../../lib/types/FrontEnd/Machines";
import { GetTransactionFeeFromPriceDetail } from "../../lib/helpers/GetTransactionFeeFromPriceDetail";
import { UIContext } from "../context/UIContext";
import { useTranslation } from "react-i18next";
interface MachinePriceProps {
  displayAmount?: string;
  details: Array<MachinePricingDetail>;
  machine: Machine;
}

/**
 *
 * @param displayAmount - contains transaction fee charge
 * @param details
 * @param machine
 * @constructor
 */
const MachinePrice: React.FC<MachinePriceProps> = ({
  displayAmount,
  details,
  machine,
}) => {
  const elementsToSumForCost = details
    .filter((priceDetail) => priceDetail.type !== TRANSACTION_FEE_PRICE_TYPE)
    .map((fee) => fee.price);

  const baseCost =
    elementsToSumForCost.reduce((sum, x) => (sum ?? 0) + (x ?? 0), 0) ?? 0;
  const baseCostFormatted = `$${(baseCost / 100).toFixed(2)}`;
  const transactionFee = GetTransactionFeeFromPriceDetail(details);
  const { t } = useTranslation();

  const transactionFeeFormatted = `$${(transactionFee / 100).toFixed(2)}`;
  const getMachineTypeString =
    machine.type === MachineTypesClientServices.Washer
      ? t("MachinePrice.washerCycle")
      : t("MachinePrice.dryerCycle");

  const { state } = useContext(UIContext);

  const canUsersOnlyPayWithStudentCard =
    state.roomSummary?.school?.allowGuestStudentCard === true &&
    state.roomSummary?.school?.allowGuestCreditCard === false;

  return (
    <div className="machine-price">
      {!canUsersOnlyPayWithStudentCard && transactionFee > 0 && (
        <div className="machine-price__line-items">
          <div className="machine-price__line-item">
            <div className="machine-price__line-item__label">
              {`${getMachineTypeString} `}
            </div>
            <div className="machine-price__line-item__value">
              {baseCostFormatted}
            </div>
          </div>
          {!machine.freePlay && (
            <div className="machine-price__line-item">
              <div className="machine-price__line-item__label">
                {t("MachinePrice.convenienceFee")}
              </div>
              <div className="machine-price__line-item__value">
                {transactionFeeFormatted}
              </div>
            </div>
          )}
        </div>
      )}

      <div className="machine-price__total">
        <div className="machine-price__total__label">
          {t("MachinePrice.total")}
        </div>
        <div className="machine-price__total__value">
          {/* If they cannot pay with a credit card, we don't want to display the transaction fee */}
          {!canUsersOnlyPayWithStudentCard ? displayAmount : baseCostFormatted}
        </div>
      </div>

      {transactionFee > 0 && !machine.freePlay && (
        <div className="machine-price__avoid-fee">
          <a
            href={"https://cscgo.app.link/nJnQLKbQGlb"}
            data-testid="downloadPrompt"
          >
            {t("MachinePrice.downloadTheApp")}
          </a>
          {canUsersOnlyPayWithStudentCard && (
            <> {t("MachinePrice.toGetNotified")}</>
          )}
          {!canUsersOnlyPayWithStudentCard && (
            <div data-testid="convenienceFees">
              {t("MachinePrice.noConvenienceFees")}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const TRANSACTION_FEE_PRICE_TYPE = "TransactionFee";

export default MachinePrice;
