import React from "react";

interface HelpExpandedMenuItemProps {
  img: string;
  href: string;
  label: string;
  rootClassName: string;
}

const HelpExpandedMenuItem: React.FC<HelpExpandedMenuItemProps> = (
  props: HelpExpandedMenuItemProps
) => {
  const { img, href, label, rootClassName } = props;
  return (
    <div className={rootClassName}>
      <img src={img} className="pl-3 pr-2" />
      <a href={href} className={"sideMenuHelpLabel"}>
        {label}
      </a>
    </div>
  );
};

export default HelpExpandedMenuItem;
