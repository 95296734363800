import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { AppContainer } from "react-hot-loader";
import { Auth0Provider } from "@auth0/auth0-react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact, {
  BugsnagErrorBoundary,
} from "@bugsnag/plugin-react";
import "./lib/i18n/i18n.js";
Bugsnag.start({
  apiKey: (process.env.BUG_SNAG_API_KEY as string) ?? "N/A",
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ["production", "staging", "development", "local"],
  releaseStage: process.env.BUG_SNAG_RELEASE_STAGE,
});

const ErrorBoundary = Bugsnag?.getPlugin("react")?.createErrorBoundary(
  React
) as BugsnagErrorBoundary;

ReactDOM.render(
  <ErrorBoundary>
    <Auth0Provider
      domain={process.env.AUTH0_DOMAIN as string}
      clientId={process.env.AUTH0_CLIENT_ID as string}
      authorizationParams={{
        scope: "openid offline_access profile email",
      }}
    >
      <AppContainer>
        <App />
      </AppContainer>
    </Auth0Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);

//@ts-ignore
if (module.hot) {
  //@ts-ignore
  module.hot.accept();
}
