import React, { useEffect, useState } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MachineTypesClientServices } from "../../lib/types/FrontEnd/Machines";
import logger from "../../lib/logger";
import DryerTopOffMenuButton from "./machine-top-off-menu";
import { Machine, MachineMode } from "../../lib/types/ClientServices/Machines";

import state_washer_active from "/assets/state_washer_active.svg";
import state_dryer_active from "/assets/state_dryer_active.svg";
import MachineName from "./MachineName";
import { UserMachine } from "../../lib/localStorage/MyLaundryManager";
import SVGCloseIcon from "../../../assets/machine/close/CloseIcon.svg";
import { useTranslation } from "react-i18next";

interface MachineStateCardProps {
  machine: Machine;
  onClose?: (machine: UserMachine) => void;
}

/**
 * Component that displays information about the machine that the user is tracking status of
 * @param machine
 * @param allowClose
 * @constructor
 */
const MachineStateCard: React.FC<MachineStateCardProps> = ({
  machine,
  onClose,
}) => {
  const [endOfCycleOverride, setEndOfCycleOverride] = useState(false);

  useEffect(() => {
    logger.debug(machine);
    if (
      machine.type === MachineTypesClientServices.Dryer &&
      machine.timeRemaining === 0
    ) {
      setEndOfCycleOverride(true);
    } else if (
      machine.type === MachineTypesClientServices.Washer &&
      machine.mode === MachineMode.idle &&
      machine.timeRemaining === 0
    ) {
      setEndOfCycleOverride(true);
    } else {
      setEndOfCycleOverride(false);
    }
  }, [machine]);

  const isActiveDryer = (machine: Machine) => {
    if (endOfCycleOverride) {
      return false;
    }

    return (
      machine.type === MachineTypesClientServices.Dryer &&
      machine.mode === MachineMode.running
    );
  };

  const machineStateImage = (machine: Machine): string => {
    if (!machine) {
      return state_washer_active;
    }

    switch (machine.type) {
      case MachineTypesClientServices.Washer:
        return state_washer_active;
      case MachineTypesClientServices.Dryer:
      default:
        return state_dryer_active;
    }
  };

  const machineStateText = (machine: Machine) => {
    if (!machine) {
      return t("MachineStateCard.available");
    }

    if (endOfCycleOverride) {
      return t("MachineStateCard.endOfCycle");
    }

    switch (machine.mode) {
      case MachineMode.idle:
        return t("MachineStateCard.paused");
      case MachineMode.pressStart:
        return t("MachineStateCard.youWillHave");
      case MachineMode.running:
        switch (machine.type) {
          case MachineTypesClientServices.Washer:
            return t("MachineStateCard.running");
          case MachineTypesClientServices.Dryer:
          default:
            return t("MachineStateCard.dryerActive");
        }
      case MachineMode.endOfCycle:
        return t("MachineStateCard.endOfCycle");
    }
  };
  const { t } = useTranslation();

  const centerView = (machine: Machine) => {
    if (!machine) {
      return <h3 className="pt-3">{t("MachineStateCard.unable")}</h3>;
    }

    if (machine.mode === MachineMode.endOfCycle || endOfCycleOverride) {
      return (
        <>
          <h1>{t("MachineStateCard.done")}</h1>
          <img src={machineStateImage(machine)} />
        </>
      );
    }

    if (
      machine.mode === MachineMode.running ||
      machine.mode === MachineMode.idle
    ) {
      return (
        <>
          <div className="time">
            <span className="value">{machine.timeRemaining}</span>
            <span className="unit">min*</span>
          </div>
          <img src={machineStateImage(machine)} />
        </>
      );
    }

    if (machine.mode === MachineMode.pressStart) {
      return <h1 className="pt-3">{t("MachineStateCard.pressStart")}</h1>;
    }
  };

  const removeMachine = () => {
    if (onClose) {
      onClose({
        licensePlate: machine.licensePlate,
        serverDateReceived: new Date(),
        machine: machine,
      });
    }
  };
  return (
    <Row className="machine-state-row">
      <Col className="col-md-6 offset-md-3">
        <div className="machine-state m-auto text-center">
          {onClose && (
            <img
              src={SVGCloseIcon}
              onClick={removeMachine}
              className={"machine-state__close"}
            />
          )}
          <MachineName machine={machine} />

          {centerView(machine)}

          {isActiveDryer(machine) && machine?.capability?.addTime ? (
            <DryerTopOffMenuButton machine={machine} />
          ) : (
            <p className="pt-4 state">{machineStateText(machine)}</p>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default MachineStateCard;
