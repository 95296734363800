import React from "react";
import timeAdded from "/assets/img/time-added.svg";
import successCheck from "/assets/img/green-success-check.svg";
import invalidPayment from "/assets/img/invalid-payment-icon.svg";
import logger from "../../lib/logger";

export interface StickyMessageProps {
  text?: string;
  type?: StickyMessageType;
  position?: StickyMessagePosition;
}

export enum StickyMessageType {
  error = "error",
  paymentCompleteNotice = "paymentCompleteNotice",
  topOffCompleteNotice = "topOffCompleteNotice",
}

export enum StickyMessagePosition {
  sticky = "sticky",
  fixed = "fixed",
}

export const FlashMessageLocalStorageName = "flashMessage";

export interface FlashMessageProps {
  type?: StickyMessageType;
  text?: string;
  pathToShow?: string;
}

export const setFlashMessage = (
  message: string,
  type: StickyMessageType = StickyMessageType.topOffCompleteNotice
): void => {
  const messageToFlashOnNextPage: FlashMessageProps = {
    type: type,
    text: message,
  };
  localStorage.setItem(
    FlashMessageLocalStorageName,
    JSON.stringify(messageToFlashOnNextPage)
  );
};

/**
 * Simple UI element used to provide feedback to users.
 * @param props
 * @constructor
 */
export const StickyMessage = (props: StickyMessageProps) => {
  logger.trace("stickyMessageProps: %s", JSON.stringify(props));
  const mapTypeToIcon = () => {
    if (props.type === StickyMessageType.paymentCompleteNotice) {
      return successCheck;
    }
    if (props.type === StickyMessageType.error) {
      return invalidPayment;
    }
    if (props.type === StickyMessageType.topOffCompleteNotice) {
      return timeAdded;
    }
  };

  return (
    <div
      className={`sticky-message sticky-message--${props.type} sticky-message--${props.position}`}
    >
      <div className="sticky-message__wrap">
        {props.type && (
          <div className="sticky-message__icon">
            <img src={mapTypeToIcon()} alt={props.text} />
          </div>
        )}
        <div className="sticky-message__text">{props.text}</div>
      </div>
    </div>
  );
};

StickyMessage.defaultProps = {
  position: StickyMessagePosition.sticky,
  type: StickyMessageType.error,
};
