import React, { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import LaundryRoomImage from "/assets/LaundryRoom.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { LaundryRoute } from "../routes/routes";
import AppLessLayout from "../components/layout/Layout";
import { useTranslation } from "react-i18next";
const Index: React.FC = () => {
  const location = useLocation();
  const nav = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (location.pathname === "/") {
      nav(LaundryRoute);
    }
  }, []);

  return (
    <AppLessLayout>
      <React.Fragment>
        <Row>
          <Col className="col-md-6 offset-md-3">
            <p className="text-center tag-line">{t("Index.getStarted")}</p>
          </Col>
        </Row>
        <Row className="laundry-room-row">
          <Col className="col-md-4 offset-md-4">
            <div className="laundry-room m-auto">
              <Image src={LaundryRoomImage} style={{ width: "100%" }} />
            </div>
          </Col>
        </Row>
        <Row className="pt-2 index-list">
          <Col className="col-md-6 offset-md-3 text-center m-3 justify-content-center p-auto">
            <ol>
              <li>
                <p className="pt-2">{t("Index.step1")}</p>
              </li>
              <li>
                <p>{t("Index.step2")}</p>
              </li>
              <li>
                <p className="pt-2">{t("Index.step3")}</p>
              </li>
            </ol>
          </Col>
        </Row>
      </React.Fragment>
    </AppLessLayout>
  );
};

export default Index;
