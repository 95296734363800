import React, { useContext } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { UIContext } from "../../context/UIContext";
import AppleDownloadImage from "/assets/AppleDownload.svg";
import GoogleDownloadIcon from "/assets/GoogleDownload.svg";
import {
  AppStoreFooterClickEventPayload,
  GoogleStoreFooterClickEventPayload,
} from "../../tracking/SegmentEvents";
import { useTranslation } from "react-i18next";
/**
 * Footer component
 * @constructor
 */

const MainFooter: React.FC = () => {
  const { dispatch } = useContext(UIContext);

  const onClickApple = () => {
    dispatch({
      type: "log-event",
      payload: AppStoreFooterClickEventPayload,
    });
  };
  const { t } = useTranslation();

  const onClickGoogle = () => {
    dispatch({
      type: "log-event",
      payload: GoogleStoreFooterClickEventPayload,
    });
  };

  return (
    <>
      <footer className="text-center footer">
        <Row className="pt-3 pb-4 px-2">
          <Col>
            <h6>{t("MainFooter.download")}</h6>
            <p className="px-4">{t("MainFooter.earn")}</p>
            <a
              href="https://apps.apple.com/us/app/csc-go/id1552572916"
              onClick={onClickApple}
            >
              <Image src={AppleDownloadImage} />
            </a>
            <br />
            <br />
            <a
              href="https://play.google.com/store/apps/details?id=com.cscsw.cscgo"
              onClick={onClickGoogle}
            >
              <Image src={GoogleDownloadIcon} />
            </a>
          </Col>
        </Row>

        <Row className="py-2 copy">
          <Col>
            <div className="mt-1 float-left">
              © {new Date().getFullYear()} {t("MainFooter.CSCServiceWorks")}
            </div>
            <div className="mt-1 float-right">
              <a href="https://www.cscsw.com/terms-conditions/">
                {t("MainFooter.termsOfService")}
              </a>{" "}
              &nbsp; | &nbsp;
              <a href="https://www.cscsw.com/privacy-policy/">
                {t("MainFooter.privacyPolicy")}
              </a>
            </div>
          </Col>
        </Row>
      </footer>
    </>
  );
};

export default MainFooter;
