import Input from "../forms/input";
import Select from "../forms/select";
import React from "react";
import States from "../../lib/types/FrontEnd/States";
import { useTranslation } from "react-i18next";
import {
  handleOnInput,
  handleOnInvalid,
} from "../../lib/helpers/inputHandlers";

interface AddressInfoFormFieldProps {
  Zip: string;
  OnZipChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  State: string;
  onStateChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  City: string;
  onCityChange: (e: React.ChangeEvent<HTMLElement>) => void;
  StreetAddress: string;
  onStreetAddressChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  StreetAddress2: string;
  onStreetAddressChange2: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * Refund form fields partial - address info
 * @param props
 * @constructor
 */
const AddressInfoFormFields = (props: AddressInfoFormFieldProps) => {
  const { t } = useTranslation();
  return (
    <div className="contact-info__input-label">
      <span className="contact-info__input-label-text">
        {t("AddressInfoForm.mailingInfo")}
      </span>
      <Input
        onChange={props.onStreetAddressChange}
        value={props.StreetAddress}
        required
        type="text"
        onInvalid={(e: React.FormEvent<HTMLInputElement>) =>
          handleOnInvalid(e, t("AddressInfoForm.streetFailed"))
        }
        onInput={(e) => {
          handleOnInput(e as any);
        }}
        autoComplete={"address-line1"}
        placeholder={t("AddressInfoForm.street") || "Street"}
        className="contact-info__input"
      />

      <Input
        onChange={props.onStreetAddressChange2}
        value={props.StreetAddress2}
        type="text"
        autoComplete={`address-line2`}
        placeholder={t("AddressInfoForm.apt") || "Unit/Apt/Suite"}
        className="contact-info__input"
      />

      <Input
        onChange={props.onCityChange}
        value={props.City}
        required
        autoComplete={"address-level2"}
        type="text"
        onInvalid={(e: React.FormEvent<HTMLInputElement>) =>
          handleOnInvalid(e, t("AddressInfoForm.cityFailed"))
        }
        onInput={(e) => {
          handleOnInput(e as any);
        }}
        data-testid="cityField"
        placeholder={t("AddressInfoForm.city") || "City"}
        className="contact-info__input"
      />

      <div className="contact-info__horizontal-wrap">
        <span className="contact-info__select-wrap contact-info__select-wrap--state">
          <Select
            onChange={props.onStateChange}
            value={props.State}
            required
            autoComplete={"address-level1"}
            data-testid="stateField"
            placeholder={t("AddressInfoForm.state") || "State"}
            className="contact-info__input--select contact-info__input--select"
            onInvalid={(e: React.FormEvent<HTMLInputElement>) =>
              handleOnInvalid(e, t("AddressInfoForm.stateFailed"))
            }
            onInput={(e) => {
              handleOnInput(e as any);
            }}
          >
            <option value={""}>{t("AddressInfoForm.state")}</option>
            {Object.keys(States).map((index) => (
              <option key={index} value={index}>
                {States[index]}
              </option>
            ))}
          </Select>
        </span>

        <Input
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.OnZipChange(e);
            handleOnInvalid(e, t("AddressInfoForm.zipFailed"));
          }}
          value={props.Zip}
          required
          onInvalid={(e: React.FormEvent<HTMLInputElement>) =>
            handleOnInvalid(e, t("AddressInfoForm.zipFailed"))
          }
          onInput={(e) => {
            handleOnInput(e as any);
          }}
          autoComplete={"postal-code"}
          type="text"
          data-testid="zipcodeField"
          placeholder={t("AddressInfoForm.zip") || "Zip"}
          className="contact-info__input contact-info__input--zip"
          pattern="[0-9]{5}"
          title={t("AddressInfoForm.zipTitle") || "5 Digit Zip Code"}
        />
      </div>
    </div>
  );
};

export default AddressInfoFormFields;
