import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MachineTypesClientServices } from "../../lib/types/FrontEnd/Machines";
import { useTranslation } from "react-i18next";

interface MachineStatusProps {
  text?: string;
  type?: MachineTypesClientServices;
}

/**
 * Simple component that displays machine ready view.
 * @param text
 * @param type
 * @constructor
 */
const MachineStatus: React.FC<MachineStatusProps> = ({
  text,
  type,
}: MachineStatusProps) => {
  const { t } = useTranslation();
  const defaultText = (text?: string, type?: MachineTypesClientServices) => {
    if (type != undefined) {
      if (type === MachineTypesClientServices.Dryer) {
        return t("MachineStatus.readyToDry");
      } else if (type == MachineTypesClientServices.Washer) {
        return t("MachineStatus.readyToWash");
      }
    }

    return text != undefined ? text : t("MachineStatus.myLaundry");
  };

  return (
    <Row className="text-center screen-title-row">
      <Col className="col-md-6 offset-md-3">
        <p>{defaultText(text, type)}</p>
      </Col>
    </Row>
  );
};

export default MachineStatus;
