import { gql } from "@apollo/client";
import { RoomSummaryMachinesStatus } from "../../types/FrontEnd/RoomSummary";
import { Machine } from "../../types/ClientServices/Machines";

const GET_ROOM_MACHINES = gql`
  query GetRoomMachines {
    data(locationId: $locationId, roomId: $roomId)
      @rest(
        type: "Post"
        path: "location/{args.locationId}/room/{args.roomId}/machines"
      ) {
      opaqueId
      type
      locationId
      roomId
      stickerNumber
      licensePlate
      nfcId
      qrCodeId
      doorClosed
      available
      notAvailableReason
      inService
      freePlay
      mode
      display
      timeRemaining
      settings
      capability
      groupId
      stack
      stackItems
    }
  }
`;

export default GET_ROOM_MACHINES;

export type RoomMachinesResponse = Machine[];

export interface RoomMachinesEndpointResponseGraphql {
  data: RoomMachinesResponse;
}
