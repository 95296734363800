export enum MachineTypes {
  Washer = "Washer",
  Dryer = "Dryer",
}

export enum MachineTypesClientServices {
  Washer = "washer",
  Dryer = "dryer",
}

export enum MachineNavigationMethod {
  nfc = "NFC",
  qr = "QR",
  manual = "Manual",
}
