import MachinePrice from "./machine-price";
import ContinueButton from "../buttons/FreePlayButton/FreePlayButton";
import React from "react";
import { Machine } from "../../lib/types/ClientServices/Machines";
import { getDisplayAmount } from "../../lib/helpers/getDisplayAmount";
import LocationTitle from "../location-title";
import { UIContext } from "../context/UIContext";
import MachineDisplay from "./MachineDisplay";

interface FreePlayConfirmationProps {
  machine: Machine;
  onClick: () => void;
}

/**
 * This partial is shown on the press-start page to introduce a confirmation step before displaying press-start
 * there's no "idle" machine mode state for free machines, so we're emulating one by always showing this partial for freeplay machines in press-start
 * Don't show this partial unless you're sure the machine is free!
 *
 * @param machine
 * @param onClick
 * @constructor
 */
const FreePlayConfirmation = ({
  machine,
  onClick,
}: FreePlayConfirmationProps) => {
  const { state } = React.useContext(UIContext);

  return (
    <>
      <LocationTitle location={state.location} />
      <MachineDisplay machine={machine} />
      <MachinePrice
        machine={machine}
        displayAmount={getDisplayAmount(true, 0)}
        details={[]}
      />
      <ContinueButton onClick={onClick} />
    </>
  );
};

export default FreePlayConfirmation;
