import { gql } from "@apollo/client";

export interface ApplePayPaymentSessionVars {
  body: any;
}

export interface ApplePayPaymentSessionPayload {}

export interface ApplePayPaymentSessionProps {
  epochTimestamp: string;
  expiresAt: string;
  merchantSessionIdentifier: string;
  nonce: string;
  merchantIdentifier: string;
  domainName: string;
  displayName: string;
  signature: string;
  operationalAnalyticsIdentifier: string;
  retries: string;
}

export interface ApplePayPaymentSessionData {
  data: ApplePayPaymentSessionProps;
}

const APPLE_PAYMENT_SESSION = gql`
  mutation ApplePaymentSession {
    data(body: $body)
      @rest(
        type: "Post"
        path: "payments/adyen/applePaymentSession"
        method: "POST"
        bodyKey: "body"
      ) {
      epochTimestamp
      expiresAt
      merchantSessionIdentifier
      nonce
      merchantIdentifier
      domainName
      displayName
      signature
      operationalAnalyticsIdentifier
      retries
    }
  }
`;

export default APPLE_PAYMENT_SESSION;
