import React, { useContext, useEffect, useState } from "react";
import { Machine } from "../../lib/types/ClientServices/Machines";
import ADYEN_PAYMENT_DETAILS, {
  AdyenPaymentDetailsData,
  AdyenPaymentDetailsVars,
} from "../../lib/graphql/mutations/AdyenPaymentDetails";
import { paymentMethods } from "../../lib/types/FrontEnd/Payment";
import logger from "../../lib/logger";
import {
  FlashMessageLocalStorageName,
  FlashMessageProps,
  StickyMessage,
  StickyMessagePosition,
  StickyMessageType,
} from "../alerts/StickyMessage";
import { useApolloClient } from "@apollo/client";
import {
  ThreeDSAdditionalBlocksUrlParameter,
  ThreeDSDurationUrlParameter,
} from "../../routes/routes";
import Expire from "../alerts/Expire";
import { UIContext } from "../context/UIContext";
import { useTranslation } from "react-i18next";

export const AdyenThreeDSCallback = (props: { machine: Machine }) => {
  const machine = props.machine;
  const client = useApolloClient();
  const [hasAlreadySent3DSChallenge, setHasAlreadySent3DSChallenge] =
    useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const { dispatch } = useContext(UIContext);

  const additionalBlocksRaw = urlParams.get(
    ThreeDSAdditionalBlocksUrlParameter
  );
  const additionalBlocks = additionalBlocksRaw
    ? parseFloat(additionalBlocksRaw as string)
    : undefined;

  const additionalDuration = urlParams.get(ThreeDSDurationUrlParameter);
  const stickyMessageType = additionalDuration
    ? StickyMessageType.topOffCompleteNotice
    : StickyMessageType.paymentCompleteNotice;

  const { t } = useTranslation();

  const stickyMessageText = additionalDuration
    ? `${additionalDuration} ${t("StudentPaymentForm.minAdded")}`
    : `${t("StudentPaymentForm.paymentSuccess")}`;

  useEffect(() => {
    if (!machine) {
      return;
    }

    const MD = urlParams.get("MD") || undefined; // This is a url variable appended by the adyen landing page
    const PaRes = urlParams.get("PaRes") || undefined; // This too
    const redirectResult = urlParams.get("redirectResult") || undefined; // This too

    if (((MD && PaRes) || redirectResult) && !hasAlreadySent3DSChallenge) {
      client
        .mutate<AdyenPaymentDetailsData, AdyenPaymentDetailsVars>({
          mutation: ADYEN_PAYMENT_DETAILS,
          variables: {
            body: {
              detailsRequest: {
                details: {
                  PaRes: PaRes,
                  MD: MD,
                  redirectResult: redirectResult,
                },
              },
              metadata: {
                method: paymentMethods.creditcard,
                licensePlate: machine.licensePlate,
                roomId: machine.roomId,
                locationId: machine.locationId,
                additionalBlocks: additionalBlocks ?? undefined,
              },
            },
          },
        })
        .then((response) => {
          logger.trace(
            "Successful response back from adyen for 3ds authentication: %o",
            response
          );
          setHasAlreadySent3DSChallenge(true);

          const messageToFlashOnNextPage: FlashMessageProps = {
            type: stickyMessageType,
            text: stickyMessageText,
          };
          localStorage.setItem(
            FlashMessageLocalStorageName,
            JSON.stringify(messageToFlashOnNextPage)
          );
          // Not ideal solution, but top-ups have race condition where this component is handling these changes after being unmounted, to force the payment confirmation UI, let's refresh the page instead of showing the payment confirmation after the users next navigation action.
          if (additionalBlocks) {
            window.location.reload();
          }
        })
        .catch((error) => {
          dispatch({
            type: "set-alert-message",
            payload: {
              type: StickyMessageType.error,
              text: t("StudentPaymentForm.adyenPaymentFailed"),
            } as FlashMessageProps,
          });
          logger.warn("Failed POST to adyen payment details!");
          logger.debug("Error: %o", error);
        });
    }
  }, [machine]);
  return <></>;
};
