import { gql } from "@apollo/client";

import PaymentMethodsResponse from "@adyen/adyen-web/dist/types/core/ProcessResponse/PaymentMethodsResponse/PaymentMethodsResponse";

const GET_ADYEN_PAYMENT_METHODS = gql`
  query GetAdyenPaymentMethods {
    methods(blockedPaymentMethods: $blockedPaymentMethods)
      @rest(
        type: "GET"
        path: "payments/adyen/paymentMethods?channel=web&blockedPaymentMethods={args.blockedPaymentMethods}"
      ) {
      paymentMethods
      storedPaymentMethods
    }
  }
`;

export default GET_ADYEN_PAYMENT_METHODS;

export interface MachinePriceEndpointResponseGraphql {
  methods: PaymentMethodsResponse;
}
