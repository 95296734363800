import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import getLaundryRedirectUrlFromMachine from "../lib/helpers/GetLaundryRedirect";
import GET_MACHINE_BY_QR, {
  GetMachineByQR,
} from "../lib/graphql/queries/GetMachineByQR";
import { setLocalStorageFromAPIResponse } from "../lib/helpers/setLocalStorageFromAPIResponse";
import MyLaundryManager from "../lib/localStorage/MyLaundryManager";
import { MachineNavigationMethod } from "../lib/types/FrontEnd/Machines";

export const QR = () => {
  const navigate = useNavigate();
  const { qr } = useParams();

  useQuery<GetMachineByQR>(GET_MACHINE_BY_QR, {
    variables: { qr: qr },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setLocalStorageFromAPIResponse(data);
      MyLaundryManager.updateMachineIdentificationMethod(
        data.machine.licensePlate,
        MachineNavigationMethod.qr
      );
      navigate(getLaundryRedirectUrlFromMachine(data.machine), {
        replace: true,
      });
    },
    onError: (err) => {
      throw new Error(err.message);
    },
  });

  return <div />;
};
