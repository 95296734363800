import React, { useEffect } from "react";
import MyLaundryManager from "../lib/localStorage/MyLaundryManager";
import RoomSummary from "../components/summary/RoomSummary";
import { useNavigate } from "react-router-dom";
import AppLessLayout from "../components/layout/Layout";
import { UIContext, UIContextProvider } from "../components/context/UIContext";

interface RoomSummaryProps {}

const Summary: React.FC<RoomSummaryProps> = () => {
  const roomMachines = MyLaundryManager.getRoomMachines();
  const navigate = useNavigate();
  const guid = roomMachines[0]?.opaqueId;

  useEffect(() => {
    if (!guid) {
      navigate("/", { replace: true });
    }
  }, []);

  return (
    <UIContextProvider id={guid}>
      <AppLessLayout>
        <RoomSummary />
      </AppLessLayout>
    </UIContextProvider>
  );
};

export default Summary;
