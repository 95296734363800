export function handleOnInput(e: React.FormEvent<HTMLInputElement>): void {
  const target = e.target as HTMLInputElement;
  target.setCustomValidity("");
}

export function handleOnInvalid(
  e: React.FormEvent<HTMLInputElement>,
  s: string
): string {
  const validityState = e.currentTarget.validity;
  if (
    validityState.stepMismatch ||
    validityState.patternMismatch ||
    validityState.rangeOverflow ||
    validityState.rangeUnderflow ||
    validityState.typeMismatch ||
    validityState.valueMissing
  ) {
    const target = e.target as HTMLInputElement;
    target.setCustomValidity(s);
  }
  return s;
}
