import { gql } from "@apollo/client";
import { onAdyenPaymentSubmitError } from "../../types/FrontEnd/Adyen";

export interface AdyenPaymentVars {
  body: any;
}

//@todo split this into successful vs challenge response
export interface AdyenPaymentDataProps {
  statusCode?: string;
  message?: string;
  id?: string;
  name?: string;
  ts?: string;
  success: boolean;
  errors?: onAdyenPaymentSubmitError[];
  data?: {
    paymentResponse: {
      resultCode: "RedirectShopper" | "Authorised";
      additionalData?: any;
      pspReference?: string;
      amount?: {
        currency: string;
        value: number;
      };
      action: any; // value to be passed to drop-in on challenge
      paymentData?: string;
      redirect: {
        data: any;
        method: "POST";
        url: string;
      };
      merchantReference: string;
    };
  };
}

export interface AdyenPaymentData {
  data: AdyenPaymentDataProps;
  source: "webapp";
}

const ADYEN_PAYMENT = gql`
  mutation DigitalRefundRequest {
    data(body: $body)
      @rest(
        type: "Post"
        path: "commands/startMachineWithAdyenPayment"
        method: "POST"
        bodyKey: "body"
      ) {
      statusCode
      message
      id
      name
      ts
      success
      errors
      data
      source
    }
  }
`;

export default ADYEN_PAYMENT;
