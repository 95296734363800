import React from "react";
import { useTranslation } from "react-i18next";
import GenericModal, {
  buttonType,
  modalType,
  titleType,
} from "../request-service/generic-modal";

export interface AccountDeletionFailureModalProps {
  contactCSC: () => void;
  close: () => void;
  showModal: boolean;
}

export const AccountDeletionFailureModal = ({
  contactCSC,
  close,
  showModal,
}: AccountDeletionFailureModalProps) => {
  const { t } = useTranslation();
  return (
    <GenericModal
      title={t("AccountDeletion.failureTitle") ?? ""}
      titleType={titleType.error}
      copy={[t("AccountDeletion.failureMessage") ?? ""]}
      type={modalType.error}
      className="account-deletion__modal"
      buttons={[
        {
          text: t("AccountDeletion.contactUs"),
          type: buttonType.primary,
          onClick: () => {
            contactCSC();
          },
        },
        {
          text: t("AccountDeletion.close"),
          type: buttonType.secondary,
        },
      ]}
      showModal={showModal}
      closeModal={() => {
        close();
      }}
    />
  );
};
