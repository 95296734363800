import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import APIRequestFailAlert from "../alerts/APIRequestFailAlert";
import { UIContext } from "../context/UIContext";
import logger from "../../lib/logger";
import { Room } from "../../lib/types/FrontEnd/Room";
import { Machine } from "../../lib/types/ClientServices/Machines";
import { useApolloClient } from "@apollo/client";
import GET_PROBLEM_CODES, {
  ProblemCodesResponseGraphql,
} from "../../lib/graphql/queries/GetProblemCodes";
import SERVICE_REQUEST, {
  ServiceRequestResponseGQL,
} from "../../lib/graphql/mutations/ServiceRequest";
import MachineLocationSummary from "../MachineLocationSummary";
import { LaundromatLocation } from "../../lib/types/FrontEnd/LaundromatLocation";
import {
  getRequestServiceProblemCodeClick,
  getRequestServiceProblemCodeSelected,
  getRequestServiceProblemCodeSelectedEventPayload,
  getRequestServiceSubmitEventPayload,
  RequestServiceProblemCodesNotFoundEventPayload,
} from "../tracking/SegmentEvents";
import { useTranslation } from "react-i18next";
import {
  handleOnInput,
  handleOnInvalid,
} from "../../lib/helpers/inputHandlers";

interface SelectProblemProps {
  machine: Machine;
  location?: LaundromatLocation;
  room?: Room;
  submitted: boolean;
  setSubmitted: Dispatch<SetStateAction<boolean>>;
}

enum TypesOfProblems {
  "Blocked Coin Drop/Circuit" = "blockedCoinDropCircuit",
  "Burning Smell" = "burningSmell",
  "Check Run" = "checkRun",
  "Clothes Stuck" = "clothesStuck",
  "Coins Not Registering" = "coinsNotRegistering",
  "Credit Card Reader Not Working" = "creditCardReaderNotWorking",
  "Damaged Clothes" = "damagedClothes",
  "Debit Card Reader Comm Error" = "debitCardReaderCommError",
  "Debit Card Reader Not Communicating" = "debitCardReaderNotCommunicating",
  "Door Lock Error" = "doorLockError",
  "Door Open Error" = "doorOpenError",
  "Door Sense Failure" = "doorSenseFailure",
  "Door Will Not Close" = "doorWillNotClose",
  "Door Will Not Open" = "doorWillNotOpen",
  "Drain Error" = "drainError",
  "Drive Board Error" = "driveBoardError",
  "Fill Error" = "fillError",
  "Flooding/Overflow" = "floodingOverflow",
  "Full of Water" = "fullOfWater",
  "Leaking Oil" = "leakingOil",
  "Leaking Water" = "leakingWater",
  "Lndry Card Reader Not Working" = "lndryCardReaderNotWorking",
  "Low Voltage Error" = "lowVoltageError",
  "Machine Didn’t Report In" = "machineDidntReportIn",
  "Motor Sense Failure" = "motorSenseFailure",
  "No Cold Water" = "noColdWater",
  "No Hot Water" = "noHotWater",
  "No Water Flow Error" = "noWaterFlowError",
  "Noisy" = "noisy",
  "Not Filling" = "notFilling",
  "Not Rinsing Soap" = "notRinsingSoap",
  "Not Spinning" = "notSpinning",
  "Not Spinning Dry" = "notSpinningDry",
  "Odor" = "odor",
  "OTHER-See Notes" = "otherSeeNotes",
  "Out Of Order" = "outOfOrder",
  "Output Board Error" = "outputBoardError",
  "Overflow Error" = "overflowError",
  "PMT-Credit Card Not Reading" = "pmtCreditCardNotReading",
  "PMT-CSC Coin Drop Not Registering" = "pmtCscCoinDropNotRegistering",
  "PMT-Laundry Card Not Reading" = "pmtLaundryCardNotReading",
  "PMTCSC Pay App Not Reading" = "pmtCscPayAppNotReading",
  "POB Escalation" = "pobEscalation",
  "Pressure Switch Error" = "pressureSwitchError",
  "Problem Detected" = "problemDetected",
  "Reads Error" = "readsError",
  "Slide/Drop Jammed" = "slideDropJammed",
  "Stops at Mid-Cycle" = "stopsAtMidCycle",
  "Unbalance Error" = "unbalanceError",
  "Unbalanced/Shakes" = "unbalancedShakes",
  "Vandalism" = "vandalism",
  "VBR-Revenue Recovery" = "vbrRevenueRecovery",
  "Water Fills Slowly" = "waterFillsSlowly",
  "Will Not Start" = "willNotStart",
}

/**
 * The final form partial in the request service UI
 * @param props
 * @constructor
 */
const SelectProblem = (props: SelectProblemProps) => {
  const { dispatch } = React.useContext(UIContext);
  const [emailInputValue, setEmailInputValue] = useState("");
  const [selectInputValue, setSelectValue] = useState("");
  const [commentInputValue, setCommentInputValue] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [problemCodes, setProblemCodes] = useState<string[]>([]);
  const [showAlert, setShowAlert] = useState(false);
  const client = useApolloClient();

  useEffect(() => {
    client
      .query<ProblemCodesResponseGraphql>({
        query: GET_PROBLEM_CODES,
        variables: { licensePlate: props.machine.licensePlate },
      })
      .then((res) => {
        setProblemCodes(res.data.problemCodes.data);
      })
      .catch((err) => {
        // What do we do if the API didn't return any
        logger.error(err, `invalid response from GET_PROBLEM_CODES query`);
        setShowAlert(true);

        dispatch({
          type: "log-event",
          payload: RequestServiceProblemCodesNotFoundEventPayload,
        });
      });
  }, []);

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    client
      .mutate<ServiceRequestResponseGQL>({
        mutation: SERVICE_REQUEST,
        variables: {
          body: {
            licensePlate: props.machine.licensePlate,
            email: emailInputValue,
            problemCode: selectInputValue,
            comments: commentInputValue,
          },
        },
      })
      .then((result) => {
        if (result?.data?.data?.success) {
          onSubmit(true);
        } else {
          onSubmit(false);
          setShowAlert(true);
        }
      })
      .catch((err) => {
        onSubmit(false);
        logger.error(err);
        setShowAlert(true);
      });
  };

  const onSubmit = (success: boolean) => {
    props.setSubmitted(success);
    dispatch({
      type: "log-event",
      payload: getRequestServiceSubmitEventPayload(props.machine, success),
    });
  };

  const setEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInputValue(e.currentTarget.value);
  };

  const onClick = () => {
    dispatch({
      type: "log-event",
      payload: getRequestServiceProblemCodeClick,
    });
  };

  const setSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    getRequestServiceProblemCodeSelectedEventPayload(e.currentTarget.value);
    setSelectValue(e.currentTarget.value);
    dispatch({
      type: "log-event",
      payload: getRequestServiceProblemCodeSelected(e.currentTarget.value),
    });
  };

  const setComment = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCommentInputValue(e.currentTarget.value);
  };

  const { t } = useTranslation();

  return (
    <div className="select-problem">
      <MachineLocationSummary
        machine={props.machine}
        room={props.room}
        location={props.location}
      />
      {showAlert && <APIRequestFailAlert setShowAlert={setShowAlert} />}

      <form onSubmit={submit}>
        <div className="select-problem__input-wrap">
          <label className="select-problem__input-label">
            <span className="select-problem__input-label-text">
              {t("SelectProblem.problem")}
            </span>
            <div className="select-problem__select-wrap">
              <select
                onClick={onClick}
                onChange={setSelect}
                value={selectInputValue}
                required
                className="select-problem__input--select"
                onInvalid={(e) =>
                  handleOnInvalid(e as any, t("SelectProblem.problemFailed"))
                }
                onInput={(e) => {
                  handleOnInput(e as any);
                }}
              >
                <option value="">{t("SelectProblem.select")}</option>
                {problemCodes.map((reason: string, index) => (
                  <option key={index} value={reason}>
                    {t(
                      `SelectProblem.problemList.${
                        TypesOfProblems[reason as keyof typeof TypesOfProblems]
                      }`
                    )}
                  </option>
                ))}
              </select>
            </div>
          </label>

          <label className="select-problem__input-label">
            <span className="select-problem__input-label-text">
              {t("SelectProblem.email")}
            </span>
            <input
              onChange={setEmail}
              value={emailInputValue}
              required
              type="email"
              placeholder={t("SelectProblem.emailPlaceholder") || undefined}
              className="select-problem__input"
              onInvalid={(e) =>
                handleOnInvalid(e as any, t("SelectProblem.emailFailed"))
              }
              onInput={(e) => {
                handleOnInput(e as any);
              }}
            />
          </label>

          <label className="select-problem__input-label">
            <span className="select-problem__input-label-text">
              {t("SelectProblem.addComments")}
            </span>
            <textarea
              onChange={setComment}
              value={commentInputValue}
              rows={6}
              placeholder={t("SelectProblem.commentsPlaceholder") || undefined}
              className="select-problem__textarea"
            />
          </label>

          <button
            type={"submit"}
            disabled={isSubmitting}
            className="select-problem__button btn btn-primary"
          >
            {t("SelectProblem.submit")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SelectProblem;
