import React, { useEffect, useState } from "react";
import MyLaundryManager from "../lib/localStorage/MyLaundryManager";
import RoomSummary from "../components/summary/RoomSummary";
import { useNavigate, useParams } from "react-router-dom";
import AppLessLayout from "../components/layout/Layout";
import {
  SetInitPropsType,
  UIContext,
  UIContextProvider,
} from "../components/context/UIContext";
import { LaundromatLocation } from "../lib/types/FrontEnd/LaundromatLocation";
import { Room } from "../lib/types/FrontEnd/Room";
import { useApolloClient } from "@apollo/client";
import GET_MACHINE from "../lib/graphql/queries/GetMachine";
import GET_ROOM_SUMMARY, {
  RoomSummaryEndpointResponseGraphql,
  RoomSummaryResponse,
} from "../lib/graphql/queries/GetRoomSummary";
import logger from "../lib/logger";
import FourOFour from "./404";
import GET_ROOM_MACHINES, {
  RoomMachinesEndpointResponseGraphql,
} from "../lib/graphql/queries/GetRoomMachines";
import Bugsnag from "@bugsnag/js";
import GET_DATA, {
  DataEndpointResponseGraphql,
} from "../lib/graphql/queries/GetData";

interface RoomSummaryProps {}

const SummaryEntry: React.FC<RoomSummaryProps> = () => {
  const { dispatch } = React.useContext(UIContext);
  const { roomId, locationId } = useParams();
  const locationLocalStorage = MyLaundryManager.getLocation();
  const roomLocalStorage = MyLaundryManager.getRoom();
  const client = useApolloClient();
  const [roomSummary, setRoomSummary] = useState<
    RoomSummaryResponse | undefined
  >();

  const [roomSummaryLocation, setRoomSummaryLocation] = useState<
    { label: string } | undefined
  >();

  const [is404, setIs404] = useState(false);
  const [guid, setGuid] = useState<string | undefined>();

  const onRoomSummarySuccess = (summary: RoomSummaryResponse) => {
    setRoomSummary(summary);
    setRoomSummaryLocation({
      label: summary.locationLabel,
    });

    if (locationLocalStorage?.locationId !== locationId) {
      MyLaundryManager.updateLocation({
        locationId: locationId,
      } as LaundromatLocation);
    }

    if (roomLocalStorage?.roomId !== roomId) {
      MyLaundryManager.updateRoom({ roomId: roomId } as Room);
    }
  };

  useEffect(() => {
    if (roomId && locationId && !roomSummary) {
      client
        .query<RoomSummaryEndpointResponseGraphql>({
          fetchPolicy: "no-cache",
          query: GET_ROOM_SUMMARY,
          variables: { locationId: locationId, roomId: roomId },
        })
        .then((response) => {
          if (!response.data.data) {
            setIs404(true);
          } else {
            onRoomSummarySuccess(response.data.data);
          }
        })
        .catch((error) => {
          logger.error("Failed to get room summary: %o", error);
          throw error;
        });
    }
  }, [roomSummary]);

  // Get machines (to bootstrap state)
  useEffect(() => {
    if (!roomSummary || !locationId || !roomSummary) {
      return;
    }

    // This is the only query we're caching so far, because we don't really need fresh data, we just need a guid so the context can bootstrap the users local state
    client
      .query<RoomMachinesEndpointResponseGraphql>({
        fetchPolicy: "cache-first",
        query: GET_ROOM_MACHINES,
        variables: { locationId: locationId, roomId: roomId },
      })
      .then((response) => {
        const machines = response.data.data;
        if (!machines.length) {
          throw new Error("No machines in room!");
        }
        setGuid(machines[0].opaqueId);
      })
      .catch((e) => {
        logger.error("Error fetching machines: %o", e);
        Bugsnag.notify(e);
      });
  }, [roomSummary]);

  useEffect(() => {
    if (!guid) {
      return;
    }
    client
      .query<DataEndpointResponseGraphql>({
        query: GET_DATA,
        variables: { guid: guid },
        fetchPolicy: "network-only",
      })
      .then((roomInfo) => {
        if (roomInfo.error || !roomInfo.data) {
          throw new Error("Room info not found!");
        } else {
          dispatch({
            type: "set-init-props",
            payload: {
              ...roomInfo.data,
              guid: guid,
            } as SetInitPropsType,
          });
        }
      })
      .catch((error) => {
        logger.error("Error fetching room info: %o", error);
        Bugsnag.notify(error);
      });
  }, [guid]);

  return (
    <>
      {!is404 && (
        <AppLessLayout guid={guid}>
          <RoomSummary
            location={roomSummaryLocation}
            dryers={roomSummary?.dryers}
            washers={roomSummary?.washers}
          />
        </AppLessLayout>
      )}
      {is404 && <FourOFour />}
    </>
  );
};
export default SummaryEntry;
