import React from "react";
import { UIContext } from "../../../context/UIContext";
import { getOnViewRoomSummaryEvent } from "../../../tracking/SegmentEvents";

interface ShareButtonProps {
  url?: string;
  roomId: string;
  locationId: string;
}

export const ShareButton = ({ url, roomId, locationId }: ShareButtonProps) => {
  const { dispatch } = React.useContext(UIContext);
  const onClick = () => {
    navigator
      .share({
        url,
        title:
          "Check out the machine availability at this CSC GO laundry room. 👕",
      })
      .then(() => {
        dispatch({
          type: "log-event",
          payload: getOnViewRoomSummaryEvent(true, roomId, locationId),
        });
        // User shared successfully segment event
      });
  };

  const doesDeviceSupportShare = typeof navigator.share === "function" || true;

  return (
    <>
      {doesDeviceSupportShare && (
        <button className={"share-button"} onClick={onClick}>
          Share Link
        </button>
      )}
    </>
  );
};
