import React from "react";
import { Room } from "../lib/types/FrontEnd/Room";
import { Machine } from "../lib/types/ClientServices/Machines";
import { LaundromatLocation } from "../lib/types/FrontEnd/LaundromatLocation";
import { useTranslation } from "react-i18next";
import { MachineTypesClientServices } from "../lib/types/FrontEnd/Machines";

export interface MachineLocationSummaryProps {
  machine?: Machine;
  room?: Room;
  location?: LaundromatLocation;
}

/**
 * The summary title card shown on this view
 * @param props
 * @constructor
 */
const MachineLocationSummary = (props: MachineLocationSummaryProps) => {
  const { t } = useTranslation();
  return (
    <div className="select-problem__machine">
      <div className="select-problem__machine-row">
        <div className="select-problem__machine-row__label">
          {t("MachineLocationSummary.machine")}
        </div>
        <div className="select-problem__machine-row__copy">
          <span>
            {props.machine?.type === MachineTypesClientServices.Dryer
              ? t("MachineType.dryer")
              : t("MachineType.washer")}{" "}
            {props.machine?.stickerNumber}
          </span>
        </div>
      </div>

      <div className="select-problem__machine-row">
        <div className="select-problem__machine-row__label">
          {t("MachineLocationSummary.room")}
        </div>
        <div className="select-problem__machine-row__copy">
          <span>{props.room?.label}</span>
        </div>
      </div>

      <div className="select-problem__machine-row">
        <div className="select-problem__machine-row__label">
          {t("MachineLocationSummary.location")}
        </div>
        <div className="select-problem__machine-row__copy">
          <span>{props.location?.label}</span>
        </div>
      </div>
    </div>
  );
};

export default MachineLocationSummary;
