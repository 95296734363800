import { StickyMessage, StickyMessagePosition } from "./StickyMessage";
import Expire from "./Expire";
import React, { useContext } from "react";
import { UIContext } from "../context/UIContext";

const StickyMessageHOC = () => {
  const { state, dispatch } = useContext(UIContext);

  const messageBannerText = state?.flashMessage?.text;
  const messageBannerType = state?.flashMessage?.type;

  const messageCleanup = () => {
    dispatch({
      type: "set-alert-message",
      payload: {
        type: undefined,
        text: undefined,
      },
    });
  };

  return (
    <>
      {messageBannerText && messageBannerType && (
        <Expire callback={messageCleanup} delay={5000}>
          <StickyMessage
            text={messageBannerText}
            position={StickyMessagePosition.fixed}
            type={messageBannerType}
          />
        </Expire>
      )}
    </>
  );
};

export default StickyMessageHOC;
