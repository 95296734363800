import React from "react";
import { SetInitPropsType, UIContext } from "../context/UIContext";
import GET_DATA from "../../lib/graphql/queries/GetData";
import logger from "../../lib/logger";
import { useApolloClient } from "@apollo/client";
import UseInterval from "../hooks/UseInterval";
import { Machine } from "../../lib/types/ClientServices/Machines";
import { IntervalToPollForMachineUpdatesInMilliSeconds } from "../../constants/Constants";

interface SSEFallbackUpdaterProps {
  isSSEEnabled: boolean;
  machine: Machine;
}

/**
 * The point of this function is to run at a specified interval, and update EVERYTHING on the top-level state with a fresh copy from the server when SSE isn't working.
 * It will continue to run until the component is unmounted.
 *
 * Do not try to access the state in a function wrapped in setInterval or setTimeout!
 *
 * Polling is not efficient, is a terrible UX, and is a ripe environment for bugs.
 * This CODE IS MEANT TO BE DELETED, DON'T START USING THIS PATTERN ELSEWHERE!!
 *
 * @param props
 * @constructor
 */
const SSEFallbackUpdater = (props: SSEFallbackUpdaterProps) => {
  const { dispatch } = React.useContext(UIContext);
  const client = useApolloClient();
  const run = () => {
    const machine = props.machine;

    if (machine) {
      client
        .query({
          query: GET_DATA,
          variables: { guid: machine.opaqueId },
          fetchPolicy: "network-only",
        })
        .then((result) => {
          logger.trace("SSE fallback: re-initializing state");
          dispatch({
            type: "set-init-props",
            payload: {
              ...result.data,
              guid: machine.opaqueId,
            } as SetInitPropsType,
          });
        })
        .catch((error) => {
          logger.error(
            "error calling /machine/info in SSE fallback mode, error: %o",
            error
          );
        });
    }
  };

  if (!props.isSSEEnabled) {
    UseInterval(run, IntervalToPollForMachineUpdatesInMilliSeconds);
  }

  return null;
};

export default SSEFallbackUpdater;
