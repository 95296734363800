import React from "react";

/**
 *
 * @param props
 * @constructor
 */
const ContinueButton = (props: { onClick: () => void }): JSX.Element => {
  return (
    <button
      onClick={props.onClick}
      className={"btn btn--primary btn--full-width laundry-machine__button"}
    >
      Continue
    </button>
  );
};

export default ContinueButton;
