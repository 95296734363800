import React from "react";

interface CustomInputProps {}

/**
 * Simple wrapper for form inputs
 * @param props
 * @constructor
 */
export const Input = (
  props: CustomInputProps & React.InputHTMLAttributes<any>
) => {
  return <input type="text" {...props} />;
};

export default Input;
