import { gql } from "@apollo/client";

const SERVICE_REQUEST = gql`
  mutation ServiceRequest {
    data(body: $body)
      @rest(
        type: "Post"
        path: "commands/submitServiceRequest"
        method: "POST"
        bodyKey: "body"
      ) {
      id
      name
      success
      ts
    }
  }
`;

export default SERVICE_REQUEST;

export interface ServiceRequestResponseGQL {
  data: {
    id: string;
    name: string;
    success: boolean;
    ts: string;
  };
}
