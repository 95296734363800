import React, { useEffect, useState } from "react";
import { LaundryRoute } from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import { UIContext } from "../../components/context/UIContext";
import AppLessLayout from "../../components/layout/Layout";
import { MachineMode } from "../../lib/types/ClientServices/Machines";
import getLaundryRedirectUrlFromMachine from "../../lib/helpers/GetLaundryRedirect";
import { Col, Row } from "react-bootstrap";
import { StudentPaymentForm } from "../../components/student-payment/StudentPaymentForm/StudentPaymentForm";
import { FetchRoomSummary } from "../../components/student-payment/FetchRoomSummary/FetchRoomSummary";
import GET_MACHINE_PRICE, {
  MachinePriceEndpointResponseGraphql,
  MachinePricing,
} from "../../lib/graphql/queries/GetMachinePrice";
import { useApolloClient } from "@apollo/client";
import "./StudentPayment.scss";
import SSEFallbackUpdater from "../../components/fallbacks/SSEFallbackUpdater";
import { useTranslation } from "react-i18next";
import { ssoNames } from "../../components/student-payment/StudentPaymentButton/StudentPaymentButton";

const StudentPayment = () => {
  const { state, dispatch } = React.useContext(UIContext);
  const [sourceMachine, setSourceMachine] = useState(state.getSourceMachine());
  const [price, setPrice] = useState<MachinePricing | undefined>();

  const navigate = useNavigate();
  const client = useApolloClient();

  useEffect(() => {
    // Check to see if the machine is not idle (from the previous step), or not in pressStart
    // aka you cannot redirect back to a previous step, this is done to prevent stale data from causing a unwanted redirect
    if (
      sourceMachine &&
      !(
        sourceMachine.mode === MachineMode.idle ||
        sourceMachine.mode === MachineMode.running
      )
    ) {
      navigate(getLaundryRedirectUrlFromMachine(sourceMachine), {
        replace: true,
      });
    }
  }, [sourceMachine]);

  useEffect(() => {
    setSourceMachine(state.getSourceMachine());
  }, [JSON.stringify(state.machines)]);

  useEffect(() => {
    dispatch({ type: "log-page-view" });
  }, []);

  useEffect(() => {
    const sourceMachine = state.getSourceMachine();
    if (!state.initialized || !sourceMachine) {
      return;
    }

    client
      .query<MachinePriceEndpointResponseGraphql>({
        query: GET_MACHINE_PRICE,
        variables: { licensePlate: sourceMachine.licensePlate },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        setPrice(response.data.price);
      });
  }, [state.initialized]);

  const cardName = state.roomSummary?.school?.cardName;
  const logoFileUrl = state.roomSummary?.school?.logoFileUrl;
  const schoolName = state.roomSummary?.school?.name;
  const { t } = useTranslation();

  return (
    <>
      <AppLessLayout headerType={"short"}>
        <>
          {sourceMachine && (
            <SSEFallbackUpdater
              isSSEEnabled={state.isSSESupported}
              machine={sourceMachine}
            />
          )}
        </>
        <FetchRoomSummary />
        <Row className="text-center screen-title-row">
          <Col className="col-md-6 offset-md-3">
            <p>
              {t("StudentPayment.payWith")} {cardName}
            </p>
          </Col>
        </Row>
        <div className={"student-payment"}>
          {logoFileUrl && (
            <div className="student-payment__image-wrap">
              <img
                src={logoFileUrl}
                alt={`${schoolName} logo`}
                className="student-payment__image"
              />
            </div>
          )}

          {!!cardName && (
            <div className={"student-payment__copy"}>
              {t("StudentPayment.youCan") +
                " " +
                cardName +
                " " +
                t("StudentPayment.useYour")}
              {cardName}.
            </div>
          )}

          <>{price && <StudentPaymentForm price={price} />}</>
        </div>
      </AppLessLayout>
    </>
  );
};

export default StudentPayment;
