import React, { useEffect, useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { UIContext } from "../context/UIContext";
import logger from "../../lib/logger";
import { useNavigate } from "react-router-dom";
import MachinePricing, {
  TopOffDurationOption,
} from "../../lib/types/FrontEnd/MachinePricing";
import { VendPrice } from "../../lib/types/FrontEnd/vend-price";
import MachinePaymentLink from "./machine-payment-link";
import Caret from "/assets/img/caret.svg";
import { Machine } from "../../lib/types/ClientServices/Machines";
import { useApolloClient } from "@apollo/client";
import GET_MACHINE_PRICE, {
  MachinePriceEndpointResponseGraphql,
} from "../../lib/graphql/queries/GetMachinePrice";

import buttonCrossIcon from "/assets/img/button-cross-icon.svg";
import START_MACHINE_FREE_PLAY, {
  StartMachineFreePlayResponse,
  StartMachineFreePlayVars,
} from "../../lib/graphql/mutations/StartMachineFreePlay";
import { PressStartRoute } from "../../routes/routes";
import {
  getOnPaymentStartEventPayload,
  OnDryerTopOffAttemptEventPayload,
} from "../tracking/SegmentEvents";
import { StudentPaymentButton } from "../student-payment/StudentPaymentButton/StudentPaymentButton";
import { GetTransactionFeeFromPriceDetail } from "../../lib/helpers/GetTransactionFeeFromPriceDetail";
import { FetchRoomSummary } from "../student-payment/FetchRoomSummary/FetchRoomSummary";
import useFeatureFlags from "../../lib/flags/useFeatureFlags";
import { useTranslation } from "react-i18next";

interface DryerTopOffMenuButtonProps {
  machine: Machine;
}

interface adyenPaymentCompleteProps {
  id?: string;
  method: string;
  name: string;
  ts?: string;
}

export interface PaymentCompleteProps {
  amount: number;
  additionalBlocks: number;
  currency: string;
  locationId: string;
  roomId: string;
  paymentId: string;
  method: string;
  paymentProcessor: string;
}

/**
 * View that allows end-users to add more time to a running dryer.
 * @param machine
 * @constructor
 */
const DryerTopOffMenuButton: React.FC<DryerTopOffMenuButtonProps> = ({
  machine,
}) => {
  const { state, dispatch } = React.useContext(UIContext);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  // Track the
  const [selectedBlocks, setSelectedBlocks] = useState<number>();
  const [selectedOption, setSelectedOption] = useState<TopOffDurationOption>();
  const [priceOptions, setPriceOptions] = useState<TopOffDurationOption[]>();

  // Track the available top off pricing
  const [priceInfo, setPriceInfo] = useState<MachinePricing>();

  // Track the computed price for the top-off
  const [price, setPrice] = useState<VendPrice>();

  // This boolean is setup and passed in as a dependency of the useEffect that re-renders the adyen form, so changing this value will force a re-render of adyen, and it will set the correct metadata, so this is required after the user changes payment options
  const [displayAdyenForm, setDisplayAdyenForm] = useState(false);

  const client = useApolloClient();

  // First load
  useEffect(() => {
    if (!state.initialized) {
      return;
    }

    getNewMachinePricing();
  }, [state.initialized]);

  const getNewMachinePricing = () => {
    const sourceMachine = machine;
    if (!sourceMachine) {
      return;
    }

    client
      .query<MachinePriceEndpointResponseGraphql>({
        query: GET_MACHINE_PRICE,
        variables: { licensePlate: sourceMachine.licensePlate },
        fetchPolicy: "network-only",
      })
      .then((result) => {
        const machinePricing = result.data.price;
        if (
          machinePricing.topOffDuration?.options &&
          machinePricing?.topOffDuration?.options?.length > 0
        ) {
          setPriceInfo(machinePricing);
          setPriceOptions(machinePricing.topOffDuration?.options);
          const firstOption = machinePricing?.topOffDuration?.options[0];
          setSelectedOption(firstOption);
          setSelectedBlocks(firstOption?.blocks);
        }
      })
      .catch((err) => {
        logger.error(err);
      });
  };

  // Every time the selected time changes OR top off info changes
  useEffect(() => {
    if (!selectedBlocks || !priceInfo) {
      return;
    }

    // Find the option selected
    const option = priceInfo?.topOffDuration?.options?.find((o) => {
      return o.blocks == selectedBlocks;
    });

    setSelectedOption(option);

    // Update price
    const newPrice = { ...price } as VendPrice;
    newPrice.zeroDecimalAmount = option?.zeroDecimalAmount;
    newPrice.displayAmount = machine.freePlay ? "FREE" : option?.displayAmount;
    newPrice.locationId = state?.location?.locationId;
    newPrice.as400Number = state?.location?.as400Number;
    newPrice.roomId = state?.room?.roomId;
    newPrice.topOffDuration = option;
    newPrice.currency = "usd";
    newPrice.additionalBlocks = option?.blocks;
    logger.debug("New top off price: %o", newPrice);

    setPrice(newPrice);
  }, [selectedBlocks, priceInfo]);

  const selectOptions = () => {
    return priceInfo?.topOffDuration?.options?.map((option) => {
      return (
        <option key={option.blocks} value={option.blocks}>
          {option.title}
        </option>
      );
    });
  };

  /**
   *
   * @param success
   * @param result
   */
  const adyenPaymentCompleteHandler = async ({
    success,
    result,
  }: {
    success: boolean;
    result: adyenPaymentCompleteProps;
  }): Promise<PaymentCompleteProps | void> => {
    logger.debug("Credit card adyen top-up payment complete:", result);
    if (!success) {
      // Sending empty promises
      logger.debug(
        "Sending an empty promise because the payment failed",
        result
      );
      return Promise.resolve();
    }

    if (price?.additionalBlocks) {
      // need to refresh the page to re-init adyen
      window.location.reload();
    } else {
      navigate(`/${PressStartRoute}/${machine.opaqueId}`, { replace: true });
    }
  };

  /**
   *
   * @param paymentStatus
   */
  const paymentCompleteFreeHandler = (paymentStatus: {
    success: boolean;
    result: adyenPaymentCompleteProps;
  }): Promise<PaymentCompleteProps | void> => {
    logger.debug("FREEPLAY TRANSACTION COMPLETE:", paymentStatus.result);
    logger.debug(
      paymentStatus.success
        ? "FREEPLAY was successful, starting the machine"
        : "FREEPLAY was NOT successful, recording the failure"
    );

    return client
      .mutate<StartMachineFreePlayResponse, StartMachineFreePlayVars>({
        mutation: START_MACHINE_FREE_PLAY,
        variables: {
          body: {
            licensePlate: machine?.licensePlate,
            additionalBlocks: price?.additionalBlocks,
          },
        },
      })
      .then(() => {
        return adyenPaymentCompleteHandler({
          success: true,
          result: {
            ts: new Date().toDateString(),
            ...paymentStatus.result,
          },
        });
      })
      .catch((err) => {
        logger.error(err);
      });
  };

  const roomSummary = state.roomSummary;

  const flags = useFeatureFlags();

  const isFee =
    (price?.details ?? false) &&
    GetTransactionFeeFromPriceDetail(price?.details) > 0;

  const isMachineInSchoolPaymentMode =
    roomSummary?.school?.allowGuestStudentCard &&
    price &&
    !machine.freePlay &&
    flags.allowStudentPayment;

  const doesRoomAllowAdyenPayments =
    (roomSummary?.school?.allowGuestCreditCard ?? true) ||
    !flags.allowStudentPayment;

  const [isSSOLoading, setIsSSOLoading] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      {!state.initialized || !price || !price.currency ? (
        <></>
      ) : (
        <>
          <div className="pt-2">
            {!isOpen && (
              <button
                className={
                  "top-off-menu__button--add-time top-off-menu__button"
                }
                onClick={() => setIsOpen(!isOpen)}
                aria-controls="add-time-menu"
                aria-expanded={isOpen}
              >
                <img src={buttonCrossIcon} /> {t("MachineTopOffMenu.addTime")}
              </button>
            )}
            {isOpen && (
              <div className={"top-off-menu__price-display"}>
                <div className="top-off-menu__price-display__value">
                  {price.displayAmount}
                </div>
                <div className="top-off-menu__price-display__label">
                  {t("MachineTopOffMenu.add")} {selectedOption?.title}
                </div>
              </div>
            )}
          </div>
          <Collapse className="text-center" in={isOpen}>
            <div>
              <div className={"top-off-menu__dropdown-wrap"} id="add-time-menu">
                {!displayAdyenForm && (
                  <>
                    <div className="top-off-menu__dropdown-label">
                      {t("MachineTopOffMenu.selectTime")}
                    </div>
                    <div className="top-off-menu__time-to-add-dropdown-wrap">
                      <select
                        className="top-off-menu__time-to-add-dropdown"
                        onChange={(e) => {
                          if (e.target.value) {
                            setSelectedBlocks(parseFloat(e.target.value));
                          }
                        }}
                        value={selectedBlocks}
                      >
                        {selectOptions()}
                      </select>
                      <img
                        src={Caret}
                        className="top-off-menu__time-to-add-dropdown-caret"
                      />
                    </div>
                  </>
                )}

                {!displayAdyenForm &&
                  doesRoomAllowAdyenPayments &&
                  !isSSOLoading && (
                    <div className="top-off-menu__confirm">
                      <button
                        onClick={() => {
                          dispatch({
                            type: "log-event",
                            payload: getOnPaymentStartEventPayload({
                              type: price.method,
                              amount: price.zeroDecimalAmount,
                              Added_Time: price.topOffDuration?.duration,
                              processor: "A",
                            }),
                          });

                          if (machine.freePlay) {
                            return paymentCompleteFreeHandler({
                              success: true,
                              result: {
                                name: "FreePlay",
                                method: "FreePlay",
                              },
                            });
                          }

                          return setDisplayAdyenForm(true);
                        }}
                        className={`top-off-menu__button top-off-menu__button--add-time`}
                      >
                        {t("MachineTopOffMenu.pay")}
                      </button>
                    </div>
                  )}

                {/* School payment */}
                {!displayAdyenForm && isMachineInSchoolPaymentMode && (
                  <StudentPaymentButton
                    additionalBlocks={price.additionalBlocks ?? undefined}
                    fees={isFee}
                    cardName={roomSummary?.school.cardName}
                    machineGuid={machine.opaqueId}
                    price={price}
                    priceOptions={priceOptions}
                    isSSOLoading={isSSOLoading}
                    setIsSSOLoading={setIsSSOLoading}
                  />
                )}

                <FetchRoomSummary />

                <MachinePaymentLink
                  licensePlate={machine.licensePlate}
                  price={price}
                  displayAdyenForm={displayAdyenForm}
                  asyncCallback={(a) => {
                    return adyenPaymentCompleteHandler(a);
                  }}
                />

                {isOpen && (
                  <a
                    onClick={() => {
                      window.location.reload();
                    }}
                    className={`top-off-menu__button--cancel`}
                  >
                    {t("MachineTopOffMenu.cancel")}
                  </a>
                )}
              </div>
            </div>
          </Collapse>
        </>
      )}
    </>
  );
};

export default DryerTopOffMenuButton;
