import { MachineMode } from "../../lib/types/ClientServices/Machines";

export interface SSEEvent {
  eventId: string;
  ts: string;
  source: SSEEventSource;
  sourceInfo?: null | any;
  correlation?: string;
  event: SSEEventType;
  schemaVer: string;
  subject: EventSubject;
  trigger?: TriggerValues;
  kind: EventKind;
  licensePlate: string;
  prop: EventPropType;
  related?: SSERelatedData;
  value: any;
  previous: null;
  previousTS: null;
  roomId: "sA200016";
  locationId: "sA200016";
  aiHubId: "sA200016";
  receivedAt: "2021-12-01T20:36:22.884014";
}

enum SSEEventSource {
  machine = "machine",
  cloud = "cloud",
}

export enum SSEEventType {
  stateChanged = "state-changed",
}

enum EventSubject {
  laundry = "laundry",
}

enum EventKind {
  machine = "machine",
}

enum EventPropType {
  timeRemaining = "timeRemaining",
  available = "available",
}

enum TriggerValues {
  ModeChanged = "mode changed",
}

interface SSERelatedData {
  mode: MachineMode;
  timeRemaining: number;
  selectedPrice: number;
  display: string;
}
