import { useAuth0 } from "@auth0/auth0-react";
import React, { useCallback, useState } from "react";
import { Col } from "react-bootstrap";
import { useApolloClient } from "@apollo/client";
import DELETE_ACCOUNT, {
  DeleteAccountData,
  DeleteAccountVars,
} from "../../lib/graphql/mutations/DeleteAccount";
import EmailUsLink from "../../constants/EmailUsLink";
import { AccountDeletionFailureModal } from "./account-deletion-failure-modal";
import { AccountDeletionConfirmModal } from "./account-deletion-confirm-modal";
import { AccountDeletionSuccess } from "./account-deletion-success";
import { AccountDeletionReady } from "./account-deletion-ready";
import { AccountDeletionSuccessRoute } from "../../routes/routes";

export interface AccountDeletionProps {
  success: boolean;
}

export const AccountDeletion = ({
  success,
}: AccountDeletionProps): JSX.Element => {
  const {
    logout,
    isAuthenticated,
    loginWithRedirect,
    isLoading,
    getIdTokenClaims,
  } = useAuth0();
  const [reason, setReason] = useState("");
  const [deleting, setDeleting] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const client = useApolloClient();

  const deleteAccount = useCallback(() => {
    setDeleting(true);
    setShowError(false);
    getIdTokenClaims()
      .then((token) => {
        if (!token) throw new Error("Failed to get token");
        return client.mutate<DeleteAccountData, DeleteAccountVars>({
          mutation: DELETE_ACCOUNT,
          context: {
            headers: {
              Authorization: `Bearer ${token.__raw}`,
            },
          },
          variables: {
            body: {
              comment: reason,
            },
          },
        });
      })
      .then((r) => {
        const success = r.data?.data?.success ?? false;
        setShowError(!success);
        if (success) {
          logout({
            logoutParams: {
              returnTo: window.origin + "/" + AccountDeletionSuccessRoute,
            },
            openUrl(url) {
              window.location.replace(url);
            },
          });
        }
      })
      .catch((e) => {
        setShowError(true);
      })
      .finally(() => {
        setDeleting(false);
      });
  }, [reason]);

  const contactUs = () => {
    window.open(EmailUsLink, "_blank");
  };

  const loginOrOut = useCallback(() => {
    if (isAuthenticated) {
      logout({
        logoutParams: {
          returnTo: window.location.href,
        },
        openUrl(url) {
          window.location.replace(url);
        },
      });
    } else {
      loginWithRedirect({
        authorizationParams: {
          redirect_uri: window.location.href,
        },
      });
    }
  }, [isAuthenticated]);

  return (
    <div className="account-deletion">
      <Col className="account-deletion__container">
        {success ? (
          <AccountDeletionSuccess contactCSC={contactUs} />
        ) : (
          <AccountDeletionReady
            signInOrOut={loginOrOut}
            deleteAccount={(reason) => {
              setReason(reason);
              setShowConfirm(true);
            }}
            isAuthenticated={isAuthenticated}
            isDeletionInProgress={deleting}
            isLoginInProgress={isLoading}
          />
        )}
        <AccountDeletionFailureModal
          contactCSC={contactUs}
          showModal={showError}
          close={() => {
            setShowError(false);
          }}
        />
        <AccountDeletionConfirmModal
          confirmDelete={deleteAccount}
          showModal={showConfirm}
          cancel={() => {
            setShowConfirm(false);
          }}
        />
      </Col>
    </div>
  );
};
