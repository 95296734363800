import {
  MachineTypes,
  MachineTypesClientServices,
} from "../types/FrontEnd/Machines";
import {
  Machine,
  MachineCycle,
  MachineMode,
  MachineSoil,
} from "../types/ClientServices/Machines";

export const MockMachine = (
  type: MachineTypes = MachineTypes.Washer,
  overrideProps?: Partial<Machine>
): Machine => {
  let defaultProps: Machine;

  if (type === MachineTypes.Washer) {
    defaultProps = {
      groupId: null,
      locationId: "",
      notAvailableReason: null,
      roomId: "",
      stack: null,
      stackItems: null,
      available: false,
      capability: {
        showSettings: false,
        addTime: false,
        showAddTimeNotice: false,
      },
      display: "2.00",
      doorClosed: true,
      settings: {
        soil: MachineSoil.normal,
        cycle: MachineCycle.normal,
        washerTemp: null,
        dryerTemp: null,
      },
      inService: true,
      licensePlate: "AJK-002",
      mode: MachineMode.idle,
      nfcId: "2",
      opaqueId: "104df3e0-8008-4a2d-9c1b-29805f4af463",
      qrCodeId: "2",
      stickerNumber: "2",
      timeRemaining: 24,
      type: MachineTypesClientServices.Washer,
      freePlay: false,
    };
  } else {
    defaultProps = {
      groupId: null,
      locationId: "",
      mode: MachineMode.idle,
      notAvailableReason: null,
      roomId: "",
      settings: {
        soil: MachineSoil.normal,
        cycle: MachineCycle.normal,
        washerTemp: null,
        dryerTemp: null,
      },
      stack: null,
      stackItems: null,
      available: false,
      capability: undefined,
      display: "12",
      doorClosed: true,
      inService: null,
      licensePlate: "UTE-005",
      nfcId: "5",
      opaqueId: "61b19a59-0aeb-4b04-8acb-3d93bba6e3c5",
      qrCodeId: "5",
      stickerNumber: "5",
      timeRemaining: 0,
      type: MachineTypesClientServices.Dryer,
      freePlay: false,
    };
  }

  return {
    ...defaultProps,
    ...overrideProps,
  };
};
