import React from "react";
import { Modal } from "react-bootstrap";

export enum modalType {
  success = "success",
  error = "error",
}

export enum titleType {
  common = "common",
  error = "error",
}

export enum buttonType {
  primary = "primary",
  secondary = "secondary",
  error = "error",
}

interface buttonProps {
  text: any;
  type: buttonType;
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export interface GenericModalProps {
  showModal: boolean;
  closeModal?: () => void;
  type: modalType;
  icon?: string;
  title?: string;
  titleType?: titleType;
  copy?: string[];
  subCopy?: string;
  buttons: buttonProps[];
  className?: string;
}

import GreenSuccessCheckSVG from "/assets/img/green-success-check.svg";
import RedErrorIconSVG from "/assets/img/red-error-icon.svg";

/**
 * Higher order component for react-boostrap modal
 *
 * @param props
 * @constructor
 */
const GenericModal = (props: GenericModalProps) => {
  const imageSrc =
    props.icon ?? props.type === modalType.success
      ? GreenSuccessCheckSVG
      : RedErrorIconSVG;
  const typeForTitle = props.titleType ?? titleType.common;

  return (
    <Modal
      show={props.showModal}
      backdrop="static"
      onHide={props.closeModal}
      className={`request-service-confirmation-modal ${props.className ?? ""}`}
    >
      <Modal.Body className={"request-service-confirmation-modal__body"}>
        <img
          alt={props.type}
          className={"request-service-confirmation-modal__icon"}
          src={imageSrc}
        />
        <div
          className={`request-service-confirmation-modal__title request-service-confirmation-modal__title--${typeForTitle}`}
        >
          {props.title}
        </div>
        {props.copy?.map((value) => (
          // eslint-disable-next-line react/jsx-key
          <div className="request-service-confirmation-modal__copy">
            {value}
          </div>
        ))}

        {props.subCopy && (
          <div className="request-service-confirmation-modal__sub-copy">
            {props.subCopy}
          </div>
        )}

        {props.buttons.map((el, index) => (
          <a
            onClick={(e) => {
              el.onClick ? el.onClick(e) : "";

              if (el?.href) {
                window.open(el.href, "_blank");
              } else {
                props.closeModal ? props.closeModal() : "";
              }
            }}
            target={el.href ? "_blank" : undefined}
            key={index}
            className={`btn btn-${el.type} request-service-confirmation-modal__button request-service-confirmation-modal__button--${el.type}`}
          >
            {el.text}
          </a>
        ))}
      </Modal.Body>
    </Modal>
  );
};

GenericModal.defaultProps = {
  title: "Request Submitted",
  copy: "Thank you for your request. We’ll send you an email to let you know if the request is approved.",
};

export default GenericModal;
