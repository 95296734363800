import React, { useState } from "react";
import "./ToolTip.scss";
import QuestionIcon from "./QuestionIcon.svg";
import CloseIcon from "./CloseIcon.svg";
//@ts-ignore no ts definitions for this module
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";

interface ToolTipProps {
  schoolId: string;
}

export const ToolTip = (props: ToolTipProps) => {
  const [isToolTipActive, setToolTipActive] = useState(false);

  const showToolTipCopy = (show = true) => {
    setToolTipActive(show);
  };
  const { t } = useTranslation();
  return (
    <span className={"tool-tip"}>
      <span onClick={() => showToolTipCopy()} className={"tool-tip__icon"}>
        <img src={QuestionIcon} alt="Learn more" />
      </span>
      <div
        className={`tool-tip__content ${
          isToolTipActive ? "tool-tip__content--active" : ""
        }`}
      >
        <div
          className={`tool-tip__content-wrap ${
            isToolTipActive ? "tool-tip__content-wrap--active" : ""
          }`}
        >
          <OutsideClickHandler onOutsideClick={() => showToolTipCopy(false)}>
            <div className={`tool-tip__content-sub-wrap`}>
              <div className="tool-tip__content__title">
                <span className="tool-tip__content__title__copy">
                  {props.schoolId} ID
                </span>
                <span
                  className="tool-tip__content__title__close"
                  onClick={() => showToolTipCopy(false)}
                >
                  <img
                    className={"tool-tip__content__title__close-icon"}
                    src={CloseIcon}
                    alt="Close tool tip"
                  />
                </span>
              </div>
              <div
                className={`tool-tip__content__copy ${
                  isToolTipActive ? "tool-tip__content__copy--active" : ""
                }`}
              >
                {t("ToolTip.your")} {props.schoolId} {t("ToolTip.message")}
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    </span>
  );
};
