import { SetInitPropsType, UIContext } from "../components/context/UIContext";
import logger from "../lib/logger";
import { Button, Col, Row } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import MyLaundryManager, {
  UserMachine,
} from "../lib/localStorage/MyLaundryManager";
import { useNavigate } from "react-router-dom";
import { LaundryRoute } from "../routes/routes";
import Loading from "../components/Loading";
import LaundryRoomIcon from "/assets/LaundryRoom.svg";
import MachineNewLoad from "../components/machine/machine-new-load";
import MachineStateCard from "../components/machine/machine-state-card";
import AppLessLayout from "../components/layout/Layout";
import { useApolloClient } from "@apollo/client";
import GET_DATA, {
  DataEndpointResponseGraphql,
} from "../lib/graphql/queries/GetData";
import { MachineMode } from "../lib/types/ClientServices/Machines";
import SSEFallbackUpdater from "../components/fallbacks/SSEFallbackUpdater";
import { useTranslation } from "react-i18next";

const MyLaundry: React.FC = () => {
  const { state, dispatch } = useContext(UIContext);
  const [loaded, setLoaded] = useState(false);

  const localStorageManager = new MyLaundryManager();
  const [userMachines, setUserMachines] = useState<UserMachine[]>([]);
  const [hasMachinesRunning, setHasMachinesRunning] = useState(false);
  const navigate = useNavigate();
  const client = useApolloClient();

  const orderUserMachines = (machines: UserMachine[]): UserMachine[] => {
    return machines.sort((lhs, rhs) => {
      if (lhs.machine?.timeRemaining && rhs.machine?.timeRemaining) {
        if (lhs.machine.timeRemaining > rhs.machine.timeRemaining) {
          return 1;
        }
        if (lhs.machine.timeRemaining < rhs.machine.timeRemaining) {
          return -1;
        }
      }
      return 0;
    });
  };
  const { t } = useTranslation();

  useEffect(() => {
    const machines = MyLaundryManager.getUsersActiveMachines();
    if (state.machines?.length) {
      const activeMachines = state.machines.filter(
        (m) =>
          m.licensePlate ===
          machines.find((m2) => m.licensePlate === m2.licensePlate)
            ?.licensePlate
      );

      setUserMachines(
        activeMachines.map((machine) => {
          return {
            licensePlate: machine.licensePlate,
            machine: machine,
            serverDateReceived: new Date(),
          };
        })
      );
    }
  }, [JSON.stringify(state.machines)]);

  useEffect(() => {
    const machines = MyLaundryManager.getUsersActiveMachines();
    const room = MyLaundryManager.getRoom();

    if (!room) {
      navigate(`/${LaundryRoute}`, { replace: true });
    }

    if (!state.initialized) {
      return;
    }

    if (!state.room) {
      navigate(`/${LaundryRoute}`, { replace: true });
    }

    if (machines.length) {
      setUserMachines(machines);

      // Get initial data, and use the fresh machines to pull into localstorage
      client
        .query<DataEndpointResponseGraphql>({
          query: GET_DATA,
          variables: { guid: machines[0].machine.opaqueId },
          fetchPolicy: "network-only",
        })
        .then((d) => {
          dispatch({
            type: "set-init-props",
            payload: {
              ...d.data,
              guid: machines[0].machine.opaqueId,
            } as SetInitPropsType,
          });
        })
        .catch((err) => {
          throw new Error(err.message);
        });
    }

    logger.trace("machines: %o", machines);
  }, [state.initialized]);

  // On load
  useEffect(() => {
    if (state.initialized) {
      if (MyLaundryManager.getUsersActiveMachines()) {
        loadMachinesFromServer();
      }
    }
  }, [state.initialized]);

  useEffect(() => {
    if (!state.initialized) {
      return;
    }
    if (!state.location) {
      navigate(`/${LaundryRoute}`, { replace: true });
    }
  }, [state.initialized]);

  const loadMachinesFromServer = () => {
    localStorageManager
      .getMachinesFromServer()
      .then((userMachines) => {
        logger.debug(userMachines);
        setUserMachines(userMachines);
        setHasMachinesRunning(
          userMachines.some(
            (machine) =>
              machine.machine.mode === MachineMode.running ||
              machine.machine.mode === MachineMode.idle
          )
        );
      })
      .finally(() => setLoaded(true));
  };

  const removeMachine = (machine: UserMachine) => {
    const machines = MyLaundryManager.deleteMachine(machine);
    setUserMachines(machines);
  };

  return (
    <AppLessLayout>
      {!state.initialized || !loaded ? (
        <Loading />
      ) : (
        <>
          {userMachines.length > 0 && (
            <SSEFallbackUpdater
              isSSEEnabled={state.isSSESupported}
              machine={userMachines[0].machine}
            />
          )}
          <Row className="text-center screen-title-row">
            <Col className="col-md-6 offset-md-3">
              <p>{t("Laundry.myLaundry")}</p>
            </Col>
          </Row>

          {userMachines.length === 0 ? (
            <div className="text-center">
              <div className="col-md-6 offset-md-3">
                <div className="machine-state m-auto text-center">
                  <p className="pt-4 name">
                    {t("Laundry.findAMachine")}
                    <br />
                    {t("Laundry.toGetStarted")}
                  </p>
                  <img
                    src={LaundryRoomIcon}
                    style={{ width: "90%" }}
                    className="my-2"
                  />
                  <MachineNewLoad showText={false} sourceName="My Laundry" />
                </div>
              </div>
            </div>
          ) : (
            <>
              {orderUserMachines(userMachines).map((value) => (
                <div key={value.licensePlate}>
                  <MachineStateCard
                    onClose={removeMachine}
                    machine={value.machine}
                  />
                  <br />
                </div>
              ))}
            </>
          )}

          {userMachines.length > 0 && (
            <>
              <Row className="text-center screen-title-row pt-4">
                <Col className="col-md-6 offset-md-3">
                  <MachineNewLoad showText={true} sourceName="My Laundry" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="estimation-footnote">{t("Laundry.times")}</p>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </AppLessLayout>
  );
};

export default MyLaundry;
