import React, { useEffect } from "react";
import GET_ROOM_SUMMARY, {
  RoomSummaryEndpointResponseGraphql,
} from "../../../lib/graphql/queries/GetRoomSummary";
import { UIContext } from "../../context/UIContext";
import { useApolloClient } from "@apollo/client";

export const FetchRoomSummary = () => {
  const { state, dispatch } = React.useContext(UIContext);
  const client = useApolloClient();

  useEffect(() => {
    if (
      !state.location?.locationId ||
      !state.room?.roomId ||
      state.roomSummary !== undefined
    ) {
      return;
    }

    // GET_ROOM_SUMMARY
    client
      .query<RoomSummaryEndpointResponseGraphql>({
        query: GET_ROOM_SUMMARY,
        variables: {
          locationId: state.location.locationId,
          roomId: state.room.roomId,
        },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        dispatch({
          type: "set-room-summary",
          payload: {
            data: response.data.data,
          },
        });
      });
  }, [state.location?.locationId, state.room?.roomId]);

  return <></>;
};
