import React, { useEffect, useState } from "react";
import MachineInstructions from "../components/machine-instructions";
import { useNavigate } from "react-router-dom";
import MachineStatus from "../components/machine/machine-status";
import MachineStartButton from "../components/machine/machine-start-button";
import { UIContext } from "../components/context/UIContext";
import AppLessLayout from "../components/layout/Layout";
import { Machine, MachineMode } from "../lib/types/ClientServices/Machines";
import getLaundryRedirectUrlFromMachine from "../lib/helpers/GetLaundryRedirect";
import MyLaundryManager from "../lib/localStorage/MyLaundryManager";
import SSEFallbackUpdater from "../components/fallbacks/SSEFallbackUpdater";
import FreePlayConfirmation from "../components/machine/FreePlayConfirmation";
import { useTranslation } from "react-i18next";

import { MachineTypesClientServices } from "../lib/types/FrontEnd/Machines";

const PressStart = () => {
  const { state, dispatch } = React.useContext(UIContext);
  const [sourceMachine, setSourceMachine] = useState(state.getSourceMachine());
  const navigate = useNavigate();
  const localStorageManager = new MyLaundryManager();
  const [showFreePlayConfirmationStep, setShowFreePlayConfirmationStep] =
    useState(sourceMachine?.freePlay ?? false);

  useEffect(() => {
    if (!state.initialized || !state.location) return;
    const machine = state.getSourceMachine() as Machine;
    // CGEXC-532: scroll to top after payments
    window.scrollTo(0, 0);
    // Start tracking the machine in local storage!
    localStorageManager.addMachine(machine.licensePlate, state.location);
  }, [state.initialized]);

  useEffect(() => {
    // Check to see if the machine is not idle (from the previous step), or not in pressStart
    // aka you cannot redirect back to a previous step, this is done to prevent stale data from causing a unwanted redirect
    if (sourceMachine && sourceMachine.mode !== MachineMode.pressStart) {
      localStorageManager.upsertMachine(sourceMachine);
      navigate(getLaundryRedirectUrlFromMachine(sourceMachine), {
        replace: true,
      });
    }

    setShowFreePlayConfirmationStep(sourceMachine?.freePlay ?? false);
  }, [sourceMachine]);

  useEffect(() => {
    setSourceMachine(state.getSourceMachine());
  }, [JSON.stringify(state.machines)]);

  useEffect(() => {
    dispatch({ type: "log-page-view" });
  }, []);

  const onFreePlayConfirmationClick = () => {
    setShowFreePlayConfirmationStep(false);
  };

  const { t } = useTranslation();

  const txt = t("MachineStateCard.youWillHave");

  return (
    <AppLessLayout>
      {sourceMachine?.type && sourceMachine?.stickerNumber && (
        <>
          {showFreePlayConfirmationStep && (
            <>
              <FreePlayConfirmation
                machine={sourceMachine}
                onClick={onFreePlayConfirmationClick}
              />
            </>
          )}
          {!showFreePlayConfirmationStep && (
            <>
              <MachineStatus type={sourceMachine.type} />
              <MachineStartButton
                type={sourceMachine.type}
                number={sourceMachine.stickerNumber}
              />
              <MachineInstructions text={txt} />
            </>
          )}

          <SSEFallbackUpdater
            isSSEEnabled={state.isSSESupported}
            machine={sourceMachine}
          />
        </>
      )}
    </AppLessLayout>
  );
};

export default PressStart;
