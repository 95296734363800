import React, { useState } from "react";
import Image from "react-bootstrap/Image";
import { Button, Col, Modal, Row, Card, Nav } from "react-bootstrap";

import state_washer_active from "/assets/state_washer_active.svg";
import state_dryer_active from "/assets/state_dryer_active.svg";
import { RoomSummaryMachinesStatus } from "../lib/types/FrontEnd/RoomSummary";
import { Machine } from "../lib/types/ClientServices/Machines";
import empty_state from "/assets/empty_state.png";
import { MachineTypesClientServices } from "../lib/types/FrontEnd/Machines";
export interface RoomSummaryProps extends RoomSummaryMachinesStatus {}

/**
 * Renders room availability summary
 * @param washers
 * @param dryers
 * @param machines
 * @constructor
 */

const SummarySticker: React.FC<RoomSummaryProps> = ({
  washers,
  dryers,
  machines,
}: RoomSummaryMachinesStatus) => {
  const [isOpen, setShowNew] = useState(false);
  const [modalAvailable, setModalAvailable] = useState(true);
  const [modalAnimation, setModalAnimation] = useState(true);
  const [machineTypeModal, setMachineTypeModal] = useState("");
  let machinesInfo: typeof machines = [];
  const [machinesInfoAvailable, setMachinesInfoAvailable] = useState<
    typeof machines
  >([]);
  const [machinesInfoUnavailable, setMachinesInfoUnavailable] = useState<
    typeof machines
  >([]);
  const washersInfo: Array<Machine> = [];
  const dryersInfo: Array<Machine> = [];

  const showModal = (machineType: string) => {
    if (machineType === "washers") {
      machinesInfo = washersInfo;
    } else if (machineType === "dryers") {
      machinesInfo = dryersInfo;
    }
    machinesInfo.sort(
      (a, b) => Number(a.stickerNumber) - Number(b.stickerNumber)
    );
    setMachinesInfoAvailable(
      machinesInfo?.filter((machine) => machine.available === true)
    );
    setMachinesInfoUnavailable(
      machinesInfo
        ?.filter((machine) => machine.available === false)
        .sort((a, b) => Number(a.timeRemaining) - Number(b.timeRemaining))
    );
    setMachineTypeModal(machineType);
    setShowNew(true);
  };

  const hideModal = () => {
    setShowNew(false);
    setModalAnimation(true);
  };

  const ListSectionTitle = ({ machineType }: { machineType: string }) => (
    <div className="section-title ">
      <hr className="mx-4" />
      <Row>
        <Col className="col-md-3 offset-md-1 ">
          <p className=" ">
            {machineType.charAt(0).toUpperCase() + machineType.slice(1)}
          </p>
        </Col>
        <Col className="col-md-4 offset-md-4 ">
          <a
            className="summary-view-all"
            onClick={() => showModal(machineType)}
          >
            View All {">"}
          </a>
        </Col>
      </Row>
    </div>
  );

  const AvailableSummary = () => (
    <Row className="summary-row">
      <Col className="col-md-6 offset-md-3">
        <div className="machine-state m-auto">
          <p className="pt-4 mb-1 name text-center">Room Summary</p>
          <ListSectionTitle machineType="washers" />
          <div className="row">
            <div className="col-6 pl-5">
              <>
                <p className="text-left">
                  {washers.available} / {washersInfo.length} available
                </p>
              </>
            </div>
            <div className="col-4">
              <Image src={state_washer_active} />
            </div>
          </div>
          <ListSectionTitle machineType="dryers" />
          <div className="row text-left ">
            <div className="col-6 pl-5">
              <>
                <p className="text-left">
                  {dryers.available} / {dryersInfo.length} available
                </p>
              </>
            </div>
            <div className="col-4 ">
              <img src={state_dryer_active} />
            </div>
          </div>
        </div>
      </Col>
      <ModalComponent />
    </Row>
  );
  const AvailableModal = () => {
    return (
      <>
        <h5 className="col-md-6 " style={{ margin: "10px" }}>
          Available{" "}
          {machineTypeModal.charAt(0).toUpperCase() + machineTypeModal.slice(1)}
          {" : "}
          {machineTypeModal === "dryers" ? dryers.available : washers.available}
        </h5>
        <p className="col-md-12" style={{ margin: "10px" }}>
          *Availability is estimated and may vary
        </p>
        {machinesInfoAvailable?.map((machine) =>
          machine.available ? (
            <Col
              className="col-md-4 col-xs-6 summary-modal-machines-col"
              key={machine.stickerNumber}
            >
              <Card
                className="machine-card-summary"
                style={{ width: "fit-content" }}
              >
                <Card.Body>
                  <Card.Title>
                    {machine.type === "dryer" ? "Dryer" : "Washer"}
                  </Card.Title>
                  <Card.Text className="card-machine-number">
                    {machine.stickerNumber}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ) : (
            <Col
              className="col-md-4 col-xs-6 summary-modal-machines-col"
              key={machine.stickerNumber}
            >
              <Card
                className="machine-card-summary"
                style={{ width: "fit-content" }}
              >
                <Card.Body>
                  <Card.Title>
                    {machine.type === "dryer" ? "Dryer" : "Washer"}
                  </Card.Title>
                  <Card.Text className="card-machine-number">
                    {machine.stickerNumber}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          )
        )}
        {machinesInfoAvailable?.length === 0 ? (
          <EmptyStates></EmptyStates>
        ) : null}
      </>
    );
  };
  const UnavailableModal = () => {
    return (
      <>
        <h5 className="col-md-6" style={{ margin: "10px" }}>
          {" "}
          Occupied{" "}
          {machineTypeModal.charAt(0).toUpperCase() + machineTypeModal.slice(1)}
          {" : "}
          {machineTypeModal === "dryers" ? dryers.inUse : washers.inUse}
        </h5>
        <p className="col-md-12" style={{ margin: "10px" }}>
          *Times are estimated and may vary{" "}
        </p>
        {machinesInfoUnavailable?.map((machine) => (
          <Row
            className="col-12 p-3 summary-row-occupied"
            key={machine.stickerNumber}
          >
            <Col className="col-4 p-2">
              <Card
                className="machine-card-summary"
                style={{ width: "fit-content" }}
              >
                <Card.Body>
                  <Card.Title>
                    {machine.type === "dryer" ? "Dryer" : "Washer"}
                  </Card.Title>
                  <Card.Text className="card-machine-number">
                    {machine.stickerNumber}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-6 offset-md-1  p-2">
              <h5 style={{ alignSelf: "start", padding: "10px" }}>
                Time Remaining
              </h5>
              <p
                style={{ alignSelf: "start" }}
                className="card-machine-number ml-2"
              >
                {machine.timeRemaining} min
              </p>
            </Col>
          </Row>
        ))}
        {machinesInfoUnavailable?.length === 0 ? (
          <EmptyStates></EmptyStates>
        ) : null}
      </>
    );
  };

  const EmptyStates = () => {
    return (
      <>
        <Col className="col-6 offset-3  empty-state-container">
          <div className="empty-state-image">
            <img src={empty_state} className="text-center" />
            {modalAvailable ? (
              <p className="empty-state-text">No Machines Available</p>
            ) : (
              <p className="empty-state-text">No Machines Occupied</p>
            )}
          </div>
          {modalAvailable ? (
            <p className="empty-check-text">
              Check the <b>Occupied</b> tab to see how much time remains until
              the next available machine.
            </p>
          ) : (
            <p className="empty-check-text">
              Check the <b>Available</b> tab to see all open machines.
            </p>
          )}
        </Col>
      </>
    );
  };
  const ModalComponent = () => {
    return (
      <Modal
        show={isOpen}
        onHide={hideModal}
        dialogClassName="root-summary-modal-container"
        animation={modalAnimation}
        aria-labelledby="summary-modal-container"
      >
        <Modal.Header className="summary-modal-header">
          <Modal.Title className="summary-modal-title ">
            <p className="text-center">
              {" "}
              All{" "}
              {machineTypeModal.charAt(0).toUpperCase() +
                machineTypeModal.slice(1)}{" "}
            </p>
          </Modal.Title>
          <Button variant="primary" className="close" onClick={hideModal}>
            <span aria-hidden="true">&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body className="m-auto">
          <Nav
            fill
            variant="tabs"
            className="justify-content-center mb-2"
            defaultActiveKey="#first"
          >
            <Nav.Item className={modalAvailable ? "selected-nav-modal" : ""}>
              <Nav.Link
                className="col-md-12 col-sm-12"
                onClick={() => {
                  setModalAvailable(true);
                  setModalAnimation(false);
                }}
              >
                AVAILABLE
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className={!modalAvailable ? "selected-nav-modal" : ""}>
              <Nav.Link
                className="col-md-12 col-sm-12"
                onClick={() => {
                  setModalAvailable(false);
                  setModalAnimation(false);
                }}
              >
                OCCUPIED
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <div className="row ">
            {modalAvailable ? <AvailableModal /> : <UnavailableModal />}
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  if (washers == undefined || dryers == undefined) {
    return <div>no summary</div>;
  } else {
    machines?.forEach((machine) => {
      if (machine.type === MachineTypesClientServices.Washer) {
        if (machine.available || machine.notAvailableReason === "in-use") {
          washersInfo.push(machine);
        }
      } else if (machine.type === "dryer") {
        if (machine.available || machine.notAvailableReason === "in-use") {
          dryersInfo.push(machine);
        }
      }
    });
    return (
      <>
        <AvailableSummary />
      </>
    );
  }
};

export default SummarySticker;
