import MachineInstructions from "../machine-instructions";
import SummarySticker from "../summary-sticker";
import LocationTitle from "../location-title";
import React, { useEffect, useState } from "react";
import { UIContext } from "../context/UIContext";
import { RoomSummaryAvailability } from "../../lib/types/FrontEnd/RoomSummary";
import { LaundromatLocation } from "../../lib/types/FrontEnd/LaundromatLocation";
import { BaseRoomSummaryRoute, RequestServiceRoute } from "../../routes/routes";
import { CopyToClipboardButton } from "./buttons/CopyToClipboardButton/CopyToClipboardButton";
import { ShareButton } from "./buttons/ShareButton/ShareButton";
import { getOnViewRoomSummaryEvent } from "../tracking/SegmentEvents";
import { Machine } from "../../lib/types/ClientServices/Machines";
import { useApolloClient } from "@apollo/client";
import Image from "react-bootstrap/Image";
import LaundryRoomImage from "../../../assets/LaundryRoom.svg";
import GET_ROOM_SUMMARY, {
  RoomSummaryEndpointResponseGraphql,
  RoomSummaryResponse,
} from "../../lib/graphql/queries/GetRoomSummary";
import logger from "../../lib/logger";
import useFeatureFlags, {
  FeatureFlagValues,
} from "../../lib/flags/useFeatureFlags";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface RoomSummaryProps {
  washers?: RoomSummaryAvailability;
  dryers?: RoomSummaryAvailability;
  location?: RoomSummaryLocation | LaundromatLocation;
  locationId?: string;
  roomId?: string;
  machines?: Array<Machine>;
}

export interface RoomSummaryLocation {
  label: string;
  locationId?: string;
}

const RoomSummary: React.FC<RoomSummaryProps> = (props: RoomSummaryProps) => {
  const { state, dispatch } = React.useContext(UIContext);
  const { t } = useTranslation();
  const washers = props.washers ?? state.availabilitySummary?.washers;
  const dryers = props.dryers ?? state.availabilitySummary?.dryers;
  const client = useApolloClient();
  const locationId =
    props.locationId ??
    props.location?.locationId ??
    state.location?.locationId;
  const machines = props.machines ?? state.machines;
  const roomId = props.roomId ?? state.room?.roomId;
  const navigate = useNavigate();
  const [is404, setIs404] = useState(false);
  const flags = useFeatureFlags();
  const url =
    locationId && roomId
      ? `${window.location.origin}/${BaseRoomSummaryRoute}/${locationId}/${roomId}`
      : undefined;

  useEffect(() => {
    dispatch({
      type: "log-event",
      payload: getOnViewRoomSummaryEvent(false, roomId ?? "", locationId ?? ""),
    });
    if (localStorage.getItem("room")) {
      const room = JSON.parse(localStorage.getItem("room") ?? "");
      client
        .query<RoomSummaryEndpointResponseGraphql>({
          fetchPolicy: "no-cache",
          query: GET_ROOM_SUMMARY,
          variables: { locationId: room.locationId, roomId: room.roomId },
        })
        .then((response) => {
          if (!response.data.data) {
            setIs404(true);
          } else {
            setIs404(!response.data.data.isOnline);
          }
        })
        .catch((error) => {
          logger.error("Failed to get room summary: %o", error);
          throw error;
        });
    }
  }, []);

  const getRequestServiceURL = (flags: FeatureFlagValues): string => {
    if (flags.allowRequestService) {
      return `/${RequestServiceRoute}/`;
    }
    return "https://www.cscsw.com/request-service/";
  };

  const handleServiceClick = () => {
    const url = getRequestServiceURL(flags);
    if (url) {
      if (url.indexOf("https://") === 0 || url.indexOf("http://") === 0) {
        window.location.replace(url);
      } else {
        navigate(url);
      }
    }
  };

  const RoomOnline = () => {
    return (
      <>
        {washers && dryers && machines && (
          <SummarySticker
            washers={washers}
            dryers={dryers}
            machines={machines}
          />
        )}
        <MachineInstructions text={t("RoomSummary.machineInstructionsText")} />

        <div className="room-summary__button-wrap">
          <div className="room-summary__title">{t("RoomSummary.share")}</div>
          <CopyToClipboardButton
            url={url}
            locationId={locationId ?? ""}
            roomId={roomId ?? ""}
          />
          <ShareButton
            locationId={locationId ?? ""}
            roomId={roomId ?? ""}
            url={url}
          />
        </div>
      </>
    );
  };

  const RoomOffline = () => {
    return (
      <>
        <div className="headerBackground" />
        <main role="main" className="container">
          <div className="laundry-room-row">
            <div className="laundry-room__wrap">
              <div className="laundry-room m-auto">
                <h3 className="pt-3" style={{ color: " var(--error-text)" }}>
                  {" "}
                  {t("RoomSummary.roomOffline")}{" "}
                </h3>
                <Image src={LaundryRoomImage} style={{ width: "100%" }} />
                <br />
                <p className="pt-3 ">
                  <b>{t("RoomSummary.somethingWentWrong")}</b>
                </p>
                <div className="p-2">
                  <button
                    className={"service-button"}
                    onClick={() => handleServiceClick()}
                  >
                    {t("RoomSummary.serviceRequest")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  };

  return (
    <>
      <LocationTitle location={props.location ?? state.location} />
      {is404 && <RoomOffline />}
      {!is404 && <RoomOnline />}
    </>
  );
};

export default RoomSummary;
