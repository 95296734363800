import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

interface MachineInstructionsProps {
  text: string;
}

/**
 * Renders text with bootstrap markup
 * @param text
 * @constructor
 */
const MachineInstructions: React.FC<MachineInstructionsProps> = ({ text }) => {
  return (
    <Row className="start-desc-row">
      <Col className="col-md-6 offset-md-3">
        <div className="start-desc m-auto">{text}</div>
      </Col>
    </Row>
  );
};

export default MachineInstructions;
