import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Loading = () => {
  const { t } = useTranslation();
  return (
    <Row className="sticker-row" style={{ marginTop: "50px" }}>
      <Col className="col-md-6 offset-md-3">
        <div className="sticker m-auto">
          <p className="pt-2">
            <br />
          </p>
          <h2>{t("Loading.loading")}</h2>
        </div>
      </Col>
    </Row>
  );
};

export default Loading;
