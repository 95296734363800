import { LaundromatLocation } from "../types/FrontEnd/LaundromatLocation";
import logger from "../logger";
import { Room } from "../types/FrontEnd/Room";
import { Machine, MachineMode } from "../types/ClientServices/Machines";
import { useApolloClient } from "@apollo/client";
import GET_MACHINES, {
  MachinesEndpointResponseGraphql,
} from "../graphql/queries/GetMachines";
import GET_MACHINE, {
  MachineEndpointResponseGraphql,
} from "../graphql/queries/GetMachine";
import { MachineNavigationMethod } from "../types/FrontEnd/Machines";

export interface TrackedUserMachine {
  licensePlate: string;
  serverDateReceived: Date;
}

export interface UserMachine extends TrackedUserMachine {
  machine: Machine;
}

/**
 * Client-side class used to store and get additional machine info from server.
 * All of the methods that mutate local storage are static
 */
class MyLaundryManager {
  private static PURCHASING_MACHINE_LICENSE_PLATE = "machine_lp";
  private static ACTIVE_MACHINES_KEY = "machines";
  private static LOCATION_KEY = "location";
  private static ROOM_KEY = "room";
  private static ROOM_MACHINES = "room_machines";
  private static MACHINE_IDENTIFICATION_METHOD = "machine_nav_method";
  private client;

  constructor(initClient = true) {
    if (initClient) {
      this.client = useApolloClient();
    }
  }

  /**
   * Gets fresh machine state from server in a single batched API call
   */
  async getMachinesFromServer(): Promise<UserMachine[]> {
    if (!this.client) {
      throw new Error(
        "Apollo client must be defined before you can query data from the server!"
      );
    }

    const machines = MyLaundryManager.getMachinesFromLocalStorage();

    if (machines.length) {
      const licensePlates: { lp: string }[] = [];
      const params = new URLSearchParams();

      machines.forEach((machine) => {
        licensePlates.push({ lp: machine.licensePlate });
        params.append("licensePlates", machine.licensePlate);
      });

      const { data: newMachines } =
        await this.client.query<MachinesEndpointResponseGraphql>({
          fetchPolicy: "network-only",
          query: GET_MACHINES,
          variables: {
            licensePlates: params.toString(),
          },
        });

      return this.addMachines(
        newMachines.machines.map((machine) => {
          return {
            licensePlate: machine.licensePlate,
            serverDateReceived: new Date(),
            machine: machine,
          };
        })
      );
    }

    return machines;
  }

  /**
   * Right now the only way we remove machines from local storage is when they go idle, or the user hits the clear button.
   * @param machines
   */
  private removeIdleMachines = (machines: UserMachine[]) => {
    return machines.filter((machine) => {
      return machine.machine.mode !== MachineMode.idle;
    });
  };

  /**
   * Public method to return machines in local storage
   */
  public static getUsersActiveMachines(): UserMachine[] {
    return MyLaundryManager.getMachinesFromLocalStorage();
  }

  /**
   * Internal function to return the machines currently in local storage
   * @private
   */
  private static getMachinesFromLocalStorage(): UserMachine[] {
    const machineString = localStorage.getItem(
      MyLaundryManager.ACTIVE_MACHINES_KEY
    );

    return machineString ? JSON.parse(machineString) : ([] as UserMachine[]);
  }

  /**
   * Dedupe machines, keeping the most recent versions
   * @param machines
   */
  dedupeMachinesByLicensePlate(machines: UserMachine[]): UserMachine[] {
    const alreadySeen = new Set();

    // Sort machines so newest records are at the start
    machines.sort((lhs, rhs) => {
      if (lhs.serverDateReceived > rhs.serverDateReceived) return -1;
      else if (lhs.serverDateReceived < rhs.serverDateReceived) return 1;
      else return 0;
    });

    // Remove any duplicates of the same license plate, starting with the newest!
    return machines.filter((machine) => {
      const duplicate = alreadySeen.has(machine.licensePlate);
      alreadySeen.add(machine.licensePlate);
      return !duplicate;
    });
  }

  /**
   * The only way to add stuff to the local storage from outside the class!
   * Adds a single machine to local storage after pulling fresh data from server.
   * This method also updates the currently saved location, and removes any machines that are not from the current location.
   *
   * @param licensePlate
   * @param location
   * @param noCache
   */
  public async addMachine(
    licensePlate: string,
    location: LaundromatLocation,
    noCache = true
  ): Promise<UserMachine[]> {
    if (!this.client) {
      throw new Error(
        "Apollo client must be defined before you can query data from the server!"
      );
    }

    logger.debug(`Tracking licensePlate: ${licensePlate}`);

    MyLaundryManager.updateLocation(location);

    const { data: newMachine } =
      await this.client.query<MachineEndpointResponseGraphql>({
        fetchPolicy: noCache ? "network-only" : "cache-first",
        query: GET_MACHINE,
        variables: { licensePlates: licensePlate },
      });

    return this.addMachines([
      {
        licensePlate: newMachine.machine.licensePlate,
        serverDateReceived: new Date(),
        machine: newMachine.machine,
      },
    ]);
  }

  /**
   * Get machine we're currently checking out
   */
  public static getMachineInPurchaseFlow(): string | null {
    return JSON.parse(
      <string>(
        localStorage.getItem(MyLaundryManager.PURCHASING_MACHINE_LICENSE_PLATE)
      )
    );
  }

  /**
   * Save a machine in checkout to local storage
   * @param licensePlate
   */
  public static addLicensePlateToTrack(licensePlate: string | null) {
    logger.trace("Setting license plate to track: %s", licensePlate);
    localStorage.setItem(
      MyLaundryManager.PURCHASING_MACHINE_LICENSE_PLATE,
      JSON.stringify(licensePlate)
    );
  }

  /**
   * Provide method to save after pulling from server
   * @param machine
   */
  public upsertMachine(machine: Machine) {
    return this.addMachines([
      {
        licensePlate: machine.licensePlate,
        serverDateReceived: new Date(),
        machine: machine,
      },
    ]);
  }

  private static getMachineIdentificationLocalStorageString = (
    licensePlate: string
  ): string => {
    return `${MyLaundryManager.MACHINE_IDENTIFICATION_METHOD}_${licensePlate}`;
  };

  /**
   * Update ID method for license plate
   * @param licensePlate
   * @param method
   */
  public static updateMachineIdentificationMethod = (
    licensePlate: string,
    method: MachineNavigationMethod
  ): void => {
    localStorage.setItem(
      MyLaundryManager.getMachineIdentificationLocalStorageString(licensePlate),
      JSON.stringify(method)
    );
  };

  /**
   * Get machine ID method by licensePlate
   * @param licensePlate
   */
  public static getMachineIdentificationMethod = (
    licensePlate: string
  ): MachineNavigationMethod => {
    return JSON.parse(
      <string>(
        localStorage.getItem(
          MyLaundryManager.getMachineIdentificationLocalStorageString(
            licensePlate
          )
        )
      )
    ) as MachineNavigationMethod;
  };

  /**
   * Updates the current location on local storage
   * @param location
   * @private
   */
  public static updateLocation(location: LaundromatLocation) {
    const currentLocation = MyLaundryManager.getLocation();
    // Check to see if the location has changed, if so, delete all the machines from the old location and give the user a clean slate.
    if (
      currentLocation?.locationId &&
      currentLocation.locationId !== location.locationId
    ) {
      MyLaundryManager.deleteAllMachines();
    }
    localStorage.setItem(
      MyLaundryManager.LOCATION_KEY,
      JSON.stringify(location)
    );
  }

  /**
   * Updates the current room on local storage
   * @param room
   */
  public static updateRoom(room: Room) {
    localStorage.setItem(MyLaundryManager.ROOM_KEY, JSON.stringify(room));
  }

  private static getRoomFromLocalStorage(): Room | null {
    return JSON.parse(
      <string>localStorage.getItem(MyLaundryManager.ROOM_KEY)
    ) as Room | null;
  }

  /**
   *
   * @param machines
   */
  public static updateRoomMachines(machines: Machine[]) {
    localStorage.setItem(
      MyLaundryManager.ROOM_MACHINES,
      JSON.stringify(machines)
    );
  }

  public static getRoomMachines(): Machine[] {
    const machinesString = localStorage.getItem(MyLaundryManager.ROOM_MACHINES);
    const machineObjects = machinesString
      ? (JSON.parse(machinesString) as Machine[])
      : [];
    const result = [];
    for (const i in machineObjects) {
      result.push(machineObjects[i]);
    }

    return result;
  }

  /**
   * Gets location from local storage.
   * @private
   */
  private static getLocationFromLocalStorage(): LaundromatLocation | null {
    const locationString = localStorage.getItem(MyLaundryManager.LOCATION_KEY);
    return locationString
      ? (JSON.parse(locationString) as LaundromatLocation)
      : null;
  }

  public static getLocation(): LaundromatLocation | null {
    return <LaundromatLocation>MyLaundryManager.getLocationFromLocalStorage();
  }

  public static getRoom(): Room {
    return <Room>MyLaundryManager.getRoomFromLocalStorage();
  }

  /**
   * Commit new machines to local storage after removing duplicates
   * Note, this will keep
   * @param newMachines
   * @private
   */
  private addMachines(newMachines: UserMachine[]) {
    const combinedMachines = this.dedupeMachinesByLicensePlate(
      newMachines.concat(MyLaundryManager.getMachinesFromLocalStorage())
    );
    const machinesRemoveIdle = this.removeIdleMachines(combinedMachines);
    MyLaundryManager.addMachinesToLocalStorage(machinesRemoveIdle);

    return machinesRemoveIdle;
  }

  /**
   * Commits new machine object to local storage
   * @param machines
   * @private
   */
  private static addMachinesToLocalStorage(machines: UserMachine[]) {
    localStorage.setItem(
      MyLaundryManager.ACTIVE_MACHINES_KEY,
      JSON.stringify(machines)
    );
  }

  /**
   * Delete all machines in local storage
   */
  public static deleteAllMachines(): void {
    localStorage.setItem(MyLaundryManager.ACTIVE_MACHINES_KEY, "");
  }

  public static deleteMachine(machineToDelete: UserMachine): UserMachine[] {
    const machines = MyLaundryManager.getMachinesFromLocalStorage().filter(
      (machine) => machine.licensePlate !== machineToDelete.licensePlate
    );
    MyLaundryManager.deleteAllMachines();
    MyLaundryManager.addMachinesToLocalStorage(machines);
    return MyLaundryManager.getMachinesFromLocalStorage();
  }
}

export default MyLaundryManager;
