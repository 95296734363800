/**
 * Determine laundry route based on machine state.
 * @param machine
 * @param data
 */
import { Machine, MachineMode } from "../types/ClientServices/Machines";
import {
  MachineRoute,
  PressStartRoute,
  StartedRoute,
} from "../../routes/routes";

const getLaundryRedirectUrlFromMachine = (machine: Machine) => {
  if (machine.mode === MachineMode.pressStart) {
    return `/${PressStartRoute}/${machine.opaqueId}`;
  } else if (
    machine.mode === MachineMode.running ||
    machine.mode === MachineMode.endOfCycle
  ) {
    return `/${StartedRoute}/${machine.opaqueId}`;
  } else {
    return `/${MachineRoute}/${machine.opaqueId}${window.location.search}`;
  }
};

export default getLaundryRedirectUrlFromMachine;
