import { gql } from "@apollo/client";

export interface DeleteAccountVars {
  body: {
    comment?: string;
  };
}

export interface DeleteAccountDataProps {
  id?: string;
  name?: string;
  ts?: string;
  success: boolean;
  data?: {
    userId?: string;
  };
}

export interface DeleteAccountData {
  data: DeleteAccountDataProps;
}

const DELETE_ACCOUNT = gql`
  mutation DeleteAccount {
    data(body: $body)
      @rest(
        type: "Delete"
        path: "account/me"
        method: "DELETE"
        bodyKey: "body"
      ) {
      id
      name
      ts
      success
      data
    }
  }
`;

export default DELETE_ACCOUNT;
