import React, { useState } from "react";
import classNames from "classnames";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { StackItem } from "../../lib/types/ClientServices/Machines";
import { MachineTypes } from "../../lib/types/FrontEnd/Machines";
import MaytagChecked from "/assets/maytag/checked.svg";
import MaytagUnChecked from "/assets/maytag/unchecked.svg";
import DryerTop from "/assets/maytag/dryer-top.svg";
import DryerBottom from "/assets/maytag/dryer-bottom.svg";
import WasherTop from "/assets/maytag/washer-top.svg";
import WasherBottom from "/assets/maytag/washer-bottom.svg";
import SelectionImage from "/assets/maytag/selection.svg";
import MaytagDivider from "/assets/maytag/divider.svg";
import { useTranslation } from "react-i18next";
interface MachineMayTagProps {
  top?: StackItem;
  bottom?: StackItem;
  changeMachine: any;
}

/**
 * Renders the stacked maytag machines
 * @param top
 * @param bottom
 * @param changeMachine
 * @constructor
 */
const MachineMayTag: React.FC<MachineMayTagProps> = ({
  top,
  bottom,
  changeMachine,
}) => {
  if (!top || !bottom) {
    throw new Error("Maytag machines not defined!");
  }
  const [checkboxImgTop] = useState(
    top.selected ? MaytagChecked : MaytagUnChecked
  );
  const [checkboxImgBottom] = useState(
    bottom.selected ? MaytagChecked : MaytagUnChecked
  );

  const imgTop = top.type === MachineTypes.Dryer ? DryerTop : WasherTop;
  const imgBottom =
    bottom.type === MachineTypes.Dryer ? DryerBottom : WasherBottom;

  const checkboxClicked = (machine: StackItem) => {
    changeMachine(machine);
  };

  const { t } = useTranslation();

  return (
    <div>
      <Row className="maytag-row" data-testid="topMachineType">
        <Col className="col-md-6 offset-md-3 maytag-col">
          <div className="maytagRoot">
            <Image
              src={checkboxImgTop}
              className="maytagCheckbox"
              onClick={() => checkboxClicked(top)}
            />
            <div
              className={classNames("maytagContainer", {
                active: checkboxImgTop.indexOf("unchecked") < 0,
              })}
            >
              <span className="maytagTitlePrimary">
                {t("MachineMaytag.top")}
              </span>
              <span className="maytagTitleSecondary">{`${top.type} ${top.stickerNumber}`}</span>
              <div className="maytagMachine">
                <Image src={SelectionImage} className="selectionTop" />
                <Image src={imgTop} className="top" />
                <Image src={imgBottom} className="bottom" />
              </div>
              <Image src={MaytagDivider} className="divider" />
            </div>
          </div>
        </Col>
      </Row>

      <Row className="maytag-row maytagBottom" data-testid="bottomMachineType">
        <Col className="col-md-6 offset-md-3 maytag-col">
          <div className="maytagRoot">
            <Image
              src={checkboxImgBottom}
              className="maytagCheckbox"
              onClick={() => checkboxClicked(bottom)}
            />
            <div
              className={classNames("maytagContainer", {
                active: checkboxImgBottom.indexOf("unchecked") < 0,
              })}
            >
              <span className="maytagTitlePrimary">
                {t("MachineMaytag.bottom")}
              </span>
              <span className="maytagTitleSecondary">{`${bottom.type} ${bottom.stickerNumber}`}</span>
              <div className="maytagMachine">
                <Image src={SelectionImage} className="selectionBottom" />
                <Image src={imgTop} className="top" />
                <Image src={imgBottom} className="bottom" />
              </div>
              <Image src={MaytagDivider} className="divider" />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MachineMayTag;
