import React from "react";
import MachineLocationSummary, {
  MachineLocationSummaryProps,
} from "../MachineLocationSummary";
import { UIContext } from "../context/UIContext";
import { getOnRefundRequestClickEventPayload } from "../tracking/SegmentEvents";
import { useTranslation } from "react-i18next";
import {
  handleOnInput,
  handleOnInvalid,
} from "../../lib/helpers/inputHandlers";

interface RefundAmountAndSummaryProps {
  refundAmount: string;
  setRefundAmount: React.Dispatch<React.SetStateAction<string>>;
  comments: string;
  setComments: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: () => void;
}

/**
 * Form partial that collects user desired refund amount and comments
 * @param props
 * @constructor
 */
const RefundAmountAndSummary = (
  props: RefundAmountAndSummaryProps & MachineLocationSummaryProps
): JSX.Element => {
  const { dispatch } = React.useContext(UIContext);

  const onSetRefundAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setRefundAmount(e.currentTarget.value);
  };

  const onSetComments = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.setComments(e.currentTarget.value);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSubmit();
    trackSuccessfulSubmit();
  };

  const trackSuccessfulSubmit = () => {
    dispatch({
      type: "log-event",
      payload: getOnRefundRequestClickEventPayload(props.refundAmount),
    });
  };

  const { t } = useTranslation();

  return (
    <div className={"refund-amount-and-summary"}>
      <MachineLocationSummary
        machine={props.machine}
        room={props.room}
        location={props.location}
      />

      <form onSubmit={onSubmit}>
        <label className="refund-amount-and-summary__input-label">
          <span className="refund-amount-and-summary__input-label-text">
            {t("RefundAmountAndSummary.requestRefundAmount")}
            <sup>*</sup>
          </span>
          <input
            onChange={onSetRefundAmount}
            value={props.refundAmount}
            required
            type="number"
            max={10}
            onInvalid={(e: React.FormEvent<HTMLInputElement>) =>
              handleOnInvalid(e, t("RefundAmountAndSummary.invalidAmount"))
            }
            onInput={(e) => {
              handleOnInput(e);
            }}
            step="any"
            placeholder={"$0.00"}
            className="refund-amount-and-summary__input"
          />
        </label>

        <label className="refund-amount-and-summary__input-label">
          <span className="refund-amount-and-summary__input-label-text">
            {t("RefundAmountAndSummary.comments")}
            <sup>*</sup>
          </span>
          <textarea
            onChange={onSetComments}
            value={props.comments}
            onInvalid={(e) =>
              handleOnInvalid(
                e as any,
                t("RefundAmountAndSummary.invalidComments")
              )
            }
            onInput={(e) => {
              handleOnInput(e as any);
            }}
            required
            className="refund-amount-and-summary__textarea"
          />
        </label>

        <button
          type="submit"
          data-disabled={!(props.refundAmount && props.comments)}
          className={"refund-amount-and-summary__button btn btn-primary"}
        >
          {t("RefundAmountAndSummary.next")}
        </button>
      </form>
    </div>
  );
};

export default RefundAmountAndSummary;
