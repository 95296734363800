// Core type for all flags
import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

export interface FeatureFlagValues {
  // This repo requires adyen
  // useAdyenEnabled: boolean;
  allowRefundRequests: boolean;
  allowFakeServiceMachines: boolean;
  allowRequestService: boolean;
  allowStudentPayment: boolean;
  allowSsoStudentPayment: boolean;
  adyenBillingAddressRequired: boolean;
}

const defaultValues: FeatureFlagValues = {
  // useAdyenEnabled:true,
  allowRefundRequests: false,
  allowFakeServiceMachines: false,
  allowRequestService: false,
  allowStudentPayment: false,
  allowSsoStudentPayment: false,
  adyenBillingAddressRequired: false,
};

// Helper to get to context
const useFeatureFlags = () =>
  (useFlags() as FeatureFlagValues) ?? defaultValues;

export default useFeatureFlags;
