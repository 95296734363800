import { MachineTypes, MachineTypesClientServices } from "../FrontEnd/Machines";

export interface Machine {
  opaqueId: string;
  type: MachineTypesClientServices;
  locationId: string;
  roomId: string;
  stickerNumber: string;
  licensePlate: string;
  nfcId: string;
  qrCodeId: string;
  doorClosed: boolean | null;
  available: boolean | undefined;
  notAvailableReason: NotAvailableReason | null;
  inService: boolean | null;
  freePlay: boolean;
  mode: MachineMode;
  display: string | null;
  timeRemaining: number | null;
  settings: MachineSettings;
  capability?: MachineCapability;
  stack: StackType | null;
  groupId: string | null;
  stackItems: StackItem[] | null;
}

export interface StackItem {
  selected: boolean;
  stickerNumber: string;
  licensePlate: string;
  type: MachineTypes;
  stack: StackType | null;
  available: boolean | null;
  timeRemaining: number | null;
  nfcId: string;
}

export interface MachineSettings {
  soil: MachineSoil;
  cycle: MachineCycle;
  washerTemp: WasherTemp | null;
  dryerTemp: DryerTemp | null;
}

export interface MachineCapability {
  showSettings: boolean;
  addTime: boolean;
  showAddTimeNotice: boolean;
}

//here
export enum MachineMode {
  idle = "idle",
  pressStart = "pressStart", // also startMode
  running = "running",
  endOfCycle = "endOfCycle",
}

export enum NotAvailableReason {
  inUse = "in-use",
  temporary = "temporary",
  machineOffline = "offline",
}

export enum MachineCycle {
  normal = "normal",
  permPress = "perm-press",
  delicates = "delicates",
}

export enum MachineSoil {
  normal = "normal",
  light = "light",
  medium = "medium",
  heavy = "heavy",
}

export enum WasherTemp {
  cold = "cold",
  warm = "warm",
  hot = "hot",
}

export enum DryerTemp {
  highTemp = "high-temp",
  medTemp = "med-temp",
  lowTemp = "low-temp",
  noTemp = "no-temp",
  delicates = "delicates",
}

export enum StackType {
  upper = "upper",
  lower = "lower",
}
