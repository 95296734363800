import React from "react";
import { Trans, useTranslation } from "react-i18next";
import SuccessIcon from "/assets/img/success-with-circle.svg";

export interface AccountDeletionSuccessProps {
  contactCSC: () => void;
}

export const AccountDeletionSuccess = ({
  contactCSC,
}: AccountDeletionSuccessProps) => {
  const { t } = useTranslation();
  return (
    <>
      <img className="account-deletion__warning-icon" src={SuccessIcon} />
      <div
        className="account-deletion__title"
        data-testid="accountDeletionSuccessTitle"
      >
        {t("AccountDeletion.successTitle")}
      </div>
      <div className="account-deletion__success-content-wrap">
        <p className="account-deletion__success-content">
          <Trans i18nKey={"AccountDeletion.successDesc1"}>
            Your account and personal data will be managed in accordance with
            our
            <a
              className="account-deletion__hyper-link"
              href="https://www.cscsw.com/privacy-policy"
            >
              Privacy Policy
            </a>
          </Trans>
        </p>
        <p className="account-deletion__success-content">
          {t("AccountDeletion.successDesc2")}
        </p>
        <p className="account-deletion__success-content">
          {t("AccountDeletion.successDesc3")}
        </p>

        <button
          className="account-deletion__contact-us-button"
          data-testid="contactUsButton"
          onClick={() => {
            contactCSC();
          }}
        >
          {t("AccountDeletion.contactUs")}
        </button>
      </div>
    </>
  );
};
