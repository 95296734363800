import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { LaundromatLocation } from "../lib/types/FrontEnd/LaundromatLocation";
import LocationIcon from "/assets/LocIcon.svg";
import { RoomSummaryLocation } from "./summary/RoomSummary";

interface LocationTitleProps {
  location?: RoomSummaryLocation;
}

/**
 * Renders location label with icon
 * @param location
 * @constructor
 */
const LocationTitle: React.FC<LocationTitleProps> = ({ location }) => {
  return (
    <Row className="text-center screen-title-row">
      <Col className="col-md-6 offset-md-3">
        <p>
          <img className="pr-1" src={LocationIcon} />
          {location?.label}
        </p>
      </Col>
    </Row>
  );
};

export default LocationTitle;
