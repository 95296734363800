import pino from "pino";

export default pino({
  // usual pino config
  level: process.env.LOGGER_LEVEL ?? "error",
  prettyPrint: true,
  useLevelLabels: true,
  browser: {
    write: (o) => {
      console.log(o);
    },
  },
});
