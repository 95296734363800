import React from "react";
import logger from "../../lib/logger";
import MainFooter from "./footer/MainFooter";
import MainHeader from "./header/MainHeader";
import {
  FlashMessageLocalStorageName,
  FlashMessageProps,
  StickyMessage,
  StickyMessagePosition,
  StickyMessageType,
} from "../alerts/StickyMessage";
import Expire from "../alerts/Expire";

interface AppLessLayoutProps {
  children: React.ReactChild | React.ReactChild[];
  noContext?: boolean; // If set, won't render components that require router context
  guid?: string;
  headerType?: "tall" | "short";
}

const AppLessLayout = ({
  children,
  headerType,
  guid,
  noContext,
}: AppLessLayoutProps) => {
  const [messageBannerText, setMessageBannerText] = React.useState<string>();
  const [messageBannerType, setMessageBannerType] =
    React.useState<StickyMessageType>();

  React.useEffect(() => {
    if (noContext) {
      logger.trace("Layout with no-context mounting...");
      return;
    }
    logger.trace("Layout with context mounting...");

    const flashMessageRaw = localStorage.getItem(FlashMessageLocalStorageName);
    if (flashMessageRaw) {
      const flashMessage: FlashMessageProps = JSON.parse(flashMessageRaw);
      if (flashMessage.type && flashMessage.text) {
        setMessageBannerText(flashMessage.text);
        setMessageBannerType(flashMessage.type);

        logger.debug("clearing flash message!");
        localStorage.setItem(FlashMessageLocalStorageName, "");
      } else {
        logger.info(
          "local storage message does not match spec: %o",
          flashMessage
        );
      }
    }
  }, [noContext]);

  // Includes local storage driven alerts
  return (
    <div className={"csc"}>
      {messageBannerText && messageBannerType && (
        <Expire delay={5000}>
          <StickyMessage
            text={messageBannerText}
            position={StickyMessagePosition.fixed}
            type={messageBannerType}
          />
        </Expire>
      )}
      <div className={`headerBackground headerBackground--${headerType}`} />
      <MainHeader noContext={noContext} guid={guid} />
      <main role="main" className="container">
        {children}
      </main>
      <MainFooter />
    </div>
  );
};

const defaultProps: Partial<AppLessLayoutProps> = {
  headerType: "tall",
  noContext: false,
};

AppLessLayout.defaultProps = defaultProps;

export default AppLessLayout;
