import React from "react";
import { Navbar } from "react-bootstrap";
import SideMenu from "./SideMenu";
import MenuButton from "../../../../assets/nav/button.svg";
import Logo from "../../../../assets/nav/logo.svg";
import { FeatureFlagValues } from "../../../lib/flags/useFeatureFlags";

interface MainHeaderProps {
  guid?: string;
  noContext?: boolean;
}

/**
 * Site header component
 * @constructor
 */
const MainHeader = (props: MainHeaderProps) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Navbar
      expand={false}
      fixed="top"
      variant="dark"
      className="gonav-bar"
      expanded={expanded}
      onToggle={(e) => {
        setExpanded(e);
      }}
    >
      <Navbar.Toggle aria-controls="responsive-navbar-nav" id="menu-button">
        <img src={MenuButton} />
      </Navbar.Toggle>
      <Navbar.Brand className="align-self-center ml-auto">
        <img src={Logo} className="mx-auto" />
      </Navbar.Brand>
      <div className="align-self-center ml-auto mr-2 user-link" />
      <Navbar.Collapse className={"navbar__collapse"}>
        {!props.noContext && (
          <SideMenu
            flags={{} as FeatureFlagValues}
            expanded={expanded}
            setExpanded={setExpanded}
            guid={props.guid}
          />
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default MainHeader;
