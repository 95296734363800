/**
 * POST request to next.js payment endpoint
 * @param data
 */
import {
  AdyenErrorResultCode,
  AdyenPaymentSubmitErrorResponse,
  GetPaymentMethodsResponseType,
  onAdyenPaymentProps,
  onAdyenPaymentResponseProps,
} from "../../lib/types/FrontEnd/Adyen";
import { AdyenPaymentCountryCode } from "../../constants/Constants";
import logger from "../../lib/logger";
import DropinComponent from "@adyen/adyen-web/dist/types/components/Dropin/components/DropinComponent";
import {
  paymentMethods as PaymentMethodTypes,
  paymentMethods,
  paymentMethods as paymentMethodsEnum,
} from "../../lib/types/FrontEnd/Payment";
import React, { useEffect, useState } from "react";
import { ApolloClient, ApolloError } from "@apollo/client";
import ADYEN_PAYMENT, {
  AdyenPaymentData,
  AdyenPaymentDataProps,
  AdyenPaymentVars,
} from "../../lib/graphql/mutations/AdyenPayment";
import { VendMethod } from "../../lib/types/FrontEnd/vend-price";
import APPLE_PAYMENT_SESSION, {
  ApplePayPaymentSessionData,
  ApplePayPaymentSessionProps,
  ApplePayPaymentSessionVars,
} from "../../lib/graphql/mutations/ApplePaymentSession";

async function makePayment(
  data: any,
  metadata: AdyenMetadata,
  client: ApolloClient<object>
): Promise<AdyenPaymentDataProps> {
  return client
    .mutate<AdyenPaymentData, AdyenPaymentVars>({
      mutation: ADYEN_PAYMENT,
      variables: {
        body: {
          paymentRequest: {
            ...data,
            returnUrl: `${metadata.returnUrl}`,
            origin: `${window.location.origin}`,
            channel: "web",
            redirectFromIssuerMethod: "GET",
          },
          metadata: metadata,
          source: "webapp",
        },
      },
    })
    .then((r) => {
      const response = r?.data as AdyenPaymentData;
      return response.data;
    })
    .catch((error: ApolloError) => {
      // This just adds some more logging context around payment errors
      logger.debug("Adyen payment error, response: %s", JSON.stringify(error));

      if (error?.networkError && "statusCode" in error.networkError) {
        const statusCode = error.networkError?.statusCode as number;
        if (statusCode === 500) {
          logger.error("Internal server error!: %s", JSON.stringify(error));
        } else if (statusCode === 400) {
          logger.error(
            "400 Bad Request: user will be prompted to re-enter their payment information"
          );
        } else {
          logger.error(
            "Uncaught payment error response: %s",
            JSON.stringify(error)
          );
        }
      }
      throw error;
    });
}

/**
 * Valid adyen drop-in form states
 */
enum AdyenDropInStatusValues {
  success = "success",
  error = "error",
  loading = "loading", // start the loading state
  ready = "ready", // set back to the initial state
}

type AmountType = {
  type: VendMethod;
} & AmountServerPayload;

type AmountServerPayload = {
  currency: string;
  value?: number;
};

type AdyenOptions = {
  amount: AmountType;
  setStatusAutomatically: boolean;
  metadata: AdyenMetadata;
  returnUrl: string;
  billingAddressRequired: boolean;
  errorHandler?: (props: AdyenPaymentSubmitErrorResponse | undefined) => void;
  successHandler?: (props: onAdyenPaymentResponseProps | undefined) => void;
  onSubmitHandler?: (props: onAdyenPaymentProps) => void;
};

export interface AdyenMetadata {
  roomId?: string;
  method: PaymentMethodTypes;
  locationId?: string;
  licensePlate: string;
  accountId: string | null;
  additionalBlocks?: number | null;
  returnUrl: string;
}

class AdyenPaymentManager {
  private client: ApolloClient<object>;

  // Adyen lib is the first arg because it doesn't work with SSR
  // i.e. it must be imported on the browser
  constructor(client: ApolloClient<object>) {
    this.client = client;
  }

  async create(
    AdyenCheckout: any,
    {
      billingAddressRequired,
      amount,
      metadata,
      errorHandler = () => ({}),
      successHandler = () => ({}),
      onSubmitHandler = () => ({}),
    }: AdyenOptions,
    dropInRef: React.MutableRefObject<DropinComponent>,
    setDropinErrorMessage: React.Dispatch<React.SetStateAction<string>>,
    paymentMethods: GetPaymentMethodsResponseType
  ) {
    /**
     * Sets the dropin form status using react reference
     * @param status
     */
    const setDropinStatus = (status: AdyenDropInStatusValues) => {
      //@ts-ignore - the DropInComponent typescript definitions are not great, while they do accurately describe the "shape" of these objects, they don't have the correct access props for this type of usage. We still get typehinting in the IDE, but we won't be catching any errors with these objects at compile time
      dropInRef.current.setStatus(status);
    };

    const amountPayload = {
      currency: amount.currency,
      value: amount.value,
    };

    /**
     * Dropin submit callback
     * @param method
     */
    const onSubmit =
      (method: paymentMethodsEnum) =>
      (state: any, dropin: DropinComponent): void => {
        onSubmitHandler({
          amount: amount.value,
          method: amount.type,
          type: method,
        });
        makePayment(
          {
            ...state.data,
            amount: amountPayload,
            reference: Date.now(), // this should probably be a concern of the server
          },
          { ...metadata, method },
          this.client
        )
          .then((response) => {
            // Successful payment
            if (response && response.success && response.name) {
              logger.debug(response, "Successful payment response");

              if (response?.data?.paymentResponse?.action) {
                //@ts-ignore - this does exist, just not on Adyen types
                dropInRef.current.handleAction(
                  response.data.paymentResponse.action
                );
              } else {
                setDropinStatus(AdyenDropInStatusValues.success);
                // Success fire
                successHandler({
                  success: true,
                  paymentMethod: method,
                });
              }
            } else {
              logger.fatal("unexpected payment error!");
              logger.debug(
                "Adyen payment error, response: %s",
                JSON.stringify(response)
              );
              setDropinStatus(AdyenDropInStatusValues.error);
              errorHandler({
                success: false,
                errorCode: "Error",
                paymentMethod: method,
              });
            }
          })
          // Something went very wrong
          .catch((error) => {
            const errorStub: onAdyenPaymentResponseProps = {
              success: false,
              statusCode: error?.networkError?.statusCode ?? null,
              paymentMethod: method,
            };
            if (
              error?.networkError?.statusCode === 400 &&
              error.networkError.result?.data
            ) {
              const adyenErrorState = error.networkError.result.data
                .paymentResponse.resultCode as AdyenErrorResultCode;

              const paymentResponse =
                error.networkError.result.data.paymentResponse;

              const adyenErrorReason = paymentResponse.refusalReason ?? "";
              const adyenErrorCode = paymentResponse.refusalReasonCode;

              setDropinStatus(AdyenDropInStatusValues.ready);
              logger.warn(
                "400 payment error detected, prompting user to re-enter info: %o",
                error
              );

              errorHandler({
                ...errorStub,
                errorCode: adyenErrorState,
                paymentMethod: method,
                message: adyenErrorReason,
                statusCode: adyenErrorCode,
              });
            } else {
              logger.fatal("An unexpected error occurred sending payment!");
              errorHandler({
                errorCode: "Error",
                ...errorStub,
              });

              setDropinStatus(AdyenDropInStatusValues.ready);
            }
          });

        // Set the dropin form status to show loading indicator while we submit
        setDropinStatus(AdyenDropInStatusValues.loading);

        // Clear the error state
        setDropinErrorMessage("");
      };

    // Initialize the checkout object with all our lovely data
    const checkout = await AdyenCheckout({
      paymentMethodsResponse: paymentMethods,
      clientKey: process.env.PUBLIC_ADYEN_CLIENT_KEY,
      locale: "en-US",
      environment: process.env.ADYEN_TRANSACTION_ENVIRONMENT ?? "test",
      onSubmit: onSubmit(paymentMethodsEnum.googlePay),
      onAdditionalDetails: (state: any, dropin: DropinComponent) => {
        // Currently unused. Can provide additional tracking information as to the various steps the user might take in the payment form, e.g. letting us know the user is filling out 2fa, etc
      },
      onError: (
        error: { name: string; message: string },
        component: string
      ) => {
        logger.error("Adyen onError: %o", error);
        logger.error("Component: %s", component);
      },
      paymentMethodsConfiguration: {
        card: this.getCardOptions(onSubmit, billingAddressRequired),
        applepay: this.getApplepayOptions(
          amountPayload,
          metadata,
          successHandler,
          errorHandler,
          onSubmitHandler,
          amount.type
        ),
      },
    });

    // Attach the dropin form into the DOM node reference we passed into this class
    dropInRef.current = checkout.create("dropin", {
      // for reference, see https://docs.adyen.com/online-payments/drop-in-web#configuring-drop-in
      openFirstPaymentMethod: false,
      openFirstStoredPaymentMethod: true,
      showPayButton: true,
      showPaymentMethods: true,
      showStoredPaymentMethods: true,
      showRemovePaymentMethodButton: false,
      setStatusAutomatically: false,
    }) as DropinComponent;

    //@ts-ignore apparently the provided definitions from adyen don't include the mount function, telling compiler to ignore
    return dropInRef.current.mount.bind(dropInRef.current);
  }

  /**
   * Get apple pay adyen options object
   * @param amount
   * @param metadata
   * @param successHandler
   * @param errorHandler
   * @param onSubmit
   * @private
   */
  private getApplepayOptions(
    amount: {
      currency: string;
      value?: number;
    },
    metadata: AdyenMetadata,
    successHandler: (props: onAdyenPaymentResponseProps | undefined) => void,
    errorHandler: (props: AdyenPaymentSubmitErrorResponse | undefined) => void,
    onSubmit: (method: onAdyenPaymentProps) => void,
    vendMethod: VendMethod
  ) {
    return {
      // for reference, see https://docs.adyen.com/payment-methods/apple-pay/web-drop-in
      amount,
      countryCode: AdyenPaymentCountryCode,
      onSubmit: (state: any) => {
        logger.debug("Apple Pay submitted");
        onSubmit({
          amount: amount.value,
          method: vendMethod,
          type: paymentMethods.applePay,
        });
        makePayment(
          {
            ...state.data,
            amount: amount,
            reference: Date.now(),
          },
          { ...metadata, method: paymentMethods.applePay },
          this.client
        )
          .then((response: onAdyenPaymentResponseProps) => {
            logger.debug("Apple Pay success");
            // Success fire
            if (response) {
              successHandler({
                ...response,
                paymentMethod: paymentMethodsEnum.applePay,
              });
            }
          })
          .catch((error) => {
            logger.debug("Apple Pay error");
            errorHandler({
              errorCode: "Error",
              ...error,
            });
          });
      },
      onValidateMerchant: (
        resolve: (sessionData?: ApplePayPaymentSessionProps | null) => void,
        reject: () => void,
        validationURL: string
      ) => {
        logger.debug("onValidateMerchant!!2");
        this.client
          .mutate<ApplePayPaymentSessionData, ApplePayPaymentSessionVars>({
            mutation: APPLE_PAYMENT_SESSION,
            variables: { body: { verificationUrl: validationURL } },
          })
          .then((response) => {
            const outResponse: ApplePayPaymentSessionProps = {
              epochTimestamp: response.data?.data.epochTimestamp as string,
              expiresAt: response.data?.data.expiresAt as string,
              merchantSessionIdentifier: response.data?.data
                .merchantSessionIdentifier as string,
              nonce: response.data?.data.nonce as string,
              merchantIdentifier: response.data?.data
                .merchantIdentifier as string,
              domainName: response.data?.data.domainName as string,
              displayName: response.data?.data.displayName as string,
              signature: response.data?.data.signature as string,
              operationalAnalyticsIdentifier: response.data?.data
                .operationalAnalyticsIdentifier as string,
              retries: response.data?.data.retries as string,
            };
            logger.debug("apple pay session initialized: %o", outResponse);
            resolve(outResponse);
          })
          .catch((error) => {
            logger.fatal("ERROR!! %o", error);
            reject();
          });
      },
    };
  }

  /**
   * Get Credit card adyen option object
   * @param onSubmit
   * @private
   */

  private getCardOptions(
    onSubmit: (
      method: paymentMethodsEnum
    ) => (state: any, dropin: DropinComponent) => void,
    billingAddressRequired: boolean
  ) {
    return {
      setStatusAutomatically: false,
      hasHolderName: true,
      holderNameRequired: true,
      enableStoreDetails: false,
      hideCVC: false, // Change this to true to hide the CVC field for stored cards
      billingAddressRequired: billingAddressRequired, // Show the billing address input fields and mark them as required.
      name: "Credit or debit card",
      onSubmit: onSubmit(paymentMethodsEnum.creditcard), // overrides the global config
      // onChange: (state: any, dropin: DropinComponent) => {},
    };
  }
}

export default AdyenPaymentManager;
