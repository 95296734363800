import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Machine } from "../../lib/types/ClientServices/Machines";
import { useTranslation } from "react-i18next";
import { MachineTypesClientServices } from "../../lib/types/FrontEnd/Machines";
interface MachineStickerProps {
  machine: Machine;
}

/**
 * Simple display component that displays machine sticker number and type
 * @param machine
 * @constructor
 */
const MachineSticker: React.FC<MachineStickerProps> = ({ machine }) => {
  const { t } = useTranslation();
  return (
    <Row className="sticker-row">
      <Col className="col-md-6 offset-md-3">
        <div className="sticker m-auto">
          <p className="pt-2">
            {machine.type === MachineTypesClientServices.Dryer
              ? t("MachineType.dryer")
              : t("MachineType.washer")}
          </p>
          <h1>{machine.stickerNumber}</h1>
        </div>
      </Col>
    </Row>
  );
};

export default MachineSticker;
