import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import getLaundryRedirectUrlFromMachine from "../lib/helpers/GetLaundryRedirect";
import GET_MACHINE_BY_NFC, {
  GetMachineByNFC,
} from "../lib/graphql/queries/GetMachineByNFC";
import { setLocalStorageFromAPIResponse } from "../lib/helpers/setLocalStorageFromAPIResponse";
import MyLaundryManager from "../lib/localStorage/MyLaundryManager";
import { MachineNavigationMethod } from "../lib/types/FrontEnd/Machines";

/**
 * Redirects to appropriate view for the current state of the machine
 * Note, this pulls a fresh copy of the machine and we throw it away...
 *
 * This and QR is the main entry point that bootstraps the local state that is required for SSE
 * Entering the application from anywhere else won't set a locationId by the time the UIContext (which manages SSE state) is initialized,
 * and will require a refresh by the user in order to get SSE events
 *
 * @constructor
 */
export const NFC = () => {
  const navigate = useNavigate();
  const { nfc } = useParams();

  useQuery<GetMachineByNFC>(GET_MACHINE_BY_NFC, {
    variables: { nfc: nfc },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setLocalStorageFromAPIResponse(data);
      MyLaundryManager.updateMachineIdentificationMethod(
        data.machine.licensePlate,
        MachineNavigationMethod.nfc
      );
      navigate(getLaundryRedirectUrlFromMachine(data.machine), {
        replace: true,
      });
    },
    onError: (err) => {
      throw new Error(err.message);
    },
  });

  return <div />;
};
