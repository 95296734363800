import { gql } from "@apollo/client";
import { Machine } from "../../types/ClientServices/Machines";

const GET_MACHINE_BY_NFC = gql`
  query GetMachineByNFC {
    machine(nfc: $nfc) @rest(type: "Post", path: "machine/nfc/{args.nfc}") {
      opaqueId
      type
      locationId
      roomId
      stickerNumber
      licensePlate
      nfcId
      qrCodeId
      doorClosed
      available
      notAvailableReason
      inService
      freePlay
      mode
      display
      timeRemaining
      settings
      capability
      groupId
      stack
      stackItems
    }
  }
`;

export default GET_MACHINE_BY_NFC;

export interface GetMachineByNFC {
  machine: Machine;
}
