import React, { useEffect, useState } from "react";
import MachineLocationSummary, {
  MachineLocationSummaryProps,
} from "../MachineLocationSummary";
import AddressInfoFormFields from "./AddressInfoFormFields";
import { ContactInfoParentProps } from "./ContactInfo";
import Input from "../forms/input";
import Select from "../forms/select";
import Label from "../forms/label";
import { useTranslation } from "react-i18next";
import {
  handleOnInput,
  handleOnInvalid,
} from "../../lib/helpers/inputHandlers";

enum CreditCardType {
  americanexpress = "American Express",
  discover = "Discover",
  master = "Mastercard",
  visa = "Visa",
}

interface CardVerificationFields {
  FirstName: string;
  SetFirstName: React.Dispatch<React.SetStateAction<string>>;

  LastName: string;
  SetLastName: React.Dispatch<React.SetStateAction<string>>;

  LastFourDigits: string;
  SetLastFourDigits: React.Dispatch<React.SetStateAction<string>>;

  Type: string;
  SetType: React.Dispatch<React.SetStateAction<string>>;
}

interface CardInfoProps {
  CardVerificationInfo: CardVerificationFields;
  ContactInfo: ContactInfoParentProps;
  PhoneNumber: string;
  SetPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  Email: string;
  SetEmail: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: () => void;
}

/**
 * Form fields and handlers for request refund - digital payments
 * @param props
 * @constructor
 */
const CardInfo = (props: CardInfoProps & MachineLocationSummaryProps) => {
  const [disabled, setDisabled] = useState(true);

  /**
   * Submit handlers
   * @param e
   */
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSubmit();
  };

  /**
   * Simple validation
   */
  useEffect(() => {
    if (
      props.Email &&
      props.CardVerificationInfo.Type &&
      props.CardVerificationInfo.LastFourDigits &&
      props.CardVerificationInfo.LastName &&
      props.CardVerificationInfo.FirstName &&
      props.ContactInfo.Zip &&
      props.ContactInfo.State &&
      props.ContactInfo.City &&
      props.ContactInfo.StreetAddress
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    props.Email,
    props.CardVerificationInfo.Type,
    props.CardVerificationInfo.LastFourDigits,
    props.CardVerificationInfo.LastName,
    props.CardVerificationInfo.FirstName,
    props.ContactInfo.Zip,
    props.ContactInfo.State,
    props.ContactInfo.StreetAddress2,
    props.ContactInfo.City,
    props.ContactInfo.StreetAddress,
  ]);

  const { t } = useTranslation();
  return (
    <div className={"card-info"}>
      <MachineLocationSummary
        machine={props.machine}
        room={props.room}
        location={props.location}
      />

      <p className={"card-info__paragraph"}>{t("CardInfo.enterCardInfo")}</p>

      <form onSubmit={onSubmit}>
        <div className="card-info__input-label">
          <span className="card-info__input-label-text">
            {t("CardInfo.nameOnCard")}
            <sup>*</sup>
          </span>
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.CardVerificationInfo.SetFirstName(e.currentTarget.value);
            }}
            value={props.CardVerificationInfo.FirstName}
            required
            type="text"
            autoComplete={"cc-given-name"}
            placeholder={t("CardInfo.firstName") || "First Name"}
            className="card-info__input"
            onInvalid={(e) =>
              handleOnInvalid(e as any, t("CardInfo.firstNameFailed"))
            }
            onInput={(e) => {
              handleOnInput(e as any);
            }}
          />

          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.CardVerificationInfo.SetLastName(e.currentTarget.value);
            }}
            value={props.CardVerificationInfo.LastName}
            required
            type="text"
            autoComplete={"cc-family-name"}
            placeholder={t("CardInfo.lastName") || "Last Name"}
            className="card-info__input"
            onInvalid={(e: React.FormEvent<HTMLInputElement>) =>
              handleOnInvalid(e, t("CardInfo.lastNameFailed"))
            }
            onInput={(e) => {
              handleOnInput(e);
            }}
          />
        </div>

        <Label className="card-info__input-label">
          <span className="card-info__input-label-text">
            {t("CardInfo.cardType")}
            <sup>*</sup>
          </span>
          <div className="card-info__select-wrap">
            <Select
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                props.CardVerificationInfo.SetType(e.currentTarget.value);
              }}
              required
              autoComplete={"Off"}
              placeholder={t("CardInfo.select") || "Select"}
              className="card-info__input--select card-info__input--select--card-type"
              onInvalid={(e: React.FormEvent<HTMLInputElement>) =>
                handleOnInvalid(e, t("CardInfo.selectFailed"))
              }
              onInput={(e) => {
                handleOnInput(e as any);
              }}
            >
              <option value={""}>{t("CardInfo.select")}</option>
              <option value={CreditCardType.americanexpress}>
                {CreditCardType.americanexpress}
              </option>
              <option value={CreditCardType.discover}>
                {CreditCardType.discover}
              </option>
              <option value={CreditCardType.master}>
                {CreditCardType.master}
              </option>
              <option value={CreditCardType.visa}>{CreditCardType.visa}</option>
            </Select>
          </div>
        </Label>

        <Label className="card-info__input-label">
          <span className="card-info__input-label-text">
            {t("CardInfo.email")}
            <sup>*</sup>
          </span>
          <Input
            onChange={(e) => {
              handleOnInvalid(e, t("CardInfo.emailFailed"));
              props.SetEmail(e.currentTarget.value);
            }}
            value={props.Email}
            required
            autoComplete={"email"}
            type="email"
            placeholder={t("CardInfo.email") || "Email"}
            className="card-info__input"
            onInvalid={(e: React.FormEvent<HTMLInputElement>) =>
              handleOnInvalid(e, t("CardInfo.emailFailed"))
            }
            onInput={(e) => {
              handleOnInput(e as any);
            }}
          />
        </Label>
        <Label className="card-info__input-label">
          <span className="card-info__input-label-text">
            {t("CardInfo.phoneNumber")}
            <sup>*</sup>
          </span>

          <Input
            onChange={(e) => {
              props.SetPhoneNumber(e.currentTarget.value);
            }}
            value={props.PhoneNumber}
            required
            type="tel"
            title={
              t("CardInfo.phoneNumberTitle") ||
              "Please enter your 10 digit phone number"
            }
            autoComplete={"tel"}
            placeholder={t("CardInfo.phoneNumber") || "Phone Number"}
            className="card-info__input"
            onInvalid={(e: React.FormEvent<HTMLInputElement>) =>
              handleOnInvalid(e, t("CardInfo.phoneNumberFailed"))
            }
            onInput={(e) => {
              handleOnInput(e as any);
            }}
          />
        </Label>

        <AddressInfoFormFields
          onStateChange={(e) =>
            props.ContactInfo.SetState(e.currentTarget.value)
          }
          onCityChange={(e) =>
            props.ContactInfo.SetCity((e.target as HTMLInputElement).value)
          }
          onStreetAddressChange={(e) =>
            props.ContactInfo.SetStreetAddress(e.currentTarget.value)
          }
          onStreetAddressChange2={(e) =>
            props.ContactInfo.SetStreetAddress2(e.currentTarget.value)
          }
          OnZipChange={(e) => props.ContactInfo.SetZip(e.currentTarget.value)}
          State={props.ContactInfo.State}
          City={props.ContactInfo.City}
          StreetAddress={props.ContactInfo.StreetAddress}
          StreetAddress2={props.ContactInfo.StreetAddress2}
          Zip={props.ContactInfo.Zip}
        />

        <Label className="card-info__input-label">
          <span className="card-info__input-label-text">
            {t("CardInfo.lastFourDigits")}
            <sup>*</sup>
          </span>
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleOnInvalid(e, t("CardInfo.lastFourDigitsValidation"));
              props.CardVerificationInfo.SetLastFourDigits(
                e.currentTarget.value
              );
            }}
            value={props.CardVerificationInfo.LastFourDigits}
            required
            maxLength={4}
            minLength={4}
            autoComplete={"off"}
            pattern="[0-9]{4}"
            title={
              t("CardInfo.lastFourDigitsTitle") ||
              "Please enter the last 4 digits of the credit card used to make the purchase you are refunding."
            }
            type="text"
            data-testid={"lastFourDigits"}
            placeholder={t("CardInfo.lastFourDigits") || "Last 4 Digits"}
            className="card-info__input card-info__input--card-no"
            onInvalid={(e: React.FormEvent<HTMLInputElement>) =>
              handleOnInvalid(e, t("CardInfo.lastFourDigitsFailed"))
            }
            onInput={(e) => {
              handleOnInput(e as any);
            }}
          />
        </Label>

        <a
          target={"_blank"}
          rel="noreferrer"
          className={"card-info__link"}
          href={
            "https://help.mycscgo.com/hc/en-us/articles/5915607865111-I-m-trying-to-request-a-refund-but-I-paid-with-Apple-Pay-or-Google-Pay-What-do-I-enter-as-the-last-4-digits-of-my-credit-card-number-"
          }
        >
          {t("CardInfo.whatIf")}
        </a>

        <button
          type="submit"
          data-disabled={disabled}
          className="btn btn-primary card-info__button"
        >
          {t("CardInfo.submit")}
        </button>
      </form>
    </div>
  );
};
export default CardInfo;
