import { MachinePricingDetail, TopOffDurationOption } from "./MachinePricing";

export enum VendMethod {
  full = "Full",
  topOff = "Add Time",
}

export interface VendPriceSharedProps {
  zeroDecimalAmount?: number;
  method: VendMethod;
}

export interface VendPriceSegmentProps extends VendPriceSharedProps {
  duration?: number;
}

export interface VendPrice extends VendPriceSharedProps {
  currency: string;
  displayAmount?: string;
  locationId?: string;
  roomId?: string;
  as400Number?: string;
  details: MachinePricingDetail[];
  selectedPrice?: number | null;
  capabilities: any | null;
  additionalBlocks?: number | null;
  topOffDuration?: TopOffDurationOption | null;
}
