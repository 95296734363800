import React from "react";
import { AdyenPaymentSubmitErrorResponse } from "../../lib/types/FrontEnd/Adyen";
import GenericModal, {
  buttonType,
  modalType,
} from "../request-service/generic-modal";
import { useTranslation } from "react-i18next";

interface ErrorModalPaymentProps {
  showModal: boolean;
  closeModal: () => void;
  error: AdyenPaymentSubmitErrorResponse;
}

enum ErrorModalType {
  error = "Error",
  cancelled = "Cancelled",
  refused = "Refused",
}

/**
 * Higher order component for generic modal, sets error state defaults
 * @param props
 * @constructor
 */
const ErrorModalPayment = (props: ErrorModalPaymentProps): JSX.Element => {
  const statusCodesToOverrideDefaultsCancelled = [
    "5",
    "4",
    "3",
    "2",
    "20",
    "15",
  ];
  const { t } = useTranslation();

  const mapErrorToModalType = (
    error: AdyenPaymentSubmitErrorResponse
  ): ErrorModalType => {
    // Set status codes that override
    const code = error.statusCode;
    const type = error.errorCode;

    if (code && statusCodesToOverrideDefaultsCancelled.includes(code)) {
      return ErrorModalType.cancelled;
    }

    if (type === ErrorModalType.refused) {
      return ErrorModalType.refused;
    }
    if (type === ErrorModalType.cancelled) {
      return ErrorModalType.cancelled;
    }
    return ErrorModalType.error;
  };
  const getCopy = (type: ErrorModalType): string => {
    if (type === ErrorModalType.cancelled) {
      return t("ErrorModalPayment.copyOne");
    }
    if (type === ErrorModalType.refused) {
      return t("ErrorModalPayment.copyTwo");
    }
    return t("ErrorModalPayment.copyThree");
  };

  const getTitle = (type: ErrorModalType): string => {
    if (type === "Cancelled") {
      return t("ErrorModalPayment.titleOne");
    }
    if (type === "Refused") {
      return t("ErrorModalPayment.titleTwo");
    }
    return t("ErrorModalPayment.titleThree");
  };

  const copy = getCopy(mapErrorToModalType(props.error));
  const title = getTitle(mapErrorToModalType(props.error));

  return (
    <GenericModal
      buttons={[
        {
          text: t("ErrorModalPayment.close"),
          type: buttonType.primary,
        },
      ]}
      showModal={props.showModal}
      closeModal={props.closeModal}
      title={title}
      copy={[copy]}
      type={modalType.error}
    />
  );
};

export default ErrorModalPayment;
