import {
  AppleStoreClickEvent,
  AppleStoreFooterClickEvent,
  GoogleStoreClickEvent,
  GoogleStoreFooterClickEvent,
  OnCashPaymentClickEvent,
  OnConfirmationModalRequestRefundCloseEvent,
  OnConfirmationModalServiceRequestCloseEvent,
  OnDryerTopOffAttemptEvent,
  OnEnterRefundAddMachineEvent,
  OnEnterServiceRequestAddMachineEvent,
  OnEnterServiceRequestAddMachineEventDuplicate,
  OnErrorModalCloseEvent,
  OnErrorModalContactEvent,
  OnMachineNotFoundEvent,
  OnMachineNotFoundMessageEvent,
  OnMachineNotFoundName,
  OnPaymentAttemptEvent,
  OnPaymentClickEvent,
  OnPaymentFailEvent,
  OnPaymentStartEvent,
  OnPaymentSuccessEvent,
  OnProblemCodeSelectedEvent,
  OnPromoModalOpenEvent,
  OnRefundRequestClickEvent,
  OnRequestRefundPageViewEvent,
  OnRequestRefundSubmitEvent,
  OnStartNewMachineEvent,
  OnSuccessfulRefundRequestEvent,
  OnViewRoomSummaryEvent,
  PaymentProcessorSegmentValue,
  RequestServiceMachineNotFoundNameEvent,
  RequestServicePageView,
  RequestServiceProblemCodeClick,
  RequestServiceProblemCodeSelected,
  RequestServiceProblemCodesNotFoundEvent,
  ssoViewedEvent,
} from "./SegmentEventTypes";
import {
  VendMethod,
  VendPriceSegmentProps,
} from "../../lib/types/FrontEnd/vend-price";
import { Machine } from "../../lib/types/ClientServices/Machines";
import {
  ApprivaSSOTypes,
  onAdyenPaymentProps,
  onAdyenPaymentResponseProps,
  onAdyenPaymentStartProps,
} from "../../lib/types/FrontEnd/Adyen";
import { paymentMethods as paymentMethodsEnum } from "../../lib/types/FrontEnd/Payment";
import { EnterLicenseGenericType } from "../enter-license/enter-license";
import { MachineTypesClientServices } from "../../lib/types/FrontEnd/Machines";

const formatCentsToDollars = (rawNumber?: number): string => {
  return rawNumber ? (rawNumber / 100).toFixed(2) : "";
};

export const GetMachineUnavailableEventPayload = (
  machine: Machine
): OnMachineNotFoundMessageEvent => {
  return {
    name: "machine is offline",
    properties: {
      error_message:
        machine.type === MachineTypesClientServices.Washer
          ? "washer is offline"
          : "dryer is offline",
      error_title: "Pay to Start",
      status_code: 0,
    },
  };
};

export const OnMachineNotFoundEventPayload = (
  type: EnterLicenseGenericType
): OnMachineNotFoundEvent => {
  const name = `Request ${
    type === EnterLicenseGenericType.service ? "Service" : "Refund"
  } License Plate Not Found` as OnMachineNotFoundName;
  return {
    name: name,
    properties: {
      "error message": "Machine not found. Try again",
    },
  };
};

export const OnDryerTopOffAttemptEventPayload = (
  additionalMinutes: number,
  amountInCents?: number
): OnDryerTopOffAttemptEvent => {
  return {
    name: "Dryer Top Off Attempt",
    properties: {
      Added_Time: additionalMinutes,
      amount: formatCentsToDollars(amountInCents),
    },
  };
};

export const RequestServiceMachineNotFoundNameEventPayload: RequestServiceMachineNotFoundNameEvent =
  { name: `request_service_machine_not_found` };

export function getOnPaymentRefundRequestEventPayload(
  title: "Digital Payment" | "Cash or Coins"
): OnPaymentClickEvent {
  return {
    properties: {
      title: title,
      "UI Type": "Button",
    },
    name: "Select Refund Type",
  };
}

export const OnRequestRefundPageViewEventPayload: OnRequestRefundPageViewEvent =
  {
    name: `View Request Refund`,
    properties: {
      title: "Request Refund",
      "UI Type": "Text Link",
    },
  };

export function getOnEnterRefundAddMachineEventPayload(
  licensePlate: string
): OnEnterRefundAddMachineEvent {
  return {
    name: `Request Refund License Plate`,
    properties: {
      title: `Next`,
      "UI Type": `Button`,
      "License Plate": licensePlate,
    },
  };
}

export function getOnEnterServiceRequestAddMachineEventPayload(
  licensePlate: string
): OnEnterServiceRequestAddMachineEvent {
  return {
    name: `Request Service License Plate`,
    properties: {
      title: `Next`,
      "UI Type": `Button`,
      "License Plate": licensePlate,
    },
  };
}

export function getOnEnterServiceRequestAddMachineDuplicateEventPayload(
  licensePlate: string
): OnEnterServiceRequestAddMachineEventDuplicate {
  return {
    name: "Get Machine Details",
    properties: {
      Machine_ID: licensePlate,
    },
  };
}

export const AppStoreFooterClickEventPayload: AppleStoreFooterClickEvent = {
  name: "app_store_button_footer",
};

export const GoogleStoreFooterClickEventPayload: GoogleStoreFooterClickEvent = {
  name: "google_play_button_footer",
};

export const OnConfirmationModalCloseRefundSuccessEventPayload: OnConfirmationModalRequestRefundCloseEvent =
  {
    name: `Request Refund Success - Close`,
    properties: {
      title: "Request Submitted",
      "UI Type": "button",
    },
  };

export const OnConfirmationModalCloseServiceRequestEventPayload: OnConfirmationModalServiceRequestCloseEvent =
  {
    name: `Request Service Success - Close`,
    properties: {
      title: "Request Submitted",
      "UI Type": "button",
    },
  };

export function getOnStartNewMachineEventPayload(
  sourceName: "My Laundry" | "Machine Started"
): OnStartNewMachineEvent {
  return {
    name: "Start New Machine",
    properties: {
      source: sourceName,
    },
  };
}

export function getOnPaymentStartEventPayload(
  props: onAdyenPaymentStartProps
): OnPaymentStartEvent {
  return {
    name: "Payment Start",
    properties: {
      type: props.type,
      amount: formatCentsToDollars(props.amount),
      Added_Time: props?.Added_Time,
      paymentProcessor: props.processor,
    },
  };
}

export function getOnPaymentAttemptEventPayload(
  props: onAdyenPaymentProps
): OnPaymentAttemptEvent {
  return {
    name: "Payment Attempt",
    properties: {
      type: props.method,
      amount: formatCentsToDollars(props.amount),
      method: props.type,
      Added_Time: props.Added_Time,
      "student-card-ID": props.studentCardID,
      paymentProcessor: props.processor,
      ssoPayment: props.ssoPayment,
    },
  };
}

export function getPaymentSuccessEventPayload(
  price: {
    method: VendMethod;
    duration?: number;
    zeroDecimalAmount?: number;
  },
  paymentMethod?: paymentMethodsEnum,
  studentCardId?: string,
  ssoPayment?: ApprivaSSOTypes
): OnPaymentSuccessEvent {
  return {
    name: "Payment Success",
    properties: {
      method: paymentMethod ?? paymentMethodsEnum.creditcard,
      amount: formatCentsToDollars(price.zeroDecimalAmount),
      type: price.method,
      Added_Time: price?.duration,
      paymentProcessor: studentCardId ? "Apr" : "A",
      ssoPayment: ssoPayment,
    },
  };
}

export function getPaymentErrorEventPayload(
  price: VendPriceSegmentProps,
  result?: onAdyenPaymentResponseProps,
  studentCardID?: string,
  ssoPayment?: ApprivaSSOTypes
): OnPaymentFailEvent {
  return {
    name: "Payment Failed",
    properties: {
      method: result?.paymentMethod as paymentMethodsEnum,
      amount: formatCentsToDollars(price.zeroDecimalAmount),
      type: price.method,
      declineCode: "N/A",
      errorCode: result?.statusCode,
      errorMessage: result?.message,
      Added_Time: price?.duration,
      paymentProcessor: studentCardID ? "Apr" : "A",
      ssoPayment: ssoPayment,
    },
  };
}

export const OnRequestRefundSubmitEventPayload: OnRequestRefundSubmitEvent = {
  name: "Request Refund Submit",
};

export const OnErrorModalContactEventPayload: OnErrorModalContactEvent = {
  name: "Request Refund Failed - Contact Us",
  properties: {
    title: "Contact Us",
    "UI Type": "button",
  },
};

export const OnErrorModalCloseEventPayload: OnErrorModalCloseEvent = {
  name: "Request Refund Failed",
  properties: {
    title: "Request Failed",
    "UI Type": "button",
  },
};

export function getRequestServiceProblemCodeSelectedEventPayload(
  problemCode: string
): OnProblemCodeSelectedEvent {
  return {
    name: "Problem code selected",
    properties: {
      Problem_code: problemCode,
    },
  };
}

export function getRequestServiceSubmitEventPayload(
  selectedMachine: Machine,
  success: boolean
): OnCashPaymentClickEvent {
  return {
    name: `Submit Request Service`,
    properties: {
      licensePlateOverride: selectedMachine.licensePlate,
      Response: success ? "Request Submitted" : "Request Failed",
    },
  };
}

export const RequestServicePageViewPayload: RequestServicePageView = {
  name: "Viewed Request Service Screen",
  properties: undefined,
};

export const RequestServiceProblemCodesNotFoundEventPayload: RequestServiceProblemCodesNotFoundEvent =
  {
    name: `request_service_problem_codes_not_found`,
  };

export function getApplePromoModalTrackingPayload(
  segmentTrackingSlug: "mylaundry" | "home" | "machineview"
): AppleStoreClickEvent {
  return {
    name: `app_store_button_${segmentTrackingSlug}`,
  };
}

export function getssoViewedEventPayload(
  title: string,
  paymentProcessor: PaymentProcessorSegmentValue = "Apr"
): ssoViewedEvent {
  return {
    name: title,
    properties: { paymentProcessor },
  };
}

export function getGooglePromoModalPayload(
  segmentTrackingSlug: "mylaundry" | "home" | "machineview"
): GoogleStoreClickEvent {
  return {
    name: `google_play_button_${segmentTrackingSlug}`,
  };
}

export function getPromoModalOpenEventPayload(
  segmentTrackingSlug: "mylaundry" | "home" | "machineview"
): OnPromoModalOpenEvent {
  return {
    name: `banner_impressions_${segmentTrackingSlug}`,
    properties: {
      source: "My Laundry",
    },
  };
}

export function getOnRefundRequestClickEventPayload(
  refundAmount: string
): OnRefundRequestClickEvent {
  return {
    name: `Request Refund Amount`,
    properties: {
      title: "Next",
      "UI Type": "Button",
      Amount: refundAmount,
    },
  };
}

export function getOnSuccessfulRefundRequestEventPayload(
  FirstName: string,
  LastName: string,
  LastFourDigits: string,
  Type: string,
  Email: string
): OnSuccessfulRefundRequestEvent {
  return {
    name: "Request Refund Success",
    properties: {
      title: "Submit",
      "UI Type": "Button",
      "First Name": FirstName,
      "Last Name": LastName,
      "Last 4 Digits": LastFourDigits,
      "Card Type": Type,
      Email: Email,
    },
  };
}

export function getRequestServiceProblemCodeSelected(
  problem: string
): RequestServiceProblemCodeSelected {
  return {
    name: "Problem code selected",
    properties: {
      Problem_code: problem,
    },
  };
}

export const getRequestServiceProblemCodeClick: RequestServiceProblemCodeClick =
  {
    name: "Viewed Select a Problem Screen",
  };

export function getOnViewRoomSummaryEvent(
  sharedScreen: boolean,
  roomID: string,
  locationId: string
): OnViewRoomSummaryEvent {
  return {
    name: "Viewed Room Summary Screen",
    properties: {
      name: "Room Summary",
      as400Id: roomID,
      locationID: locationId,
      sharedscreen: sharedScreen ? "yes" : "no",
    },
  };
}
