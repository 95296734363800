import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { UIContext } from "../components/context/UIContext";
import EnterLicenseForSupportRequest from "../components/request-service/enter-license-for-support-request";
import SelectProblem from "../components/request-service/select-problem";
import ConfirmationModal, {
  ConfirmationModalType,
} from "../components/request-service/confirmation-modal";
import { Machine } from "../lib/types/ClientServices/Machines";
import AppLessLayout from "../components/layout/Layout";
import { useTranslation } from "react-i18next";

const RequestService = () => {
  const { state } = React.useContext(UIContext);
  const [selectedMachine, setSelectedMachine] = useState<Machine>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [location, setLocation] = useState(state.location);
  const [room, setRoom] = useState(state.room);
  const { t } = useTranslation();

  const hideModal = () => {
    // We want to "redirect" back to the initial Request Service page,
    // but we're already on /request-service. So instead of router.push...

    // Hide the modal
    setShowConfirmationModal(false);

    // Clear the current machine
    setSelectedMachine(undefined);

    // Scroll back to top
    window.scrollTo(0, 0);
  };

  return (
    <AppLessLayout headerType={"short"}>
      <>
        <Row className="text-center screen-title-row">
          <Col className="col-md-6 offset-md-3">
            <p className="text-center tag-line">
              {t("RequestService.requestServiceTitle")}
            </p>
          </Col>
        </Row>

        {!selectedMachine && (
          <EnterLicenseForSupportRequest
            room={room}
            setRoom={setRoom}
            location={location}
            setLocation={setLocation}
            selectedMachine={selectedMachine}
            setSelectedMachine={setSelectedMachine}
          />
        )}

        {selectedMachine && (
          <SelectProblem
            machine={selectedMachine}
            submitted={showConfirmationModal}
            setSubmitted={setShowConfirmationModal}
            location={location}
            room={room}
          />
        )}

        <ConfirmationModal
          closeModal={hideModal}
          showModal={showConfirmationModal}
          modalType={ConfirmationModalType.service}
        />
      </>
    </AppLessLayout>
  );
};

export default RequestService;
