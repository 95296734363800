import { VendPrice } from "../lib/types/FrontEnd/vend-price";
import React, { useContext, useEffect, useState } from "react";
import MachinePaymentLink, {
  paymentCompleteCallback,
} from "../components/machine/machine-payment-link";
import { FreePlayContinueButton } from "../components/machine/FreePlayContinueButton";
import { UIContext } from "../components/context/UIContext";
import { StudentPaymentButton } from "../components/student-payment/StudentPaymentButton/StudentPaymentButton";
import { GetTransactionFeeFromPriceDetail } from "../lib/helpers/GetTransactionFeeFromPriceDetail";
import { Machine } from "../lib/types/ClientServices/Machines";
import useFeatureFlags from "../lib/flags/useFeatureFlags";
import OfflineMachine from "../components/machine/OfflineMachine";
import { useTranslation } from "react-i18next";

interface PaymentOptionsProps {
  price: VendPrice | undefined;
  isOnline: boolean;
  freePlayOnClick: () => void;
  paymentCompleteCallback: paymentCompleteCallback;
  machine: Machine;
}

enum PaymentOptionValues {
  FreePlay,
  StudentPayment,
  AdyenPayment,
  AdyenAndStudentPayment,
  Unavailable,
  NoPaymentMethod,
  Undefined,
}

const PaymentOptions = ({
  machine,
  price,
  paymentCompleteCallback,
  isOnline,
  freePlayOnClick,
}: PaymentOptionsProps) => {
  const { state } = useContext(UIContext);
  const { roomSummary } = state;
  const flags = useFeatureFlags();

  const isRoomFreePlay = roomSummary?.freePlay ?? false;

  const allowGuestSchoolCard =
    roomSummary?.school?.allowGuestCreditCard ?? false;

  const isMachineInSchoolPaymentMode =
    flags.allowStudentPayment &&
    allowGuestSchoolCard &&
    isOnline &&
    price &&
    !isRoomFreePlay;

  const allowAdyenPayment = allowGuestSchoolCard && flags.allowStudentPayment;

  const isMachineInRegularPaymentMode =
    (allowAdyenPayment && isOnline && !!price) ||
    (!isMachineInSchoolPaymentMode && !isRoomFreePlay);

  const canUsersOnlyPayWithStudentCard =
    roomSummary?.school?.allowGuestStudentCard === true &&
    roomSummary?.school?.allowGuestCreditCard === false;

  const isFee =
    (price?.details ?? false) &&
    GetTransactionFeeFromPriceDetail(price?.details) > 0 &&
    !canUsersOnlyPayWithStudentCard;

  const [showPaymentInfo, setShowPaymentInfo] = useState<boolean>(false);

  const onCreditShowButtonClick = () => {
    setShowPaymentInfo(true);
  };

  const [paymentOptions, setPaymentOptions] = useState<PaymentOptionValues>(
    PaymentOptionValues.Undefined
  );

  const [isSSOLoading, setIsSSOLoading] = useState(false);

  useEffect(() => {
    if (!roomSummary) return;
    const allowGuestSchoolCard =
      roomSummary?.school?.allowGuestStudentCard ?? false;

    const showPaymentInfoHelper =
      paymentOptions === PaymentOptionValues.AdyenPayment &&
      !allowGuestSchoolCard;
    setShowPaymentInfo(showPaymentInfoHelper);
  }, [
    isMachineInRegularPaymentMode,
    isMachineInSchoolPaymentMode,
    isRoomFreePlay,
    paymentOptions,
    roomSummary,
  ]);

  useEffect(() => {
    const isRoomFreePlay = roomSummary?.freePlay ?? false;

    const allowGuestSchoolCard =
      roomSummary?.school?.allowGuestStudentCard ?? false;

    const allowAdyenCard =
      roomSummary?.school?.allowGuestCreditCard ??
      !allowGuestSchoolCard ??
      false;

    if (!isOnline) {
      setPaymentOptions(PaymentOptionValues.Unavailable);
    } else if (isRoomFreePlay) {
      setPaymentOptions(PaymentOptionValues.FreePlay);
    } else if (flags.allowStudentPayment && allowGuestSchoolCard) {
      // School or both
      if (allowAdyenCard) {
        setPaymentOptions(PaymentOptionValues.AdyenAndStudentPayment);
      } else {
        setPaymentOptions(PaymentOptionValues.StudentPayment);
      }
    } else if (!flags.allowStudentPayment || allowAdyenCard) {
      setPaymentOptions(PaymentOptionValues.AdyenPayment);
    } else {
      setPaymentOptions(PaymentOptionValues.Unavailable);
    }
  }, [roomSummary, price, isOnline, showPaymentInfo]);

  const { t } = useTranslation();

  return (
    <>
      {/* Free play*/}
      {paymentOptions === PaymentOptionValues.FreePlay && (
        <FreePlayContinueButton onClick={freePlayOnClick} />
      )}

      {/* Credit card payment */}
      {!!price && (
        <>
          {paymentOptions === PaymentOptionValues.AdyenPayment && (
            <MachinePaymentLink
              displayAdyenForm={
                (!isRoomFreePlay && showPaymentInfo) ||
                !flags.allowStudentPayment
              }
              price={price}
              asyncCallback={paymentCompleteCallback}
            />
          )}
          {paymentOptions === PaymentOptionValues.AdyenAndStudentPayment && (
            <>
              {!showPaymentInfo && !isSSOLoading && (
                <button
                  onClick={onCreditShowButtonClick}
                  className={"student-payment-button"}
                >
                  {t("PaymentOptions.pay")}
                </button>
              )}
              {showPaymentInfo && (
                <MachinePaymentLink
                  displayAdyenForm={!isRoomFreePlay}
                  price={price}
                  asyncCallback={paymentCompleteCallback}
                />
              )}
            </>
          )}
        </>
      )}

      {/* School payment */}
      {!!price &&
        !showPaymentInfo &&
        (paymentOptions === PaymentOptionValues.StudentPayment ||
          paymentOptions === PaymentOptionValues.AdyenAndStudentPayment) && (
          <StudentPaymentButton
            cardName={roomSummary?.school.cardName}
            fees={isFee}
            machineGuid={machine.opaqueId}
            price={price}
            isSSOLoading={isSSOLoading}
            setIsSSOLoading={setIsSSOLoading}
          />
        )}

      {/* No payment options allowed */}
      {paymentOptions === PaymentOptionValues.NoPaymentMethod && (
        <div>{t("PaymentOptions.noPayment")}</div>
      )}

      {/* Offline */}
      {paymentOptions === PaymentOptionValues.Unavailable && (
        <OfflineMachine machine={machine} />
      )}
    </>
  );
};

export default PaymentOptions;
