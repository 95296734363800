import React, { useEffect, useState } from "react";
import MachineLocationSummary, {
  MachineLocationSummaryProps,
} from "../MachineLocationSummary";
import Input from "../forms/input";
import AddressInfoFormFields from "./AddressInfoFormFields";
import { useTranslation } from "react-i18next";
import { handleOnInvalid } from "../../lib/helpers/inputHandlers";

export interface ContactInfoParentProps {
  SetStreetAddress: React.Dispatch<React.SetStateAction<string>>;
  SetStreetAddress2: React.Dispatch<React.SetStateAction<string>>;
  SetCity: React.Dispatch<React.SetStateAction<string>>;
  SetZip: React.Dispatch<React.SetStateAction<string>>;
  SetState: React.Dispatch<React.SetStateAction<string>>;

  State: string;
  City: string;
  Zip: string;
  StreetAddress: string;
  StreetAddress2: string;
}

interface ContactInfoProps {
  FirstName: string;
  SetFirstName: React.Dispatch<React.SetStateAction<string>>;
  LastName: string;
  SetLastName: React.Dispatch<React.SetStateAction<string>>;
  Email: string;
  SetEmail: React.Dispatch<React.SetStateAction<string>>;
  PhoneNumber: string;
  SetPhoneNumber: React.Dispatch<React.SetStateAction<string>>;

  ContactInfo: ContactInfoParentProps;

  onSubmit: () => void;
}

/**
 * Form fields and handlers for request refund - physical currency
 * @param props
 * @constructor
 */
const ContactInfo = (props: ContactInfoProps & MachineLocationSummaryProps) => {
  const [disabled, setDisabled] = useState(true);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSubmit();
  };

  useEffect(() => {
    if (
      props.ContactInfo.Zip &&
      props.PhoneNumber &&
      props.FirstName &&
      props.LastName &&
      props.ContactInfo.State &&
      props.ContactInfo.StreetAddress &&
      props.ContactInfo.City &&
      props.Email
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    props.ContactInfo.Zip,
    props.ContactInfo.State,
    props.ContactInfo.StreetAddress2,
    props.ContactInfo.City,
    props.ContactInfo.StreetAddress,
    props.Email,
    props.PhoneNumber,
    props.FirstName,
    props.LastName,
  ]);
  const { t } = useTranslation();

  return (
    <div className="contact-info">
      <MachineLocationSummary
        machine={props.machine}
        room={props.room}
        location={props.location}
      />

      <form onSubmit={onSubmit}>
        <div className="contact-info__input-label">
          <span className="contact-info__input-label-text">
            {t("ContactInfo.title")}
          </span>
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.SetFirstName(e.currentTarget.value);
            }}
            value={props.FirstName}
            autoComplete={"given-name"}
            required
            type="text"
            placeholder={t("ContactInfo.firstName") || "First Name"}
            className="contact-info__input"
          />

          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.SetLastName(e.currentTarget.value);
            }}
            value={props.LastName}
            required
            type="text"
            autoComplete={"family-name"}
            placeholder={t("ContactInfo.lastName") || "Last Name"}
            className="contact-info__input"
          />

          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.SetEmail(e.currentTarget.value);
            }}
            value={props.Email}
            required
            type="email"
            autoComplete={"email"}
            placeholder={t("ContactInfo.email") || "Email"}
            className="contact-info__input"
          />

          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.SetPhoneNumber(e.currentTarget.value);
            }}
            value={props.PhoneNumber}
            required
            type="text"
            autoComplete={"tel"}
            placeholder={t("ContactInfo.phoneNumber") || "Phone Number"}
            className="contact-info__input"
          />
        </div>

        <AddressInfoFormFields
          onStateChange={(e) =>
            props.ContactInfo.SetState(e.currentTarget.value)
          }
          onCityChange={(e) =>
            props.ContactInfo.SetCity((e.target as HTMLInputElement).value)
          }
          onStreetAddressChange={(e) =>
            props.ContactInfo.SetStreetAddress(e.currentTarget.value)
          }
          onStreetAddressChange2={(e) =>
            props.ContactInfo.SetStreetAddress2(e.currentTarget.value)
          }
          OnZipChange={(e) => {
            handleOnInvalid(e, t("AddressInfoForm.zipFailed"));
            props.ContactInfo.SetZip(e.currentTarget.value);
          }}
          State={props.ContactInfo.State}
          City={props.ContactInfo.City}
          StreetAddress={props.ContactInfo.StreetAddress}
          StreetAddress2={props.ContactInfo.StreetAddress2}
          Zip={props.ContactInfo.Zip}
        />

        <button
          data-disabled={disabled}
          className="btn btn-primary contact-info__button"
        >
          {t("ContactInfo.submit")}
        </button>
      </form>
    </div>
  );
};
export default ContactInfo;
