import { gql } from "@apollo/client";
import { RoomSummaryMachinesStatus } from "../../types/FrontEnd/RoomSummary";

export interface SchoolSummary {
  name: string;
  cardName: string;
  accountName: string;
  accountDescription: string;
  logoFileUrl: string;
  allowGuestCreditCard: boolean;
  allowGuestStudentCard: boolean;

  serviceClient: {
    clientId: string;
    clientSecret: {
      algorithm: string;
      iv: string;
      cipherText: string;
    };
  };
}

const GET_ROOM_SUMMARY = gql`
  query GetRoomSummary {
    data(locationId: $locationId, roomId: $roomId)
      @rest(
        type: "Post"
        path: "location/{args.locationId}/room/{args.roomId}/summary"
      ) {
      locationId
      locationLabel
      roomId
      roomLabel
      isOnline
      washers
      dryers
      freePlay
      school
    }
  }
`;

export default GET_ROOM_SUMMARY;

export interface RoomSummaryResponse extends RoomSummaryMachinesStatus {
  locationId: string;
  locationLabel: string;
  roomId: string;
  roomLabel: string;
  isOnline: boolean;
  freePlay: boolean;
  school: SchoolSummary;
}

export interface RoomSummaryMachines {
  available: boolean;
  type: string;
  stickerNumber: number;
  notAvailableReason: string;
}

export interface RoomSummaryEndpointResponseGraphql {
  data: RoomSummaryResponse;
}
