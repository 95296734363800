import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { UIContext } from "../context/UIContext";
import SampleLicensePlateIMG from "../../../assets/img/sample-license-plate.png";
import MachineNotFoundIconIMG from "/assets/img/machine-not-found-icon.svg";
import {
  getOnEnterRefundAddMachineEventPayload,
  getOnEnterServiceRequestAddMachineDuplicateEventPayload,
  getOnEnterServiceRequestAddMachineEventPayload,
  OnMachineNotFoundEventPayload,
} from "../tracking/SegmentEvents";
import { useTranslation } from "react-i18next";

export enum EnterLicenseGenericType {
  service,
  refund,
}

interface EnterLicenseGenericProps {
  submit: (licensePlate: string) => void;
  ToggleMachineNotFound: (show: boolean) => void;
  showMachineNotFound: boolean;
  type: EnterLicenseGenericType;
}

/**
 * Prompts the user to enter the license plate of the troubled machine
 * Note: this requires the parent component to pass in function to set machine state
 * @param props
 * @constructor
 */
const EnterLicenseGeneric = (props: EnterLicenseGenericProps) => {
  const { dispatch } = React.useContext(UIContext);
  const [disabled, setDisabled] = useState(false);
  const [firstHalfInputValue, FirstHalfSetInputValue] = useState("");
  const [secondHalfInputValue, SecondHalfSetInputValue] = useState("");

  const inputMaxLength = 6;

  const secondInputRef = useRef<HTMLInputElement>(null);
  const firstInputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  /**
   * Internal function to put the full licensePlate together from the inputs
   */
  const getFullInputValue = (): string => {
    return `${firstHalfInputValue}-${secondHalfInputValue}`;
  };

  /**
   * Check to see if the user entered something that could be a license plate, if so, change the button "disabled" state
   */
  useEffect(() => {
    if (
      firstHalfInputValue.length + secondHalfInputValue.length !==
      inputMaxLength
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [firstHalfInputValue, secondHalfInputValue]);

  useEffect(() => {
    if (props.showMachineNotFound) {
      dispatch({
        type: "log-event",
        payload: OnMachineNotFoundEventPayload(props.type),
      });
    }
  }, [props.showMachineNotFound]);

  /**
   * Sets the state of the input element,
   * Inserts the "-" after the 3rd char if adding text
   * If the user deletes the "-" and inserts another char, then add the "-" back in before the char the user just typed
   * @param e
   * @param setInputValue
   */
  const setInput = (
    e: React.FormEvent<HTMLInputElement>,
    setInputValue: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const valueToSet = e.currentTarget.value.toUpperCase();

    setInputValue(valueToSet);
    props.ToggleMachineNotFound(false);
  };

  /**
   * Submit the form if user hits enter in input
   * @param event
   */
  const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Prevent autofocus when user is explicitly selecting input focus
    if (
      event.key !== "Tab" &&
      event.key !== "Shift" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight"
    ) {
      if (
        event.currentTarget.value.length === event.currentTarget.maxLength &&
        event.currentTarget?.dataset?.next &&
        secondInputRef.current
      ) {
        // Focus next input
        secondInputRef?.current?.focus();
      }

      if (
        event.currentTarget.value.length === 0 &&
        event.key === "Backspace" &&
        event.currentTarget?.dataset.previous &&
        firstInputRef.current
      ) {
        // Focus previous input
        firstInputRef?.current?.focus();
      }
    }

    if (event.key === "Enter") {
      props.submit(getFullInputValue());
    }
  };

  return (
    <div className="enter-license">
      {/* Error message*/}
      {props.showMachineNotFound && (
        <div className="enter-license__error">
          <img
            src={MachineNotFoundIconIMG}
            className="enter-license__error__icon"
          />
          <div className="enter-license__error__copy">
            {t("EnterLicense.machineNotFound")}
          </div>
        </div>
      )}

      {/* Main content */}
      <Row>
        <Col>
          <div className="enter-license__header">
            <div className="enter-license__header__image-wrap">
              <img
                src={SampleLicensePlateIMG}
                alt="Example license plate"
                className="enter-license__header__image"
              />
              <div className="enter-license__header__image-copy">
                {t("EnterLicense.example")}
              </div>
            </div>
            <div
              className="enter-license__header__copy"
              data-testid="enterMachineLicenseCopy"
            >
              {t("EnterLicense.enterMachineLicenseCopy")}
            </div>
          </div>

          <div className="enter-license__body">
            <div className="enter-license__body__title">
              {t("EnterLicense.machineLicense")}
            </div>
            <div className="enter-license__body__input-wrap">
              <input
                onKeyUp={onKeyUp}
                onChange={(e) => {
                  setInput(e, FirstHalfSetInputValue);
                }}
                value={firstHalfInputValue}
                minLength={inputMaxLength / 2}
                maxLength={inputMaxLength / 2}
                ref={firstInputRef}
                data-next={"true"}
                data-testid={"firstInput"}
                type="text"
                className={`enter-license__body__input ${
                  props.showMachineNotFound
                    ? "enter-license__body__input--error"
                    : ""
                }`}
              />

              <span className="enter-license__body__input-separator">-</span>
              <input
                onKeyUp={onKeyUp}
                onChange={(e) => {
                  setInput(e, SecondHalfSetInputValue);
                }}
                value={secondHalfInputValue}
                minLength={inputMaxLength / 2}
                maxLength={inputMaxLength / 2}
                ref={secondInputRef}
                data-previous={"true"}
                type="text"
                className={`enter-license__body__input ${
                  props.showMachineNotFound
                    ? "enter-license__body__input--error"
                    : ""
                }`}
              />
            </div>
          </div>

          {/* Submit button */}
          <button
            data-testid="next"
            onClick={() => {
              if (props.type === EnterLicenseGenericType.service) {
                dispatch({
                  type: "log-event",
                  payload: getOnEnterServiceRequestAddMachineEventPayload(
                    getFullInputValue()
                  ),
                });

                //https://bofcmportal.atlassian.net/browse/CGEXC-558 - client asked for duplicate events with different names
                dispatch({
                  type: "log-event",
                  payload:
                    getOnEnterServiceRequestAddMachineDuplicateEventPayload(
                      getFullInputValue()
                    ),
                });
              } else if (props.type === EnterLicenseGenericType.refund) {
                dispatch({
                  type: "log-event",
                  payload: getOnEnterRefundAddMachineEventPayload(
                    getFullInputValue()
                  ),
                });
              }

              props.submit(getFullInputValue());
            }}
            disabled={disabled}
            type={"submit"}
            className="enter-license__button btn btn-primary"
          >
            {t("EnterLicense.next")}
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default EnterLicenseGeneric;
