import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MachineTypesClientServices } from "../../lib/types/FrontEnd/Machines";

interface MachineStartButtonProps {
  type: string;
  number: string;
}

/**
 * Simple view that displays start button screen
 * @param type
 * @param number
 * @constructor
 */
const MachineStartButton: React.FC<MachineStartButtonProps> = ({
  type,
  number,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (
      localStorage.getItem("SSOToken") ||
      localStorage.getItem("SSOAtriumToken")
    ) {
      localStorage.removeItem("SSOToken");
      localStorage.removeItem("SSOAtriumToken");
    }
  });
  return (
    <Row className="start-row">
      <Col className="col-md-6 offset-md-3">
        <div className="start m-auto">
          <h1 className="pt-5">{t("MachineStartButton.start")}</h1>
          <p className="pt-3">
            {t("MachineStartButton.on")}
            {type === MachineTypesClientServices.Dryer
              ? t("MachineType.dryer")
              : t("MachineType.washer")}{" "}
            {number}
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default MachineStartButton;
