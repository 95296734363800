import React from "react";
import { BaseRoomSummaryRoute, RoomSummaryRoute } from "../../routes/routes";
import { useTranslation } from "react-i18next";

const RoomSummarySplash = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className={"room-summary-splash"}>
        <div className="room-summary-splash__copy">
          {t("RoomSummarySplash.splashMessage")}
        </div>
        <a
          href={`${BaseRoomSummaryRoute}`}
          className="room-summary-splash__button"
        >
          {t("RoomSummarySplash.roomSummary")}
        </a>
      </div>
    </>
  );
};

export default RoomSummarySplash;
