import React, { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import RedErrorIcon from "/assets/img/red-error-icon.svg";
import Input from "../forms/input";

export interface AccountDeletionReadyProps {
  signInOrOut: () => void;
  deleteAccount: (reason: string) => void;
  isAuthenticated: boolean;
  isLoginInProgress: boolean;
  isDeletionInProgress: boolean;
}

export const AccountDeletionReady = ({
  signInOrOut,
  deleteAccount,
  isAuthenticated,
  isDeletionInProgress,
  isLoginInProgress,
}: AccountDeletionReadyProps) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState("");

  const triggerDeletion = useCallback(() => {
    deleteAccount(reason);
  }, [reason]);

  return (
    <>
      {!isLoginInProgress && (
        <a
          onClick={() => {
            signInOrOut();
          }}
          className="account-deletion__login-button"
          data-testid="logInOutButton"
        >
          {isAuthenticated
            ? t("AccountDeletion.signOut")
            : t("AccountDeletion.signIn")}
        </a>
      )}
      {isLoginInProgress && (
        <div
          className="account-deletion__login-button"
          data-testid="loginLoadingIndicator"
        >
          Loading
        </div>
      )}
      <img className="account-deletion__warning-icon" src={RedErrorIcon} />
      <div className="account-deletion__title">
        {t("AccountDeletion.title")}
      </div>
      <ul className="account-deletion__desc-wrap">
        <li className="account-deletion__desc">
          <Trans i18nKey={"AccountDeletion.desc1"} />
        </li>
        <li className="account-deletion__desc">{t("AccountDeletion.desc2")}</li>
        <li className="account-deletion__desc">{t("AccountDeletion.desc3")}</li>
        <li className="account-deletion__desc">
          <Trans i18nKey={"AccountDeletion.desc4"}>
            All account and personal data will be managed in accordance with our
            <a
              className="account-deletion__hyper-link"
              href="https://www.cscsw.com/privacy-policy"
            >
              Privacy Policy
            </a>
          </Trans>
        </li>
        <li className="account-deletion__desc">
          <Trans i18nKey={"AccountDeletion.desc5"} />
        </li>
      </ul>
      <div className="account-deletion__divider" />
      <p className="account-deletion__input-title">
        {t("AccountDeletion.inputTitle")}
      </p>
      <Input
        onChange={(value) => {
          setReason(value.currentTarget.value);
        }}
        value={reason}
        type="text"
        placeholder={t("AccountDeletion.optional") || "Optional"}
        className="account-deletion__input"
        data-testid="deletionReasonInput"
        disabled={isDeletionInProgress || isLoginInProgress || !isAuthenticated}
      />
      <button
        onClick={() => {
          triggerDeletion();
        }}
        className="account-deletion__confirm-button"
        disabled={isDeletionInProgress || isLoginInProgress || !isAuthenticated}
        data-testid="triggerDeletionButton"
      >
        {!isAuthenticated
          ? t("AccountDeletion.signInRequired")
          : isDeletionInProgress
          ? t("AccountDeletion.deleting")
          : t("AccountDeletion.deleteMyAccount")}
      </button>
    </>
  );
};
