import React from "react";
import { useTranslation } from "react-i18next";
import GenericModal, {
  buttonType,
  modalType,
} from "../request-service/generic-modal";

export interface AccountDeletionConfirmModalProps {
  confirmDelete: () => void;
  cancel: () => void;
  showModal: boolean;
}

export const AccountDeletionConfirmModal = ({
  confirmDelete,
  cancel,
  showModal,
}: AccountDeletionConfirmModalProps) => {
  const { t } = useTranslation();
  return (
    <GenericModal
      title={t("AccountDeletion.confirmTitle") ?? ""}
      copy={[
        t("AccountDeletion.confirmMessage1") ?? "",
        t("AccountDeletion.confirmMessage2") ?? "",
      ]}
      type={modalType.error}
      className="account-deletion__modal"
      buttons={[
        {
          text: t("AccountDeletion.confirmDeletion"),
          type: buttonType.error,
          onClick: () => {
            confirmDelete();
          },
        },
        {
          text: t("AccountDeletion.close"),
          type: buttonType.secondary,
        },
      ]}
      showModal={showModal}
      closeModal={() => {
        cancel();
      }}
    />
  );
};
