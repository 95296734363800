import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import MyLaundryManager from "../lib/localStorage/MyLaundryManager";
import MachineStatus from "../components/machine/machine-status";
import { UIContext } from "../components/context/UIContext";
import MachineNewLoad from "../components/machine/machine-new-load";
import MachineStateCard from "../components/machine/machine-state-card";
import Loading from "../components/Loading";
import AppLessLayout from "../components/layout/Layout";
import { useNavigate } from "react-router-dom";
import { MachineMode } from "../lib/types/ClientServices/Machines";
import SSEFallbackUpdater from "../components/fallbacks/SSEFallbackUpdater";
import { AdyenThreeDSCallback } from "../components/payment/AdyenThreeDSCallback";
import { useTranslation } from "react-i18next";

const Started: FunctionComponent<{ is3DSreturn: boolean }> = (
  props: PropsWithChildren<{ is3DSreturn: boolean }>
) => {
  const is3DSreturn = props.is3DSreturn ?? false;
  const { state, dispatch } = React.useContext(UIContext);
  const [sourceMachine, setSourceMachine] = useState(state.getSourceMachine());
  const localStorageManager = new MyLaundryManager();
  const laundryManager = new MyLaundryManager();
  const { t } = useTranslation();

  useEffect(() => {
    setSourceMachine(state.getSourceMachine());
  }, [JSON.stringify(state.machines)]);

  useEffect(() => {
    if (!sourceMachine) {
      return;
    }
    localStorageManager.upsertMachine(sourceMachine);
  }, [sourceMachine]);

  useEffect(() => {
    dispatch({ type: "log-page-view" });
  }, []);

  useEffect(() => {
    MyLaundryManager.addLicensePlateToTrack(null);
    const sourceMachine = state.getSourceMachine();
    if (sourceMachine && state.location) {
      laundryManager
        .addMachine(sourceMachine.licensePlate, state.location)
        .then((machine) => {
          setSourceMachine(state.getSourceMachine());
        });
    }
  }, [state.initialized]);

  if (sourceMachine) {
    return (
      <AppLessLayout>
        <>
          {is3DSreturn && <AdyenThreeDSCallback machine={sourceMachine} />}
          <MachineStatus />
          <MachineStateCard
            key={sourceMachine.licensePlate}
            machine={sourceMachine}
          />
          <MachineNewLoad sourceName="Machine Started" showText={true} />
          {(sourceMachine.mode === MachineMode.running ||
            sourceMachine.mode === MachineMode.idle) && (
            <p className="estimation-footnote">
              {t("Started.timesAreEstimates")}
            </p>
          )}
        </>
        <SSEFallbackUpdater
          isSSEEnabled={state.isSSESupported}
          machine={sourceMachine}
        />
      </AppLessLayout>
    );
  }

  return (
    <AppLessLayout noContext={true}>
      <Loading />
    </AppLessLayout>
  );
};

export default Started;
