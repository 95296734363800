import { gql } from "@apollo/client";
import { GenericRefundRequestBody } from "./CashRefundRequest";

export const ANONYMOUS_USER_REFUND_REQUEST_REASON = "refund_failed_to_start";

export interface DigitalRefundRequestBody extends GenericRefundRequestBody {
  creditCardType: string;
  creditCardLast4: string;
}

export interface DigitalRefundRequestVars {
  body: DigitalRefundRequestBody;
}

interface DigitalRefundRequest {
  correlation: string;
  ts: string;
  fullName: string;
  email: string;
  phone: string;
  source: string;
  paymentMethod: string;
  amount: string;
  reasonId: typeof ANONYMOUS_USER_REFUND_REQUEST_REASON;
  reason: string;
  comments: string;
  as400Number: string;
  locationId: string;
  roomId: string;
  licensePlate: string;
  userId: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zipCode: string;
  creditCardType: string;
  creditCardLast4: string;
  processedBy: string;
  processedAt: string;
  adminNotes: string;
  schemaVersion: string;
}

export interface DigitalRefundRequestData {
  data: DigitalRefundRequest;
}

const DIGITAL_REFUND_REQUEST = gql`
  mutation DigitalRefundRequest {
    data(body: $body)
      @rest(
        type: "Post"
        path: "commands/submitAnonymousCreditCardRefundRequest"
        method: "POST"
        bodyKey: "body"
      ) {
      success
      correlation
    }
  }
`;

export default DIGITAL_REFUND_REQUEST;
